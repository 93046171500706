import React, { Component } from 'react'
import axios from 'axios'

import { path } from '../../Routes'

import List from '../List'

class Following extends React.Component {
  state = {
    users: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('following_user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          users: res.data.users
        });
        this._asyncRequest = null;
      })
  }

  render () {
    const { users } = this.state

    return (
      <List users={users} />
    );
  }
}

export default Following
