import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'
import Rates from './Rates'

class Nodes extends Component {
  render() {
    return (
      <Switch>
        <Route path={Routes.rates_node_path} render={props => <Rates id={props.match.params.id} />} />
        <Route exact path={Routes.node_path} render={props => <Show id={props.match.params.id} history={props.history} />} />
      </Switch>
    )
  }
}

export default Nodes
