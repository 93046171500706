import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'
import Edit from './Edit'

Decisions.propTypes = {
  match: PropTypes.object
}

export default function Decisions (props) {
  return (
    <Switch>
      <Route
        path={Routes.edit_decision_path}
        render={props =>
          <Edit id={props.match.params.id} {...props} />
        }
      />

      <Route
        path={Routes.decision_path}
        render={props =>
          <Show id={props.match.params.id} {...props} />
        }
      />
    </Switch>
  )
}
