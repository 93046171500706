import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { Editor, EditorState, convertFromRaw, convertToRaw } from 'draft-js'

import Errors from '../Errors'
import { Current } from '../Current'
import { path } from '../Routes'
import I18n from '../I18n'

import JoinBlock from '../Auth/JoinBlock'

import styles from './Form.module.css'
import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'

Form.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}

export default function Form (props) {
  const { id, history } = props
  const { user } = useContext(Current)

  const [event, setEvent] = useState()
  const [values, setValues] = useState()
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { event, values } } = await axios.get(path(id ? 'edit_event_path' : 'new_event_path', { id, format: 'json' }))

      setEvent(event)

      values.text = values.text ? EditorState.createWithContent(convertFromRaw(values.text)) : EditorState.createEmpty()
      setValues(values)
    }

    _loadAsyncData()
  }, [id])

  const handleSubmit = e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    const params = {
      event: {
        ...values,
        text: values.text.getCurrentContent().hasText() ? convertToRaw(values.text.getCurrentContent()) : null
      }
    }

    console.log(event)

    if (id) {
      _handleUpdate(params)
    } else {
      _handleCreate(params)
    }
  }

  const _handleCreate = async params => {
    await axios.post(
      path('events_path'),
      params
    ).then(res => {
      history.push(res.headers.location)
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const _handleUpdate = async params => {
    await axios.patch(
      path('event_path', { id }),
      params
    ).then(res => {
      history.push(res.headers.location)
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const title = event ? event.title : I18n.t('event.new.title')

  // if (!event) return null
  // console.log(values)

  return (
    <div className={page.root}>
      <div className={page.tight}>
        <div className={page.title}>
          <h1>{title}</h1>

          <Helmet>
            <title>{title}</title>
          </Helmet>
        </div>

        {user && values &&
          <form onSubmit={handleSubmit}>
            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Название блока заданий
                </div>

                <div className={form.input}>
                  <input
                    type="text"
                    name="title"
                    placeholder="Введите название.."
                    value={values.title}
                    onChange={({ target: { name, value } }) => setValues({ ...values, [name]: value })}
                  />
                </div>
              </label>

              <Errors errors={errors.title} />
            </div>

            <div className={form.el}>
              <label>
                <div className={form.label}>
                  Описание
                </div>

                <div className={form.input}>
                  <Editor
                    editorState={values.text}
                    onChange={(editorState) => setValues({ ...values, text: editorState })}
                  />
                </div>
              </label>
            </div>

            <div className={form.submit}>
              <input type="submit" value={event ? 'Сохранить' : 'Создать' } className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} />
            </div>

          </form>
        }

        {!user &&
          <div className={styles.join}>
            <JoinBlock origin={path('new_event_path')}>
              <p>
                {I18n.t('event.not_auth')}
              </p>
            </JoinBlock>
          </div>
        }
      </div>
    </div>
  )
}
