import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'

Executions.propTypes = {
  match: PropTypes.object
}

export default function Executions (props) {
  return (
    <Switch>
      <Route
        path={Routes.execution_path}
        render={props =>
          <Show id={props.match.params.id} {...props} />
        }
      />
    </Switch>
  )
}
