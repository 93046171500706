import React from 'react'
import classNames from 'classnames'

import I18n from '../I18n'
import Link from '../Routes'

import styles from './Tabs.module.css'

function Tabs(props) {
  const { all, experts, friends } = props

  return (
    <div className={styles.root}>
      {!!all &&
        <Link exact strict to="users_path" className={classNames(styles.common)}>
          <div className={styles.tab}>
            <div className={styles.label}>
              {I18n.t('users.all')}
            </div>
            <div className={styles.value}>
              {all}
            </div>
          </div>
        </Link>
      }

      {!!experts &&
        <Link to="experts_users_path" className={classNames(styles.common, styles.experts)}>
          <div className={styles.tab}>
            <div className={styles.label}>
              {I18n.t('users.experts')}
            </div>
            <div className={styles.value}>
              {experts}
            </div>
          </div>
        </Link>
      }

      {!!friends &&
        <Link to="friends_users_path" className={classNames(styles.common, styles.friends)}>
          <div className={styles.tab}>
            <div className={styles.label}>
              {I18n.t('users.friends')}
            </div>
            <div className={styles.value}>
              {friends}
            </div>
          </div>
        </Link>
      }
    </div>
  )
}

export default Tabs
