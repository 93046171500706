import React, { Component } from 'react'
import className from 'classnames'

import Link from '../Routes'

import styles from './List.module.css'

class List extends Component {
  render() {
    const { gists } = this.props;

    if(!gists) return null;

    return (
      <div className={styles.list}>
        {gists.map((gist) =>
          <Item key={gist.id} gist={gist} />
        )}
      </div>
    )
  }
}

class Item extends Component {
  render () {
    const { gist } = this.props

    return (
      <div className={styles.item}>
        <Link to="gist_path" params={{ id: gist.id }}>
          <div>
            {gist.title}
          </div>
        </Link>
      </div>
    );
  }
}

export default List
