import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'
import { Editor, EditorState, convertFromRaw, convertToRaw } from 'draft-js'

import { path } from '../../../Routes'
import Marks from '../../../Rates/Marks'
import I18n from '../../../I18n'
import Errors from '../../../Errors'

import Files from './Files'

import buttons from '../../../Buttons.module.css'
import form from '../../../Form.module.css'
import styles from './Node.module.css'

Node.propTypes = {
  task: PropTypes.object,
  nodes: PropTypes.array,
  decisions: PropTypes.array,
  onDecisionCreate: PropTypes.func
}

export default function Node (props) {
  const { nodes, task, decisions, onDecisionCreate } = props

  const [node, setNode] = useState()
  const [values, setValues] = useState()
  useEffect(() => {
    if (node) {
      if (node.rate) {
        setValues({
          text: node.rate.text ? EditorState.createWithContent(convertFromRaw(node.rate.text)) : EditorState.createEmpty(),
          attachment_ids: [],
          marks: node.rate.marks
        })
      } else {
        setValues({ text: EditorState.createEmpty(), marks: Object.assign(...task.axes.map(axis => ({ [axis]: 60 }))), attachment_ids: [] })
      }
    } else {
      setValues(null)
    }
  }, [node])

  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  const handleCreate = async (e) => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    const text = values.text.getCurrentContent().hasText() ? convertToRaw(values.text.getCurrentContent()) : null

    await axios.post(
      path('decisions_path'),
      {
        decision: {
          ...values,
          text,
          marks: null,
          task_id: task.id,
          ...(node.rate && { rate_id: node.rate.id }),
          rate_attributes: {
            ...(node.rate && { id: node.rate.id }),
            ...(!node.rate && { node_id: node.id }),
            text,
            marks: values.marks
          }
        }
      }
    ).then(res => {
      onDecisionCreate && onDecisionCreate()
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  const decisioned = decisions.filter(decision => decision.rate).map(decision => decision.rate.node.id)

  return (
    <div>
      {!node &&
        <>
          <h2>Выберите педагога</h2>
          <div className={styles.nodes}>
            {nodes.map(node =>
              <div className={classNames(styles.node, { [styles.decisioned]: decisioned.includes(node.id) })} key={node.id} onClick={() => !decisioned.includes(node.id) && setNode(node)}>
                <div className={styles.cover}>
                  <img src={node.cover} />
                </div>
                <div className={styles.title}>
                  {node.surname}
                </div>
              </div>
            )}
          </div>
        </>
      }

      {node &&
        <div>
          {node.title}
        </div>
      }

      {node && values &&
        <form onSubmit={handleCreate}>
          <h2>{I18n.t(`decision.${task.type}.new`)}</h2>

          <div className={styles.rate}>
            <Marks
              onMarksChange={(marks) => setValues({ ...values, marks })}
              axes={task.axes}
              marks={values.marks}
            />

            <Errors errors={errors['rate.marks']} />
          </div>

          <div className={styles.form}>
            <div className={form.el}>
              <label>
                <div className={form.input}>
                  <Editor
                    editorState={values.text}
                    onChange={(editorState) => setValues({ ...values, text: editorState })}
                    placeholder={I18n.t(`decision.${task.type}.placeholder`)}
                  />
                </div>
              </label>

              <Errors errors={errors.text} />
            </div>

            {task.attachment_formats &&
              <div>
                <Files formats={task.attachment_formats} onFileUpload={id => setValues({ ...values, attachment_ids: [...values.attachment_ids, id] })} />
              </div>
            }

            <div className={form.submit}>
              <input type="submit" value="Сохранить" className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} />
            </div>
          </div>
        </form>
      }
    </div>
  )
}
