import React from 'react'
import classNames from 'classnames'

import styles from './Avatar.module.css'

export default function Avatar(props) {
  return (
    <div className={classNames(styles.root, styles[props.size])}>

      {!props.user.avatar_thumb_url &&
        <div className={styles.letters}>
          {props.user.title_letter}
        </div>
      }

      {props.user.avatar_thumb_url &&
        <img src={props.user.avatar_thumb_url} className={styles.image} />
      }
    </div>
  )
}

Avatar.defaultProps = {
  size: 's',
}
