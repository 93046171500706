import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import update from 'immutability-helper'

import Link, { path, Routes } from '../Routes'
import Loaf from '../Loaf'

import Add from './Structure/Add'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Structure.module.css'

class Structure extends Component {
  state = {
    add: false,
    gist: null,
    structures: null,
    nodes: null,
    redirect_to: null,
  }

  handleUpdate = () => {
    this.setState({ add: false })
    this._loadAsyncData(this.props.id)
  }

  removeNode = (structure) =>  {
    let redirect_to = this.props.routes.gists_path + '/' + this.state.gist.id + '/structure';
    if(structure.parent_id) redirect_to += '/' + structure.parent_id;

    axios.post(this.props.routes.gists_path + '/' + this.state.gist.id + '/unattach', Object.assign({
      authenticity_token: this.props.authenticity_token
    }, { structure_id: structure.id })).then(
      res => {
        this.setState({
          redirect_to: redirect_to,
          structures: null
        });
      }
    );
  }

  addNode = (id, type, structure_id) =>  {
    axios.post(this.props.routes.gists_path + '/' + this.state.gist.id + '/structure', Object.assign({
      authenticity_token: this.props.authenticity_token
    }, { structurable_id: id, structurable_type: type, structure_id: structure_id })).then(
      res => {
        this.setState({
          structures: null
        });
      }
    );
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  static getDerivedStateFromProps(props, state) {
    const id = props.location.hash.slice(1)
    if(state.id != id) {
      return { id }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.structures === null) this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  _loadAsyncData = async id => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('structure_gist_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })
    this._asyncRequest = null
  }

  handleDestroy = (structure) => {
    this._asyncRequest = axios.CancelToken.source()

    axios.post(path('unattach_gist_path', { id: this.state.gist.id, format: 'json' }), { structure_id: structure.id }, { cancelToken: this._asyncRequest.token, })
      .then(res => {
        this.props.history.push(path('structure_gist_path', { id: this.state.gist.id }) + `#${structure.parent_id}`)
        this._loadAsyncData(this.props.id)
        this._asyncRequest = null
      })
  }

  render () {
    const { id, gist, structures, nodes, add } = this.state

    if(!gist || !structures) return null

    const structure = structures.find(s => s.id == id)
    const parent = structures.find(s => structure && s.id == structure.parent_id)

    let list = structures.filter(s => !s.parent_id)

    if(structure) {
      list = structures.filter(s => s.parent_id == id)
    }

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <>
            {structure &&
              <>
                {parent &&
                  <Link to="structure_gist_path" params={{ id: gist.id, hash: parent.id  }}>
                    {parent.title_short}
                  </Link>
                }

                {!parent &&
                  <Link to="structure_gist_path" params={{ id: gist.id  }}>
                    {gist.title}
                  </Link>
                }
              </>
            }

            {!structure &&
              <Link to="gist_path" params={{ id: gist.id }}>
                Карта
              </Link>
            }

            </>
          </Loaf>

          <div className={page.title}>
            <h1>
              {gist.title}
            </h1>

            <Helmet>
              <title>{'Структура: ' + gist.title}</title>
            </Helmet>
          </div>

          <div className={styles.actions}>
            <Link to="edit_gist_path" params={{ id: gist.id }} className={styles.edit}>Редактировать название</Link>
          </div>

          <div className={styles.root}>
            {structure &&
              <div>
                <h2>
                  {structure.title}
                </h2>

                <div className={styles.actions}>
                  <div className={buttons.destroy} onClick={() => this.handleDestroy(structure)}>
                    Удалить ветку
                  </div>
                </div>
              </div>
            }

            {!structure &&
              <p className={styles.first}>
                На первом уровне любой карты лежат подходы. Вы можете выбрать из уже существующих или создать свои, которые так же смогут использовать другие участники проекта.
              </p>
            }

            <ul className={styles.list}>
              {list.map(item =>
                <li key={item.id}>
                  <Link to="structure_gist_path" params={{ id: gist.id, hash: item.id }}>
                    {item.title_short}
                  </Link>
                </li>
              )}
              <li>
                <h2 className={classNames(styles.new, { [styles.inactive]: add })} onClick={() => this.setState(state => ({ add: !state.add }))}>
                  <svg viewBox="0 0 56 56">
                    <line x1="28.5" y1="18.5" x2="28.5" y2="36.5" stroke="#407BB5" strokeLinecap="round"/>
                    <line x1="37.5" y1="27.5" x2="19.5" y2="27.5" stroke="#407BB5" strokeLinecap="round"/>
                  </svg>

                  Добавить {structure ? ['Conception', 'Node'].includes(structure.structurable_type) ? 'последователя' : 'концепцию' : 'подход' }
                </h2>
              </li>
            </ul>

            {add &&
              <Add gist={gist} nodes={nodes.filter(node => !structures.filter(s => s.structurable_type == node.type).map(s => s.structurable_id).includes(node.id))} structure={structure} onUpdate={this.handleUpdate} />
            }


          </div>
        </div>
      </div>
    );
  }
}

export default Structure
