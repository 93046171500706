import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'
import Form from './Form'

class Rates extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={Routes.edit_rate_path} render={props => <Form id={props.match.params.id} history={props.history} />} />
        <Route exact path={Routes.rate_path} render={props => <Show id={props.match.params.id} history={props.history} />} />
      </Switch>
    )
  }
}

export default Rates
