import React from 'react'
import { Helmet } from 'react-helmet'

// import Link from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css'

export default function Tasks (props) {
  return (
    <div className={page.root}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>
            {I18n.t('pages.help.tasks.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('pages.help.tasks.title')}</title>
          </Helmet>
        </div>

        <div>

          <h2>
            Типы заданий
          </h2>

          <p>
            В сервисе уже заранее готовы шаблоны заданий, вы можете выбрать любое готовое или выбрать свободный режим задания. Задания сгруппированы по 6 типам:
          </p>

          <h3>Знание</h3>

          <p>
            <strong>
              1. Написание инструкции к сервису
            </strong>
            <br />
            Исследуйте сервис «Педагогическая карта мира», определите целевое назначение его рубрик и отдельных компонентов («Педагоги»,  «Карта»,  «Практика», Интерпретация педагогических идей (графо-аналитический инструмент) и др. На его основе разработайте «Инструкции».
          </p>

          <p>
            <strong>
              2. Разработка глоссария
            </strong>
            <br />
            Составьте глоссарий по основаниям концепций, подходов, наследию основателей. Выпишите наиболее важные, на ваш взгляд, термины и понятия. Общее количество терминов должно быть не менее 20. Расположите все термины в алфавитном порядке, дайте толкование каждому термину.

          </p>

          <p>
            <strong>
              3. Составление кроссворда
            </strong>
            <br />
            Отберите и сформулируйте вопросы к кроссворду (25-30). Они должны быть краткими, четкими, однозначными и лаконичными. В содержание вопросов рекомендуется включать: названия трудов педагогов, авторскую терминологию педагогов, формулировки идей и пр. (Можно использовать - https://biouroki.ru/workshop/crossgen.html)
          </p>

          <h3>Понимание</h3>

          <p>
            <strong>
              1. Формулировка вопросов
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» составьте вопросы для игры «Что? Где? Когда?» по педагогам, их идеям и произведениям, научной биографии.
          </p>

          <p>
            <strong>
              2. Проектирование географии педагогических идей
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» постройте  контурную карту на основе проблемно-хронологического подхода по 16-20 вв. по разным странам мира.  В этой карте должны быть четко отражены: века, страны, ключевые проблемы образования в конкретной стране (кратко), их динамика в хронологическом аспекте. Контурную карту рекомендуется оформить в форме плаката.
          </p>

          <p>
            <strong>
              3. Отбор аргументов
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» изучите основные идеи педагогов (представителей определенной концепции), найдите и проиллюстрируйте подтверждение одной общей для них в трудах каждого автора. Задание оформите в виде таблицы с рубриками: Идея; ФИО педагога; тезисы, цитаты, аргументы, раскрывающие идею; название труда с указанием страниц.
          </p>

          <p>
            <strong>
              4. Разработка связи педагогов одной концепции
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» (вкладка «Карта») сравните основные педагогические идеи педагогов (одной концепции) от основателя до последователей, определите их преемственность и новаторство. Задание оформите в виде структурно-логической схемы. На схеме отразите основателя, его последователей с кратким комментарием, содержащим характеристику преемственности в трактовке и реализации педагогических идей и выделение новаторских положений каждого последователя.
          </p>

          <h3>Применение</h3>

          <p>
            <strong>
              1. Анализ современных практик
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» (вкладка «Практика»), проанализируйте видеоролик/видеофрагменты, выделите педагогические идеи автора как представителя определенной концепции.
          </p>

          <p>
            <strong>
              2. Разработка презентации
            </strong>
            <br />
            Используя материалы ЭС «Педагогическая карта мира» разработайте  презентацию с видеофрагментами для выступления на методическом семинаре с целью представления новаторских идей педагога и их реализации в современной педагогической практике. В презентации должны быть отражены: методическая проблема (проблемы), варианты ее решения в наследии конкретного  педагога (или педагогов), возможности и условиях ее применения в современных условиях в образовательной организации. Положения презентации необходимо дополнить видеофрагментами.
          </p>

          <p>
            <strong>
              3. Разработка сценария интервью
            </strong>
            <br />
            Разработайте сценарий журналистского интервью с конкретным педагогом для газеты, журнала, телепередачи и пр. с целью популяризации его идей и опыта.
          </p>

          <h3>Анализ</h3>

          <p>
            <strong>
              1. Сравнение педагогических идей
            </strong>
            <br />
            Работа с графо-аналитическим инструментом «Интерпретация педагогических идей» (вкладка «Педагоги»). Выберите не менее двух педагогов, представителей одной или разных концепций. Сравните их педагогические системы по 8-ми заданным дуалистичным параметрам, отобразите с помощью графо-аналитического инструмента, позиции аргументируйте. Сравните свои позиции с мнением экспертов.
          </p>

          <p>
            <strong>
              2. Разработка дайджеста
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», определите педагога, идеи которого в настоящее время имеют широкое распространение в мировом образовательном пространстве. Подготовьте дайджест по развитию идей и опыта этого педагога в России и мире.
          </p>

          <p>
            <strong>
              3. Организация опроса
            </strong>
            <br />
            Проведите опрос студентов старших курсов педагогического вуза, школьных учителей о степени популярности педагогов и их идей в современном педагогическом сообществе. Оформите отчет.
          </p>

          <p>
            <strong>
              4. Создание дополнительных материалов
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», дополните «концы веток» материалами о современной реализации педагогических идей и концепций.
          </p>

          <h3>Оценка</h3>

          <p>
            <strong>
              1. Разработка структуры электронного ресурса
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», выбрав отдельного педагога. Разработайте структуру электронного ресурса/ поста/сайта/портала о наследии этого педагога.
          </p>

          <p>
            <strong>
              2. Разработка афиши фильма
            </strong>
            <br />
            На основе анализа видеоматериалов во вкладке «Практика» ЭС «Педагогическая карта мира» разработайте афишу фильма об отдельном педагоге, его опыте, последователях, учениках и пр.
          </p>

          <p>
            <strong>
              3. Создание поста в соцсети
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», сделайте публикацию/пост в социальной сети о педагоге, чьи педагогические принципы Вам наиболее близки.
          </p>

          <p>
            <strong>
              4. Разработка проекта школы
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», разработайте концепцию/проект/модель школы имени конкретного педагога.
          </p>

          <p>
            <strong>
              5. Характеристика педагогических принципов
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», охарактеризуйте педагогические принципы отдельного педагога с позиции педагога/реализатора/критика/ученика/родителя.
          </p>

          <p>
            <strong>
              6. Написание письма
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», напишите «Письмо от ученика», обучающегося в школе в исторической ретроспективе (основатель школы должен быть один из педагогов, включенных в ЭС «ПКМ»). Необходимо соблюсти жанр письма. Письмо должно быть адресовано будущим поколениям учеников или педагогов. Письмо можно творчески оформить.
          </p>

          <p>
            <strong>
              7. Проведите сравнительный анализ
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», определите общее и особенное в решении проблем образования в конкретный период в разных странах мира. Оформите в форме таблицы с рубриками: исторический период, проблемы образования, общее в решении проблем образования, особенное в решении проблем образования с указанием стран.
          </p>

          <h3>Создание</h3>

          <p>
            <strong>
              1. Разработка сценарий видео
            </strong>
            <br />
            Исследуйте материалы ЭС «Педагогическая карта мира», разработайте сценарий видео «Моя педагогика» (временной интервал до 5 мин). Отразите следующие позиции:  Кто повлиял на ваши педагогические взгляды и как? Какие произведения определили ваши педагогические позиции? Какие педагогические ориентиры для Вас являются приоритетными и почему?
          </p>

          <p>
            <strong>
              2. Экспертиза концепции
            </strong>
            <br />
            На основе анализа материалов ЭС «Педагогическая карта мира», проведите экспертизу отдельной педагогической концепции. Результаты отразите в экспертном листе.
          </p>
        </div>
      </div>
    </div>
  )
}
