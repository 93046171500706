import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'

import I18n from '../../I18n'
import Link from '../../Routes'

import styles from './Tasks.module.css'

class Tasks extends Component {
  render () {
    const { tasks } = this.props

    if (!tasks || tasks.length === 0) return null

    return (
      <div className={styles.root}>
        <div className={styles.tasks}>
          {tasks.map((task, i) =>
            <div key={task.id} className={classNames(styles.wrapper, { [styles.complete]: task.complete })}>
              <div className={styles.marker}>{!task.complete && i + 1}</div>

              <Link to="task_path" params={{ id: task.id }} className={styles.task}>
                <div className={styles.title}>{I18n.t(`task.type.${task.type}.title`)}</div>

                {task.text &&
                  <div className={styles.text}>
                    <ReactMarkdown source={stateToMarkdown(convertFromRaw(task.text))} />
                  </div>
                }
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  }
}

// Tasks.contextType = Current

Tasks.propTypes = {
  tasks: PropTypes.array
}

export default Tasks
