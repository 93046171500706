import React, { Component } from 'react'
import className from 'classnames'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { MeshLine, MeshLineMaterial } from 'three.meshline'

import { path } from '../../Routes'

import styles from './Graph.module.css'

class Cell {
  constructor(node, a, depth) {
    this.a = a
    this.depth = depth
    this.x = Math.cos(a) * (1 / 20 * node.era)
    this.y = Math.sin(a) * (1 / 20 * node.era)
    this.vx = 0
    this.vy = 0
    this.vz = 0
    this.m = 1
    this.q = 3
    this.dt = 1e-4
    this.node = node
  }
}

class Spring {
  constructor (_from, _to) {
    this.from = _from
    this.to = _to
    this.h = 10
    // this.l = l
  }
}


class Graph extends Component {
  state = {
    center: {
      x: 0, y: 0
    },
    era: 40,
    size: 22,
    width: 0,
    height: 0
  }

  mount = React.createRef()

  lon = 0
  lat = 0
  phi = 0
  theta = 0

  componentDidMount() {
    const width = this.mount.current.clientWidth, height = this.mount.current.clientHeight

    this.scene = new THREE.Scene()
    this.nodes = new Map()
    this.springs = new Set()

    // this.scene.add( new THREE.AxesHelper( 20 ))



    this._placeNode(null, 1, 0, 0)

    this.nodes.forEach(node => {
      node.node.child_ids.map(id => {
        // const to = this.nodes.get(id)
        // const from = node
        // let distance_between = Math.sqrt(Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2))

        this.springs.add(new Spring(node.node.id, id))
      })
    })

    let i = 0
    do {
      const nodes = new Map([...this.nodes].filter(([k, node]) => node.depth == i))


      const offset = 2 / nodes.size
      const increment = Math.PI * (3 - Math.sqrt(5))

      let j = 0
      nodes.forEach((node, id) => {
        let y = ((j * offset) - 1) + (offset / 2)
        const distance = Math.sqrt(1 - Math.pow(y, 2))
        var phi = ((j + 1) % nodes.size) * increment
        let x = Math.cos(phi) * distance
        let z = Math.sin(phi) * distance

        node.x = x * (i + 2)
        node.y = y * (i + 2)
        node.z = z * (i + 2)

        j += 1
      })

      if (nodes.size < 1) break

      // this.scene.add(new THREE.Mesh(new THREE.SphereGeometry(i + 2, 18, 18), new THREE.MeshBasicMaterial({ color: 0x000000, opacity: 0.03, wireframe: true })))

      i += 1
    } while (1)

    // let j = 0
    // const offset = 2 / this.nodes.size
    // const increment = Math.PI * (3 - Math.sqrt(5))
    //
    // this.nodes.forEach((node, id) => {
    //   let y = ((j * offset) - 1) + (offset / 2)
    //   const distance = Math.sqrt(1 - Math.pow(y, 2))
    //   var phi = ((j + 1) % this.nodes.size) * increment
    //   let x = Math.cos(phi) * distance
    //   let z = Math.sin(phi) * distance
    //
    //   node.x = x * 10
    //   node.y = y * 10
    //   node.z = z * 10
    //
    //   j += 1
    // })

    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)

    this.camera = new THREE.PerspectiveCamera(
      75,
      width / height,
      0.1,
      1000
    )

    // this.camera.position.z = 15

    // this.camera.position.set(10, 0, 0)
    this.camera.position.set(5, 9, 14)
    this.camera.lookAt(0, 0, 0)

    this.mount.current.appendChild(this.renderer.domElement)

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)

    let geometry = new THREE.BoxGeometry(0.1, 0.1, 0.1)
    let material = new THREE.MeshBasicMaterial({ color: '#433F81' })
    // this.cube = new THREE.Mesh(geometry, material)
    // this.scene.add(this.cube)

    // console.log(this.nodes)

    this.nodes.forEach(node => {
      const o = new THREE.Mesh(new THREE.SphereGeometry(0.05), material)

      o.position.x = node.x
      o.position.y = node.y
      o.position.z = node.z
      this.scene.add(o)
      node.o = o

      // const p = this.nodes.get(node.node.parent_id)

      // if (p) {
      //   const geometry = new THREE.Geometry()
      //   geometry.vertices.push(new THREE.Vector3(node.x, node.y, node.z))
      //   geometry.vertices.push(new THREE.Vector3(p.x, p.y, p.z))
      //
      //   var line = new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0xff0000 }))
      //
      //   node.l = line
      //   this.scene.add(line)
      // }
    })

    this._calcNodes()

    this.nodes.forEach(node => {
      let p = this.nodes.get(node.node.parent_id)
      let nodes = [node.o.position]

      while (p) {
        nodes.push(p.o.position)
        p = this.nodes.get(p.node.parent_id)
      }

      if (nodes.length > 1) {
        const curve = new THREE.CatmullRomCurve3(nodes)

        // console.log(parents)
        // console.log(curve)

        var points = curve.getPoints(50)
        const geometry = new THREE.Geometry().setFromPoints(points)

        const material = new MeshLineMaterial({
          transparent: true,
          opacity: 0.3,
          lineWidth: 0.007,
          color: new THREE.Color('#000'),
          dashArray: 0.1,
          dashOffset: 0,
          dashRatio: 0.3
        })

        const line = new MeshLine()
        line.setGeometry(geometry)

        console.log(line.geometry)

        node.l = new THREE.Mesh(line.geometry, material)
        this.scene.add(node.l)
      }
    })

    this.start()
    // this.count = 0

    window.addEventListener('resize', this._updateDimensions)
    this._updateDimensions()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.active !== state.active) {
      return {
        active: props.active,
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.active != prevState.active) {
      // this._highlight()
    }

    if (this.state.width != prevState.width || this.state.height != prevState.height) {
      this.camera.aspect = this.state.width / this.state.height
      this.camera.updateProjectionMatrix()

      this.renderer.setSize(this.state.width, this.state.height)
    }
  }

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop = () => {
    cancelAnimationFrame(this.frameId)
  }

  animate = () => {
    this.controls.update()

    // if (this.frameId % 2 === 0) {
    //   this.count += 1
    //   this._calcForces()
    //   this._moveNodes()
    // }


    this.nodes.forEach(node => {
      if (node.l) {
        node.l.material.uniforms.dashOffset.value += 0.001
        // console.log(node.l.material)
        // node.l.material.dashSize += 0.1
        // node.l.material.dashSize += 1
        // node.l.geometry.verticesNeedUpdate = true
      }
    })

    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene = () => {
    this.renderer.render(this.scene, this.camera)
  }

  render () {
    return (
      <div className={styles.root}>
        <div className={styles.map} ref={this.mount} />
      </div>
    )
  }

  _placeNode = (parent_id, sector, offset, depth) => {
    const structures = this.props.structures.filter(s => (s.parent_id == parent_id && parent_id != null) || (s.structurable_type === 'Conception' && parent_id == null))
    let current_offset = offset
    if (structures.length < 1) return null
    const total = structures.map(s => s.descendant_count + 1).reduce(function(acc, val) { return acc + val })

    structures.map(s => {
      const node_sector = (s.descendant_count + 1) / total * sector
      this.nodes.set(s.id, new Cell(s, 2 * Math.PI * (current_offset + node_sector / 2), depth))

      this._placeNode(s.id, node_sector, current_offset, depth + 1)
      current_offset += node_sector
    })
  }

  _calcNodes = () => {
    Array(400).fill().map((_, i) => {
      this._calcForces()
      this._moveNodes()
    })
  }

  _calcForces = () => {
    this.nodes.forEach((_from, i) => {
      _from.fx = 0
      _from.fy = 0
      _from.fz = 0

      this.nodes.forEach((_to, j) => {
        if (i !== j) {
          let dx = _from.x - _to.x
          let dy = _from.y - _to.y
          let dz = _from.z - _to.z
          let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2) + Math.pow(dz, 2))
          // console.log(distance)
          // if(distance < 0.3) {
          _from.fx += _from.q * _to.q / Math.pow(distance, 3) * dx
          _from.fy += _from.q * _to.q / Math.pow(distance, 3) * dy
          _from.fz += _from.q * _to.q / Math.pow(distance, 3) * dz
          // }
        }
      })
    })

    this.springs.forEach((spring, i) => {
      const _from = this.nodes.get(spring.from)
      const _to = this.nodes.get(spring.to)
      let dx = _from.x - _to.x
      let dy = _from.y - _to.y
      let dz = _from.z - _to.z
      let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2) + Math.pow(dz, 2))
      // distance = 1
      // console.log(distance)
      const qq = spring.h * ((1 - distance) / distance)
      // console.log(qq)
      _from.fx += qq * (_from.x - _to.x)
      _from.fy += qq * (_from.y - _to.y)
      _from.fz += qq * (_from.z - _to.z)

      _to.fx += qq * (_to.x - _from.x)
      _to.fy += qq * (_to.y - _from.y)
      _to.fz += qq * (_to.z - _from.z)
    })
  }

  _moveNodes = () => {
    this.nodes.forEach((node, i) => {
      const radius = Math.sqrt(node.x * node.x + node.y * node.y + node.z * node.z)

      const f0 = new THREE.Vector3(node.fx, node.fy, node.fz)
      const r0 = new THREE.Vector3(node.x, node.y, node.z)

      f0.normalize()
      r0.normalize()

      const k = (node.x * node.fx + node.y * node.fy + node.z * node.fz) / (node.x * node.x + node.y * node.y + node.z * node.z)

      const df = new THREE.Vector3(f0.x - r0.x * k, f0.y - r0.y * k, f0.z - r0.z * k)

      const dt = 0.1

      const r1 = new THREE.Vector3(node.x + df.x * dt, node.y + df.y * dt, node.z + df.z * dt)
      const radius1 = Math.sqrt(r1.x * r1.x + r1.y * r1.y + r1.z * r1.z)
      node.x = r1.x * radius / radius1
      node.y = r1.y * radius / radius1
      node.z = r1.z * radius / radius1

      node.o.position.x = node.x
      node.o.position.y = node.y
      node.o.position.z = node.z

      // if (node.l) {
      //   node.l.geometry.vertices[0] = new THREE.Vector3(node.x, node.y, node.z)
      //   node.l.geometry.verticesNeedUpdate = true
      //   const p = this.nodes.get(node.node.parent_id)
      //   node.l.geometry.vertices[1] = new THREE.Vector3(p.x, p.y, p.z)
      // }
    })
  }

  _updateDimensions = () =>  {
    this.setState({
      center: {
        x: this.mount.current.clientWidth / 2,
        y: this.mount.current.clientHeight / 2
      },
      width: this.mount.current.clientWidth,
      height: this.mount.current.clientHeight
    })
  }
}

export default Graph
