import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Routes } from '../Routes'

import Dashboard from './Dashboard'
import Nodes from './Nodes/Nodes'

class Admin extends Component {
  render () {
    return (
      <React.Fragment>
        <Switch>
          <Route path={Routes.admin_dashboard_path} component={Dashboard} exact strict />
          <Route path={Routes.admin_nodes_path} component={Nodes} />
        </Switch>
      </React.Fragment>
    );
  }
}


export default Admin
