import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import I18n from './I18n'

class DT extends Component {
  render () {
    const { value } = this.props

    return (
      <>
        {dayjs(value).locale(I18n.locale).format('D MMMM YYYY')}
      </>
    )
  }
}

DT.propTypes = {
  value: PropTypes.string
}

export default DT
