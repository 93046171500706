import React from 'react'
import { Helmet } from 'react-helmet'

import Link from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css'

export default function Index (props) {
  return (
    <div className={page.root}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>
            {I18n.t('pages.help.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('pages.help.title')}</title>
          </Helmet>
        </div>

        <div>
          <ul>
            <li>
              <Link to="help_tasks_path">
                Комплексы заданий
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
