import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { useDropzone } from 'react-dropzone'

import { path } from '../../../Routes'
import I18n from '../../../I18n'

import form from '../../../Form'

import styles from './Files.module.css'

Files.propTypes = {
  onFileUpload: PropTypes.func,
  formats: PropTypes.array
}

export default function Files (props) {
  const { formats, onFileUpload } = props

  const [files, setFiles] = useState([])

  const onDrop = acceptedFiles => {
    let ext = []

    if (formats.includes('img')) ext = [...ext, ...['image/jpeg', 'image/png']]
    if (formats.includes('doc')) ext = [...ext, ...['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']]
    if (formats.includes('ppt')) ext = [...ext, ...['application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation']]

    setFiles([...files, ...acceptedFiles.filter(file => formats.length === 0 || ext.includes(file.type))])
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <div {...getRootProps()} className={classNames(styles.dropzone, { [form.active]: isDragActive })}>
        <input {...getInputProps()} />
        <div className={styles.label}>Прикрепить файл</div>
        {formats &&
          <div className={styles.formats}>
            {formats.length === 1 ? 'Только ' : ''}
            {formats.map(format => I18n.t(`decision.attachments.${format}.title`)).join(', ')}
            : {formats.map(format => I18n.t(`decision.attachments.${format}.ext`)).join(', ')}
          </div>
        }
      </div>

      {files && files.length > 0 &&
        <div className={styles.files}>
          {files.map((file, i) =>
            <File onFileUpload={onFileUpload} key={i} file={file} />
          )}
        </div>
      }
    </>
  )
}

File.propTypes = {
  file: PropTypes.object,
  onFileUpload: PropTypes.func
}

function File (props) {
  const { file, onFileUpload } = props

  const [upload, setUpload] = useState(0)
  const [errors, setErrors] = useState({})

  const [id, setId] = useState()

  useEffect(() => {
    id && onFileUpload && onFileUpload(id)
  }, [id])

  const [url, setUrl] = useState()

  useEffect(() => {
    const _saveAsync = async () => {
      console.log(file)

      const formData = new FormData()
      formData.append('attachment[file]', file)
      formData.append('attachment[title]', file.name)
      formData.append('attachment[size]', file.size)

      await axios.post(
        path('attachments_path'),
        formData,
        {
          onUploadProgress: progressEvent => {
            let p = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            if (p >= 100) p = 99
            setUpload(p)
          }
        }
      ).then(res => {
        setUpload(100)
        setId(res.data.id)
        setUrl(res.data.url)
      }).catch((error) => {
        setErrors(error.response.data)
      })
    }

    _saveAsync()
  }, [])

  return (
    <div className={styles.file}>
      <div className={styles.name}>
        {url &&
          <a href={url} target="_blank" rel="noopener noreferrer">
            {file.name}
          </a>
        }

        {!url && file.name }
      </div>

      <div className={styles.upload}>
        {upload}%
      </div>

      {!url &&
        <div className={styles.progress}>
          <div className={styles.bar} style={{ width: `${upload}%` }} />
        </div>
      }
    </div>
  )
}
