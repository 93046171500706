import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Link from '../../Routes'
import I18n from '../../I18n'

import styles from './Types.module.css'

export default function Types (props) {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.g, styles.g1)}>
        <Type
          {...props}
          type="manual"
          level="knowledge"
          component="node"
          docs="facts"
          form="single"
        />

        <Type
          {...props}
          type="glossary"
          level="knowledge"
          component="node"
          docs="facts"
          form="single"
        />

        <Type
          {...props}
          type="crossword"
          level="knowledge"
          component="node"
          docs="facts"
          form="single"
        />
      </div>

      <div className={classNames(styles.g, styles.g2)}>
        <Type
          {...props}
          type="questions"
          level="comprehension"
          component="conception"
          docs="ideas"
          form="single"
        />

        <Type
          {...props}
          type="map"
          level="comprehension"
          component="conception"
          docs="ideas"
          form="single"
        />

        <Type
          {...props}
          type="arguments"
          level="comprehension"
          component="conception"
          docs="ideas"
          form="single"
        />

        <Type
          {...props}
          type="gist"
          level="comprehension"
          component="conception"
          docs="ideas"
          form="single"
        />
      </div>

      <div className={classNames(styles.g, styles.g3)}>
        <Type
          {...props}
          type="ideas"
          level="application"
          component="practice"
          docs="methodics"
          form="double"
        />

        <Type
          {...props}
          type="slides"
          level="application"
          component="practice"
          docs="methodics"
          form="double"
        />

        <Type
          {...props}
          type="interview"
          level="application"
          component="practice"
          docs="methodics"
          form="double"
        />
      </div>

      <div className={classNames(styles.g, styles.g4)}>
        <Type
          {...props}
          type="rates"
          level="analysis"
          component="gist"
          docs="systems"
          form="double"
        />

        <Type
          {...props}
          type="digest"
          level="analysis"
          component="gist"
          docs="systems"
          form="double"
        />

        <Type
          {...props}
          type="poll"
          level="analysis"
          component="gist"
          docs="systems"
          form="double"
        />

        <Type
          {...props}
          type="practices"
          level="analysis"
          component="gist"
          docs="systems"
          form="double"
        />
      </div>

      <div className={classNames(styles.g, styles.g5)}>
        <Type
          {...props}
          type="template"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />

        <Type
          {...props}
          type="poster"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />

        <Type
          {...props}
          type="post"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />

        <Type
          {...props}
          type="school"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />

        <Type
          {...props}
          type="letter"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />

        <Type
          {...props}
          type="compare"
          level="evaluation"
          component="nodes"
          docs="principles"
          form="group"
        />
      </div>

      <div className={classNames(styles.g, styles.g6)}>
        <Type
          {...props}
          type="script"
          level="creation"
          component="gists"
          docs="theory"
          form="group"
        />

        <Type
          {...props}
          type="conception"
          level="creation"
          component="gists"
          docs="theory"
          form="group"
        />
      </div>
    </div>
  )
}

Type.propTypes = {
  type: PropTypes.string,
  event_id: PropTypes.string,
  level: PropTypes.string,
  component: PropTypes.string,
  docs: PropTypes.string,
  form: PropTypes.string
}

function Type (props) {
  const { type, event_id: eventId } = props
  const { level, component, docs, form } = props

  return (
    <Link to="new_task_path" params={{ query: { event_id: eventId, type: type } }} className={styles.type}>
      <h3>{I18n.t(`task.type.${type}.title`)}</h3>
      <p className={styles.desc}>
        {I18n.t(`task.type.${type}.desc`)}
      </p>
      <div className={styles.tags}>
        <div className={styles.tag}>
          {I18n.t(`task.level.${level}`)}
        </div>
        <div className={styles.tag}>
          {I18n.t(`task.component.${component}`)}
        </div>
        <div className={styles.tag}>
          {I18n.t(`task.docs.${docs}`)}
        </div>
        <div className={styles.tag}>
          {I18n.t(`task.form.${form}`)}
        </div>
      </div>
    </Link>
  )
}
