import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import update from 'immutability-helper'
import classNames from 'classnames'

import I18n from '../../I18n'
import { path } from '../../Routes'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Bios.module.css'

class Bios extends React.Component {
  state = {
    send: false,
    node: null,
    ideas: [],
    redirect_to: null
  }

  values = {
    id: null,
    text: ''
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('bios_admin_node_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const bios = res.data.bios.map(bio => {
          I18n.available_locales.map(locale => {
            if(!bio[locale]) {
              bio[locale] = { values: {...this.values, locale: locale} }
            }
          });
          bio.show = false;

          return bio;
        });

        this.setState({
          node: res.data.node,
          bios: bios,
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleInputChange = (e, i) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const bios = update(this.state.bios, {
      [i]: {
        values: {
          [name]: {
            $set: value
          }
        }
      }
    });

    this.setState({ bios });
  }

  handleLocaleInputChange = (e, locale, i) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const bios = update(this.state.bios, {
      [i]: {
        [locale]: {
          values: {
            [name]: {
              $set: value
            }
          }
        }
      }
    });

    this.setState({ bios });
  }

  handleYearsClick = (e, i) => {
    const bios = update(this.state.bios, {
      [i]: {
        $toggle: ['show']
      }
    });

    this.setState({ bios });
  }

  handleNewClick = () => {
    let bio = { id: null, values: { years: '' }};
    I18n.available_locales.map(locale => {
      bio[locale] = { values: {...this.values, locale: locale} };
    });

    const bios = update(this.state.bios, { $push: [bio] });

    this.setState({ bios });
  }

  _canSubmit = () => {
    return !this.state.send;
  }


  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: 'patch',
        url: path('admin_node_path', { id: this.props.id, format: 'json' }),
        cancelToken: this._asyncRequest.token,
        data: { node: {
          bios_attributes: this.state.bios.map(bio => {
            return {
              id: bio.id,
              years: bio.values.years,
              translations_attributes: I18n.available_locales.map(locale => {
                return bio[locale].values;
              })
            };
          })
        }}
      }).then(
        res => {
          this._asyncRequest = null
          if(res.headers.location) this.props.history.replace(res.headers.location)
        }
      ).catch((error) => {
        console.log(error);
        this.setState({ send: false });
      }).then(() => {

      });
    }

    e.preventDefault();
  }

  render() {
    const { bios, node } = this.state

    if(!node) return null;

    const title = `Биография: ${node.title_short}`;

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.compact}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              {bios.map((bio, _) =>
                <div className={styles.bio} key={_}>
                  <div className={styles.title} onClick={(e) => this.handleYearsClick(e, _)}>
                    {bio.values.years || I18n.t('admin.no_years')}
                  </div>
                  <div className={classNames(styles.locales, { [styles.collapsed]: !bio.show })}>
                    <div className={form.elem}>
                      <div className={form.label}>
                        Годы
                      </div>

                      <div className={form.input}>
                        <input type="text" name="years" value={bio.values.years} onChange={(e) => this.handleInputChange(e, _)} />
                      </div>
                    </div>

                    {I18n.available_locales.map(locale =>
                      <React.Fragment key={locale}>
                        <div className={form.elem}>
                          <div className={form.label}>
                            Подробное описание {locale.toUpperCase()}
                          </div>

                          <div className={form.input}>
                            <textarea name="text" value={bio[locale].values.text} rows="6" onChange={(e) => this.handleLocaleInputChange(e, locale, _)} />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              <div className={styles.new} onClick={this.handleNewClick}>
                Добавить новую веху
              </div>

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Bios
