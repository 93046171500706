import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
// import classNames from 'classnames'

import Loaf from '../Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import Avatar from '../Users/Avatar'
import DT from '..//DT'

import page from '../Page.module.css'
// import grid from '../Grid.module.css'
import styles from './Executions.module.css'

Executions.propTypes = {
  id: PropTypes.string
}

export default function Executions (props) {
  const [executions, setExecutions] = useState()
  const [event, setEvent] = useState()
  const _cancelToken = axios.CancelToken.source()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { executions, event } } = await axios.get(path('executions_event_path', { id: props.id, format: 'json' }), { cancelToken: _cancelToken.token })
      setExecutions(executions)
      setEvent(event)
    }

    _loadAsyncData()

    return () => {
      _cancelToken.cancel()
    }
  }, [])

  if (!event) return null

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <Loaf>
          <Link to="event_path" params={{ id: event.id }}>
            {event.title}
          </Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t(`event.executions.title`)}
          </h1>

          <Helmet>
            <title>{I18n.t(`event.executions.title`)}</title>
          </Helmet>
        </div>

        {executions &&
          <div className={styles.executions}>
            {executions.map(execution =>
              <Link key={execution.id} className={styles.execution} to="execution_path" params={{ id: execution.id }}>
                <div className={styles.avatar}>
                  <Avatar user={execution.user} />
                </div>

                <div>
                  <div className={styles.user}>
                    {execution.user.title}
                  </div>

                  <div className={styles.created_at}>
                    <DT value={execution.created_at} />
                  </div>
                </div>
              </Link>
            )}
          </div>
        }
      </div>
    </div>
  )
}
