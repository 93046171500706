import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import ReactMarkdown from 'react-markdown'

import I18n from '../../I18n'

import styles from './Events.module.css'
// import buttons from '../../Buttons'

class Events extends Component {
  render () {
    const { events } = this.props

    return (
      <div className={styles.root}>
        {events.map(event =>
          <div key={event.id} className={styles.event}>
            <div className={styles.title}>
              {event.title}
            </div>

            <div className={styles.tasks}>
              {event.tasks.map(task =>
                <label className={styles.task} key={task.id}>
                  <input type="checkbox" />
                  {task.title}
                </label>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Events.propTypes = {
  events: PropTypes.array
}

export default Events
