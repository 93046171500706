import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import { path } from '../Routes'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'
import styles from './Recovery.module.css'

class Recovery extends Component {
  state = {
    send: false,
    errors: {},
    values: {
      email: ''
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleInputChange = e =>  {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    if(this._canSubmit()) {
      this.setState({ send: true })
      this._asyncRequest = axios.CancelToken.source()

      axios.post(path('recovery_path', { format: 'json' }), { user: { ...this.state.values } }, { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._asyncRequest = null
          this.setState({ errors: {} })
          if(res.headers.location) this.props.history.push(res.headers.location)
        })
        .catch(error => {
          this.setState({ errors: error.response.data })
        })
        .then(() => {
          this.setState({ send: false })
        })
    }

    e.preventDefault()
  }

  _canSubmit() {
    return true
  }

  render() {
    const { values, errors } = this.state

    return (
      <div className={page.page}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {I18n.t('auth.recovery.title')}
              </h1>
              <Helmet>
                <title>
                  {I18n.t('auth.recovery.title')}
                </title>
              </Helmet>
            </div>

            <div className={styles.form}>
              <form className={form.root} onSubmit={this.handleSubmit}>
                <div className={form.item}>
                  <div className={form.label}>
                    Электронная почта
                  </div>

                  <div className={form.input}>
                    <input
                      type="text"
                      name="email"
                      value={values.email}
                      onChange={this.handleInputChange}
                      placeholder="Введите электронную почту..."
                    />
                  </div>

                  {errors.email &&
                    <div className={form.error}>
                      <ul>
                        {errors.email.map(error => <li>{error}</li>)}
                      </ul>

                    </div>
                  }
                </div>

                {Object.entries(errors).length > 0 &&
                  <div className={styles.error}>
                    Необходимо исправить ошибки и попробовать снова.
                  </div>
                }

                <div className={form.submit}>
                  <input type="submit" value="Восстановить" className={buttons.main} disabled={!this._canSubmit()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Recovery
