import React, { Component } from 'react'
import axios from 'axios'

import { path } from '../../Routes'

import List from '../../Rates/List';

class Rates extends React.Component {
  state = {
    rates: null,
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('rates_user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          rates: res.data.rates
        });
        this._asyncRequest = null;
      })
  }

  render () {
    return (
      <List rates={this.state.rates} />
    )
  }
}

export default Rates
