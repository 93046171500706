import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Avatar from '../Users/Avatar'

import page from '../Page.module.css'
import styles from './Requests.module.css'

class Requests extends React.Component {
  state = {
    requests: null
  }

  componentDidMount() {
    this._loadAsyncData()
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('requests_users_path', { format: 'json' }), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })
    this._asyncRequest = null
  }

  render () {
    const { requests } = this.state

    return (
      <div className={page.root}>
        <div className={page.tight}>
          <div className={page.title}>
            <h1>
              {I18n.t('users.requests.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('users.requests.title')}</title>
            </Helmet>
          </div>

          {requests &&
            <div className={styles.requests}>
              {requests.map(request =>
                <div className={styles.request}>
                  <div className={styles.avatar}>
                    <Avatar user={request.user} />
                  </div>

                  <div>
                    <div className={styles.name}>
                      {request.user.title}
                    </div>

                    <div className={styles.accounts}>
                      <Link to="user_path" params={{ id: request.user.id }}>
                        Профиль
                      </Link>

                      {request.user.social_url && request.user.social_provider == 'vkontakte' &&
                        <a href={request.user.social_url} target="_blank">
                          {request.user.social_provider}
                        </a>
                      }
                    </div>

                    {request.user.occupation &&
                      <div className={styles.occupation}>
                        {request.user.occupation}
                      </div>
                    }

                    <div className={styles.meta}>
                      {request.user.rates > 0 &&
                        <div className={styles.rates}>
                          {request.user.rates}
                        </div>
                      }

                      {request.user.gists > 0 &&
                        <div className={styles.gists}>
                          {request.user.gists}
                        </div>
                      }

                      {request.user.following > 0 &&
                        <div className={styles.following}>
                          {request.user.following}
                        </div>
                      }

                      {request.user.comments > 0 &&
                        <div className={styles.comments}>
                          {request.user.comments}
                        </div>
                      }
                    </div>

                    {request.text &&
                      <div className={styles.text}>
                        {request.text}
                      </div>
                    }
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Requests
