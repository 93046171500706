import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { EditorState, convertFromRaw } from 'draft-js'

import Loaf from '../Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css'

Edit.propTypes = {
  id: PropTypes.string
}

export default function Edit (props) {
  const { id } = props

  const [decision, setDecision] = useState()
  const [values, setValues] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { decision, values } } = await axios.get(path('edit_decision_path', { id, format: 'json' }))

      values.text = values.text ? EditorState.createWithContent(convertFromRaw(values.text)) : EditorState.createEmpty()

      setDecision(decision)
      setValues(values)
    }

    _loadAsyncData()
  }, [id])

  if (!decision) return null

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <Loaf>
          <Link to="event_path" params={{ id: decision.event.id }}>
            {decision.event.title}
          </Link>

          <Link to="task_path" params={{ id: decision.task.id }}>
            {I18n.t(`task.type.${decision.task.type}.title`)}
          </Link>

          <Link to="decision_path" params={{ id: decision.id }}>
            Результат #{decision.id}
          </Link>
        </Loaf>

        <div className={page.title}>
          <p>
            Приносим свои извинения, но редактирование временно отключено. Сервис заданий находится в режиме тестирования и поэтому некоторые функции пока находятся в стадии доработок. Как временное решение вы можете удалить результат и заново выполнить задание.
          </p>
        </div>

        {values &&
          <form>

          </form>
        }
      </div>
    </div>
  )
}
