import React from 'react'
import classNames from 'classnames'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import { Current } from '../Current'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import JoinBlock from '../Auth/JoinBlock'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

class Fork extends React.Component {
  state = {
    gist: null
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id);
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('fork_gist_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          gist: res.data.gist,
        })

        this._asyncRequest = null
      })
  }

  // handleClick = () => {
  //   this._asyncRequest = axios.CancelToken.source();
  //
  //   axios.post(
  //     this.props.routes.gists_path + '/' + this.props.id + '/dup.json',
  //     Object.assign({
  //       authenticity_token: this.props.authenticity_token
  //     }, { qqq: 'QQQ' }),
  //     { cancelToken: this._asyncRequest.token },
  //   ).then(res => {
  //     if(res.status == 201) {
  //       this.setState({
  //         redirect_to: res.headers.location
  //       })
  //     }
  //
  //     this._asyncRequest = null;
  //   });
  // }

  render () {
    const { gist } = this.state
    const { user } = this.context

    if(!gist) return null

    return (
      <div className={page.root}>
        <div className={page.tight}>
          <div className={page.title}>
            <h1>
              {gist.title}
            </h1>

            <Helmet>
              <title>{'Копирование: ' + gist.title}</title>
            </Helmet>
          </div>

          {!user &&
            <div>
              <JoinBlock redirect_to={this.props.location.pathname}>
                <p>
                  {I18n.t('gist.not_auth')}
                </p>
              </JoinBlock>
            </div>
          }

          {user &&
            <div>
              <p>
                Эта карта создана пользователем — {gist.user.title}, поэтому внести правки в эту карту напрямую вы не сможете, но вы сможете сделать себе копию этой карты и внести в нее любые правки.
              </p>
              <p>
                {/*
                  <span className={buttons.main} onClick={this.handleClick}>Копировать карту</span>
                */}
               Копирование пользовательских карт временно отключено.
              </p>
            </div>
          }
        </div>
      </div>
    );
  }
}

Fork.contextType = Current

export default Fork
