import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'

import styles from './Ideas.module.css'

class IdeasListItem extends Component {
  state = {
    collapse: true
  }

  handleClick = (e) => {
    this.setState(prevState => ({
      collapse: !prevState.collapse
    }));
  }

  render () {
    const { title, text } = this.props;

    return (
      <div className={classNames(styles.item, { [styles.collapse]: this.state.collapse })}>
        <div className={styles.title} onClick={this.handleClick}>
          <h3>
            {title}
          </h3>
        </div>


        <div className={styles.text}>
          <ReactMarkdown source={text} />
        </div>
      </div>
    );
  }
}
class IdeasList extends React.Component {
  state = {
    show: true
  }

  toggleShow = () => {
    this.setState(prevState => ({
      show: !prevState.show
    }));
  }

  render () {
    let ideas = this.props.ideas;
    if(!this.state.show) ideas = ideas.slice(0, 1);

    return (
      <React.Fragment>
        <div className={styles.list}>
          {ideas.map((idea, _) =>
            <IdeasListItem key={idea.id} title={idea.title} text={idea.text} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

class Ideas extends Component {
  render () {
    return (
      <IdeasList ideas={this.props.ideas} />
    );
  }
}

export default Ideas
