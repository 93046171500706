import React, { Component } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from './I18n'
import Link from './Routes'

import styles from './About.module.css'
import page from './Page.module.css'
import fonts from './Fonts.module.css'
import buttons from './Buttons.module.css'

import Ballon from '!svg-react-loader!../images/pages/about/ballon.svg'
import Glasses from '!svg-react-loader!../images/pages/about/glasses.svg'
import Telescope from '!svg-react-loader!../images/pages/about/telescope.svg'
import Sign_up from '!svg-react-loader!../images/pages/about/sign_up.svg'

class About extends Component {
  render () {
    return (
      <div className={classNames(page.root, styles.root)}>
        <section className={page.container12}>
          <div className={styles.index}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h1 className={classNames(fonts.h1, styles.indent)}>
                {I18n.t('pages.about.index.ideas')}
              </h1>

              <p>
                <span>
                  {I18n.t('pages.about.index.desc')}
                </span>
              </p>
            </div>

            <Helmet>
              <title>{I18n.t('pages.about.title')}</title>
            </Helmet>
          </div>
        </section>

        <section className={page.container12}>
          <div className={styles.service}>
            <div className={styles.text}>
              <h2 className={fonts.h2}>
                {I18n.t('pages.about.service.text')}
              </h2>
            </div>

            <div className={styles.indent}>
              <Ballon />
            </div>

            <div className={styles.travel}>
              <h3 className={styles.indent}>
                {I18n.t('pages.about.travel.title')}
              </h3>

              <p className={fonts.at}>
                <span>
                  {I18n.t('pages.about.travel.desc')}
                </span>
              </p>
            </div>

            <div className={classNames(styles.glasses, styles.indent)}>
              <Glasses />
            </div>

            <div className={styles.subject}>
              <h3 className={styles.indent}>
                {I18n.t('pages.about.subject.title')}
              </h3>

              <p className={fonts.at}>
                <span>
                  {I18n.t('pages.about.subject.desc')}
                </span>
              </p>
            </div>

            <div className={classNames(styles.telescope, styles.indent)}>
              <Telescope />
            </div>

            <div className={styles.opening}>
              <h3 className={styles.indent}>
                {I18n.t('pages.about.opening.title')}
              </h3>

              <p className={fonts.at}>
                <span>
                  {I18n.t('pages.about.opening.desc')}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className={page.container12}>
          <div className={styles.teachers}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h2 className={styles.indent}>
                {I18n.t('pages.about.teachers.title')}
              </h2>

              <p>
                <span>
                  {I18n.t('pages.about.teachers.desc')}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className={page.container12}>
          <div className={styles.theories}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h2 className={styles.indent}>
                {I18n.t('pages.about.theories.title')}
              </h2>

              <p>
                <span>
                  {I18n.t('pages.about.theories.desc')}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className={page.container12}>
          <div className={styles.online}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h2 className={styles.indent}>
                {I18n.t('pages.about.online.title')}
              </h2>

              <p>
                <span>
                  {I18n.t('pages.about.online.desc')}
                </span>
              </p>
            </div>
          </div>
        </section>

        <section className={page.container12}>
          <div className={styles.sign_up}>
            <div className={classNames(styles.join, styles.indent)}>
              <Sign_up />
            </div>

            <div className={styles.text}>
              <h3 className={styles.indent}>
                {I18n.t('pages.about.sign_up.text')}
              </h3>
            </div>
            <div className={styles.login}>
              <Link className={classNames(buttons.huge, styles.button)} to="login_path">{I18n.t('pages.about.sign_up.button')}</Link>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default About
