import React, { Component } from 'react'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from './I18n'
import Link from './Routes'

import styles from './Index.module.css'
import page from './Page.module.css'
import buttons from './Buttons.module.css'
import fonts from './Fonts.module.css'

class Index extends Component {
  render () {
    return (
      <div className={classNames(page.root, styles.root)}>
        <section className={page.container}>
          <div className={styles.index}>
            <div className={styles.image}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h1 className={fonts.ex}>
                {I18n.t('site_name')}
              </h1>

              <Helmet>
                <title>{I18n.t('site_name')}</title>
                <meta name="description" content={`${I18n.t('pages.index.desc.full')} ${I18n.t('pages.index.desc.short')}`} />
              </Helmet>

              <p>
                <span className={styles.full}>
                  {I18n.t('pages.index.desc.full')}
                </span>
                <span className={styles.short}>
                  {I18n.t('pages.index.desc.short')}
                </span>
              </p>

              <p>
                <Link className={classNames(buttons.huge, styles.button)} to="gist_path" params={{ id: 1 }}>
                  {I18n.t('pages.index.button')}
                </Link>
              </p>
            </div>
          </div>
        </section>

        <section className={page.container}>
          <div className={styles.livebook}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h1>
                {I18n.t('pages.index.livebook.title')}
              </h1>

              <p>
                {I18n.t('pages.index.livebook.desc.short')}
              </p>
            </div>

            <div className={classNames(styles.image, styles.secondary)}>
              <div></div>
            </div>

            <div className={styles.aside}>
              <p className={fonts.h3}>
                {I18n.t('pages.index.livebook.desc.aside')}
              </p>
            </div>
          </div>
        </section>

        <section className={page.container}>
          <div className={styles.rate}>
            <div className={classNames(styles.image, styles.main)}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h1>
                {I18n.t('pages.index.umb.title')}
              </h1>

              <p>
                <span className={styles.full}>
                  {I18n.t('pages.index.umb.desc.full')}
                </span>
                <span className={styles.short}>
                  {I18n.t('pages.index.umb.desc.short')}
                </span>
              </p>
            </div>

            <div className={classNames(styles.image, styles.secondary)}>
              <div></div>
            </div>

            <div className={styles.aside}>
              <p className={fonts.h3}>
                {I18n.t('pages.index.friends.title')}
              </p>

              <p>
                {I18n.t('pages.index.friends.desc')}
              </p>
            </div>
          </div>
        </section>

        <div className={styles.wrapper}>
          <section className={page.container}>
            <div className={styles.tasks}>
              <div className={styles.image}>
                <div></div>
              </div>

              <div className={styles.text}>
                <h1>
                  {I18n.t('pages.index.tasks.title')}
                </h1>

                <p>
                  <span className={styles.full}>
                    {I18n.t('pages.index.tasks.desc.full')}
                  </span>
                  <span className={styles.short}>
                    {I18n.t('pages.index.tasks.desc.short')}
                  </span>
                </p>

                {/*
                <p>
                  <Link className={classNames(buttons.main, styles.button)} to="gist_path"  params={{ id: 1 }}>{I18n.t('pages.index.tasks.button')}</Link>
                </p>
                */}
              </div>
            </div>
          </section>
        </div>

        <section className={page.container}>
          <div className={styles.authors}>
            <a href="//mininuniver.ru" className={styles.image}>
              <div></div>
              <span>
                mininuniver.ru
              </span>
            </a>

            <div className={styles.text}>
              <h1>
                {I18n.t('pages.index.authors.title')}
              </h1>

              <p>
                {I18n.t('pages.index.authors.desc')}
              </p>
            </div>
          </div>

          <div className={styles.join}>
            <div className={styles.image}>
              <div></div>
            </div>

            <div className={styles.text}>
              <h1>
                {I18n.t('pages.index.join.title')}
              </h1>

              <p>
                {I18n.t('pages.index.join.desc')}
              </p>

              <p>
                <Link className={classNames(buttons.huge, styles.button)} to="login_path" params={{ id: 1 }}>{I18n.t('pages.index.join.button')}</Link>
              </p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Index
