import React, { Component } from 'react'
import className from 'classnames'

import Link from '../Routes'
import I18n from '../I18n'
import Card from './Card'
import DT from '../DT'

import Avatar from '../Users/Avatar'
import styles from './List.module.css'
import buttons from '../Buttons.module.css'

class List extends Component {
  state = {
    display: this.props.display === false ? this.props.users.length : this.props.display
  }

  render() {
    const { display } = this.state
    const { node } = this.props

    if(!this.props.rates) return null

    const rates = this.props.rates.slice(0, display)

    return (
      <div className={styles.list}>
        {rates.map((rate) =>
          <Item key={rate.id} rate={rate} node={node} />
        )}

        {this.props.rates.length > display &&
          <div className={styles.more} onClick={() => this.setState(state => ({ display: state.display + this.props.display }))}>
            <div className={buttons.main}>{I18n.t('load_more')}</div>
          </div>
        }
      </div>
    )
  }
}

class Item extends Component {
  hasMeta = () => {
    return this.props.rate.comments > 0 || this.props.rate.likes > 0;
  }

  render () {
    const { rate, node } = this.props

    return (
      <div className={className(styles.item, { my: rate.my, expert: rate.expert, friend: rate.friend })}>
        <Link to="rate_path" params={{ id: rate.id }}>
          <div className={styles.header}>
            <div className={styles.avatar}>
              <Avatar user={rate.user} />
            </div>

            <div>
              <div className={styles.user}>
                {rate.user.title}
              </div>

              <div className={styles.created_at}>
                <DT value={rate.created_at} />
              </div>
            </div>
          </div>

          <div className={styles.rate}>
            <Card axes={rate.axes} marks={rate.marks} />
          </div>

          {rate.node && node &&
            <div className={styles.node}>
              {rate.node.title_short}
            </div>
          }

          {this.hasMeta() &&
            <div className={styles.meta}>
              {rate.comments > 0 &&
                <div className={styles.comments}>
                  {rate.comments}
                </div>
              }

              {rate.likes > 0 &&
                <div className={styles.likes}>
                  {rate.likes}
                </div>
              }
            </div>
          }

        </Link>
      </div>
    )
  }
}

List.defaultProps = {
  display: 24,
  node: true,
}


export default List
