import React, { Component, PureComponent } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'

import I18n from '../I18n'
import Loaf from '../Loaf'
import Link, { path } from '../Routes'
import DT from '../DT'

import Marks from './Marks'
import Comments from '../Comments/List'
import Form from '../Comments/Form'
import Avatar from '../Users/Avatar'

import styles from './Show.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

import LikeIcon from '!svg-react-loader!../../images/icons/likes.svg'

class Show extends PureComponent {
  state = {
    rate: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.rate === null) this._loadAsyncData(this.props.id)
  }

  static getDerivedStateFromProps (props, state) {
    if (props.id !== state.prevId) {
      return {
        rate: null,
        prevId: props.id
      }
    }

    return null
  }

  handleLikeClick = () => {
    this._asyncRequest = axios.CancelToken.source()
    axios.post(path('like_rate_path', { id: this.props.id, format: 'json' }), {}, { cancelToken: this._asyncRequest.token })
      .then(res => {
        this._asyncRequest = null

        if (res.status === 200) {
          if (res.headers.location) this.props.history.replace(res.headers.location)
        } else if (res.status === 201) {
          this.setState(state => ({
            rate: { ...state.rate, likes: res.data.likes, liked: res.data.liked }
          }))
        }
      })
  }

  handleUpdateComments = (comments) => {
    this.setState({ comments })
  }

  _loadAsyncData = async (id) => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('rate_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })

    this._asyncRequest = null
  }

  render () {
    const { rate, comments } = this.state

    if (!rate) return null

    const { node } = rate

    return (
      <div className={page.root}>
        <div className={page.container12}>
          <Loaf>
            <Link to="node_path" params={{ id: node.id }}>
              {node.title_short}
            </Link>

            <Link to="rates_node_path" params={{ id: node.id }}>
              {I18n.t('rates.title')}
            </Link>
          </Loaf>

          <div className={styles.header}>
            <div className={styles.header}>
              <div className={styles.avatar}>
                <Avatar user={rate.user} />
              </div>

              <div>
                <Link className={styles.name} to="user_path" params={{ id: rate.user.id }}>
                  {I18n.t('rate.share', { user: rate.user.title })}
                </Link>

                <div className={styles.created_at}>
                  <DT value={rate.created_at} />
                </div>
              </div>
            </div>

            <Helmet>
              <title>{`${I18n.t('rate.share', { user: rate.user.title })} — ${node.title}`}</title>
            </Helmet>
          </div>

          <div className={styles.root}>
            <div className={styles.rate}>
              <Marks axes={rate.axes} rates={[{ id: 'user', marks: rate.marks }]} />

              {rate.can_edit &&
                <div className={styles.actions}>
                  <Link to="edit_rate_path" params={{ id: rate.id }} className={buttons.huge}>
                    {I18n.t('edit')}
                  </Link>
                </div>
              }
            </div>

            <div className={styles.buttons}>
              <div className={classNames(styles.likes, { [styles.liked]: rate.liked })} onClick={this.handleLikeClick}>
                <LikeIcon />
                {rate.likes > 0 ? rate.likes : ' '}
              </div>

              <div className={styles.comments}>
                {rate.comments > 0 ? rate.comments : ' '}
              </div>
            </div>

            {rate.text &&
              <div className={styles.text}>
                <ReactMarkdown source={stateToMarkdown(convertFromRaw(rate.text))} />
              </div>
            }

            {!!rate.notes.length &&
              <Notes notes={rate.notes} />
            }

            <div>
              <Comments comments={comments} />

              <Form comment={{ commentable_type: 'Rate', commentable_id: rate.id }} onUpdateComments={this.handleUpdateComments} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}

export default Show

class Notes extends Component {
  state = {
    show: false
  }

  render () {
    const { notes } = this.props
    const { show } = this.state

    return (
      <div className={classNames(styles.notes, { [styles.with]: show })}>
        <div className={styles.toggle} onClick={() => this.setState(state => ({ show: !state.show }))}>
          {I18n.t('rate.notes')} ({notes.length})
        </div>

        <div className={styles.list}>
          {notes.map((note, i) =>
            <p key={i}>
              <strong>
                {I18n.t(`rate.axes.${note.axis}`)}
              </strong>
              <br />
              {note.note}
            </p>
          )}
        </div>
      </div>
    )
  }
}

Notes.propTypes = {
  notes: PropTypes.array
}
