import React from 'react'

import Konva from 'konva'

class GistMapCell {
  constructor(node, a) {
    this.a = a;
    this.x = Math.cos(a) * (1 / 20 * node.era),
    this.y = Math.sin(a) * (1 / 20 * node.era),
    this.vx = 0;
    this.vy = 0;
    this.m = 10;
    this.q = 300;
    this.dt = 1e-6;
    this.node = node;
  }
}

class GistMapSpring {
  constructor(from, to, l) {
    this.from = from;
    this.to = to;
    this.h = 1e+9;
    this.l = l;
  }
}

class GistMap extends React.Component {
  constructor(props) {
    super(props);
    this.mountRef = React.createRef();

    this.state = {
      center: {
        x: 0, y: 0
      },
      era: 40,
      size: 22,
      width: 0,
      height: 0
    }
  }

  componentDidMount() {
    this.nodes = {};
    this.springs = [];

    this.placeNode(null, 1, 0);

    Object.entries(this.nodes).map(o => {
      o[1].node.child_ids.map(ch => {
        const to = this.nodes[ch];
        const from = o[1];
        let distance_between = Math.sqrt(Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2));

        this.springs.push(new GistMapSpring(o[1].node.id, ch, distance_between));
      });
    });


    this.stage = new Konva.Stage({
      draggable: true,
      container: this.mountRef.current,
      width: this.mountRef.current.clientWidth,
      height: this.mountRef.current.clientHeight
    });

    this.layer = new Konva.Layer();

    this.updateDimensions();
    this.calcNodes();

    Object.entries(this.nodes).filter(ob => ob[1].node.child_ids.length == 0 || 1).map(ob => {
      const n = ob[1];
      let points = [];

      const addParent = (n) => {
        if(n == undefined) return;
        points.push(n.x * this.state.era * this.state.size + (this.mountRef.current.clientWidth / 2));
        points.push(n.y * this.state.era * this.state.size + (this.mountRef.current.clientHeight / 2));

        if(n.node.parent_id) {
          addParent(this.nodes[n.node.parent_id]);
        }
      }

      addParent(n);




      n.node._line = new Konva.Line({
        points: points,
        stroke: '#e9e9e9',
        strokeWidth: 1 + (points.length * points.length * 0.02),
        // strokeWidth: 1 + Math.pow((this.state.size / 2 / ((this.state.size / 2 - n.node.era) > 0 ? (this.state.size - n.node.era) : n.node.era)), 2) * 10,
        lineCap: 'round',
        lineJoin: 'round',
        tension : 0.4,
      });

      this.layer.add(n.node._line);
    });

    Object.entries(this.nodes).map(i => {
      let node = i[1];

      node._ = new Konva.Group({
        x: node.x * this.state.era * this.state.size + (this.mountRef.current.clientWidth / 2),
        y: node.y * this.state.era * this.state.size + (this.mountRef.current.clientHeight / 2),
      });

      node._rect =  new Konva.Rect({
        x: -10,
        y: -10,
        width: 100,
        height: 50,
        // fill: 'yellow',
      });

      node._circle =  new Konva.Circle({
        x: 0,
        y: 0,
        radius: 5,
        fill: '#df4fab',
        strokeWidth: 0
      });



      node._.add(node._rect);
      node._.add(node._circle);

      node._.on('click', (e) => this.handleClick(e, node));
      node._.on('mouseenter', (e) => this.handleMouseOver(e, node));
      node._.on('mouseleave', (e) => this.handleMouseOut(e, node));

      this.layer.add(node._);

      node._label = new Konva.Text({
        x: 0,
        y: 10,
        text: node.node.structurable_type == 'Conception' ? node.node.title_short.replace(/ /g, '\n') : node.node.title_short,
        fontSize: 12,
        fontFamily: 'Noto Sans',
        fontStyle: 'bold',
        fill: '#141414'
      });

      // node._label.on('mouseenter', (e) => this.handleMouseOver(e, node));
      // node._label.on('mouseleave', (e) => this.handleMouseOut(e, node));

      // node._label.hide();

      node._.add(node._label);
    });







    this.stage.add(this.layer);

    this.lastDist = 0;
    // this.startScale = 1;

    window.addEventListener('resize', this.updateDimensions);

    this.stage.getContent().addEventListener('wheel', this.wheel, false);
    this.stage.getContent().addEventListener('touchmove', this.touchmove, false);
    this.stage.getContent().addEventListener('touchend', this.touchend, false);

  }

  handleClick = (e, node) => {
    this.props.act.activateNode(node.node.id);
  }

  handleMouseOver = (e, node) => {
    this.stage.container().style.cursor = 'pointer';
    // console.log('OVER');
    node._circle.radius(10);
    node._label.show();
    this.layer.draw();
  }

  handleMouseOut = (e, node) => {
    this.stage.container().style.cursor = 'default';
    node._circle.radius(5);
    if(this.stage.scaleX() < 1) {
      node._label.hide();
    }

    this.layer.draw();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    this.layer.destroy();
    this.stage.destroy();
  }

  touchend = (e) => {
    e.preventDefault();
    this.lastDist = 0;
  }

  touchmove = (e) => {
    e.preventDefault();

    let touch1 = e.touches[0];
    let touch2 = e.touches[1];

    if(touch1 && touch2) {
      let dist = Math.sqrt(Math.pow((touch2.clientX - touch1.clientX), 2) + Math.pow((touch2.clientY - touch1.clientY), 2)) / 2;

      if(!this.lastDist) {
        this.lastDist = dist;
      }

      var scale = this.stage.getScaleX() * dist / this.lastDist;

      this.stage.scaleX(scale);
      this.stage.scaleY(scale);
      this.stage.draw();
      this.lastDist = dist;
    }
  }

  wheel = (e) => {
    e.preventDefault();

    var scaleBy = 1.05;

    var oldScale = this.stage.scaleX();
    var mousePointTo = {
      x: this.stage.getPointerPosition().x / oldScale - this.stage.x() / oldScale,
      y: this.stage.getPointerPosition().y / oldScale - this.stage.y() / oldScale,
    };

    var newScale = e.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale >= 2) {
        newScale = 2;
    } else if (newScale <= 0.5) {
        newScale = 0.5;
    }

    Object.entries(this.nodes).map(i => {
      let node = i[1];
      let newWidth = 100 * newScale;
      let newHeight = 60 / newScale;
      if(newWidth > 60) newWidth = 60;
      if(newHeight > 40) newHeight = 40;
      node._label.fontSize(12 / newScale);
      node._rect.width(newWidth);
      node._rect.height(newHeight);

      if(newScale >= 1) {
        node._label.show();
      } else {
        node._label.hide();
      }

    });

    this.stage.scale({ x: newScale, y: newScale });

    var newPos = {
      x: -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) * newScale
    };
    this.stage.position(newPos);
    this.stage.batchDraw();
    // console.log('QQ');
  }

  updateDimensions = () =>  {
    this.setState({
      center: {
        x: this.mountRef.current.clientWidth / 2,
        y: this.mountRef.current.clientHeight / 2
      },
      width: this.mountRef.current.clientWidth,
      height: this.mountRef.current.clientHeight
    });
  }

  calcForces = () => {
    Object.entries(this.nodes).map(i => {
      i[1].fx = 0;
      i[1].fy = 0;
      Object.entries(this.nodes).map(j => {
        if(i[1].node.id != j[1].node.id) {
          let dx = i[1].x - j[1].x;
          let dy = i[1].y - j[1].y;
          // console.log(i[1].node.id, j[1].node.id);
          let distance = Math.sqrt(Math.pow(i[1].x - j[1].x, 2) + Math.pow(i[1].y - j[1].y, 2));
          if(distance < 0.3) {
            // if(i[1].node.id == 110) {
            //   console.log(j[1].node.id);
            // }

            i[1].fx += i[1].q * j[1].q / Math.pow(distance, 3) * (i[1].x - j[1].x);
            i[1].fy += i[1].q * j[1].q / Math.pow(distance, 3) * (i[1].y - j[1].y);
          }
        }
      });
    });
  }

  moveNodes = () => {
    Object.entries(this.nodes).map(i => {
      let node = i[1];

      let current_radius = Math.sqrt(Math.pow(node.x, 2) + Math.pow(node.y, 2));
      let upper_radius = (1 / 20 * (node.node.era + 2));
      let lower_radius = (1 / 20 * (node.node.era - 1));

      if(node.node.era < 2) {
        lower_radius = 1 / 20;
      }

      if (current_radius < lower_radius) {
        node.x = node.x * (1 + 0.1 * (upper_radius - lower_radius) / lower_radius);
        node.y = node.y * (1 + 0.1 * (upper_radius - lower_radius) / lower_radius);
        node.vx = 0;
        node.vy = 0;
      } else if (current_radius > upper_radius) {
        node.x = node.x * (1 - 0.1 * (upper_radius - lower_radius) / upper_radius);
        node.y = node.y * (1 - 0.1 * (upper_radius - lower_radius) / upper_radius);
        node.vx = 0;
        node.vy = 0;
      } else {
        node.x += node.vx * node.dt;
        node.y += node.vy * node.dt;
        node.vx += node.fx / node.m * node.dt;
        node.vy += node.fy / node.m * node.dt;
      }
    });
  }

  calcNodes = () => {
    Array(150).fill().map((_, i) => {
      this.calcForces();
      this.moveNodes();
    });
  }

  placeNode = (parent_id, sector, offset) => {
    let nodes = Object.entries(this.props.structures).filter(ob => (ob[1].parent_id == parent_id && parent_id != null) || (ob[1].structurable_type == 'Conception' && parent_id == null));
    let current_offset = offset;

    if(nodes.length < 1) return null;

    let total = nodes.map(ob => ob[1].descendant_count + 1).reduce(function(acc, val) { return acc + val; });

    nodes.map((ob, _) =>  {
      let s = ob[1];
      let node_sector = (s.descendant_count + 1) / total * sector;

      this.nodes[s.id]= new GistMapCell(s, 2 * Math.PI * (current_offset + node_sector / 2));
      this.placeNode(s.id, node_sector, current_offset);

      current_offset += node_sector;
    });
  }

  static getDerivedStateFromProps(props, state) {
    if(props.activated) {
      if(props.activated.id !== state.prevActivatedId) {
        return {
          reActivate: true,
          prevActivatedId: props.activated.id,
        };
      }
    } else if(state.prevActivatedId !== false) {
      return  {
        reActivate: true,
        prevActivatedId: false,
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reActivate) {
      Object.entries(this.nodes).filter(ob => ob[1].node._active).map(ob => ob[1].node._active.destroy());

      if(this.props.activated) {
        const node = this.nodes[this.props.activated.id].node;
        node._active = node._line.clone();
        node._active.stroke('#df4fab');
        node._active.strokeWidth(2);
        this.layer.add(node._active);
      }

      this.layer.batchDraw();
      this.setState({ reActivate: null })
    }
  }

  render () {
    const structures = this.props.structures;

    return (
      <div className="page_gist_map">
        <div className="starmap_wrapper">
          <div className="starmap" ref={this.mountRef} />
        </div>
      </div>
    );
  }
}

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow((p2.x - p1.x), 2) + Math.pow((p2.y - p1.y), 2));
}

export default GistMap
