import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import { path } from '../Routes'
import { Current } from '../Current'

import List from './List'
import Tabs from './Tabs'

import page from '../Page.module.css'
import styles from './Index.module.css'

class Experts extends Component {
  state = {
    users: [],
    request: null
    // values: {
    //   text: ''
    // },
    // send: false
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('experts_users_path', { format: 'json' }))
    this.setState({ ...res.data })

    this._asyncRequest = null
  }

  // handleRequest = () => {
  //   if(this.state.request) return false;
  //
  //   this._asyncRequest = axios.CancelToken.source();
  //
  //   axios.post(`${this.props.routes.user_requests_path}.json`, {
  //       authenticity_token: this.props.authenticity_token,
  //       user_request: {
  //         category: 1
  //       }
  //     }, { cancelToken: this._asyncRequest.token })
  //     .then(res => {
  //       if(res.headers.location == `${this.props.routes.users_path}/experts`) {
  //         window.location = res.headers.location;
  //       } else {
  //         this.setState({
  //           redirect_to: res.headers.location
  //         });
  //       }
  //
  //       this._asyncRequest = null;
  //     });
  // }
  //
  // handleInputChange = (e) =>  {
  //   const target = e.target;
  //   const value = target.value;
  //   const name = target.name;
  //
  //   this.setState(prevState => ({
  //     values: { ...prevState.values, [name]: value }
  //   }));
  // }
  //
  // handleSubmit = (e) => {
  //   if(this.canSubmit()) {
  //     this.setState({
  //       send: true
  //     });
  //
  //     this._asyncRequest = axios.CancelToken.source();
  //
  //     axios({
  //       method: 'patch',
  //       url: `${this.props.routes.user_requests_path}/${this.state.request.id}.json`,
  //       cancelToken: this._asyncRequest.token,
  //       data: Object.assign({
  //         authenticity_token: this.props.authenticity_token
  //       }, { user_request: this.state.values })
  //     }).then(
  //       res => {
  //         this.setState({
  //           request: res.data.user_request
  //         });
  //       }
  //     );
  //   }
  //
  //   e.preventDefault();
  // }
  //
  // canSubmit = () => {
  //   return !this.state.send && this.state.values.text;
  // }

  render () {
    const { users, tabs, request } = this.state

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {I18n.t('users.experts')}
            </h1>

            <Helmet>
              <title>{I18n.t('users.experts')}</title>
            </Helmet>
          </div>

          <Tabs {...tabs} />

          {false &&
            <div className={classNames(styles.request, { [styles.requested]: request })}>
              <div className={styles.join}>
                {this.props.user && this.props.user.has_request_expert ? (
                  <React.Fragment>
                    <h3>
                      {I18n.t('users.request.almost')}
                    </h3>
                    <p>
                      {I18n.t('users.request.expert')}
                    </p>
                    {!request.text &&
                      <p className="as_at">
                        Нам будет проще рассмотреть вашу заявку если вы напишите о себе.
                        Укажите вашу должность, научное звание или все что посчитаете нужным, это описание будут видеть все участники сервиса.
                      </p>
                    }
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>
                      {I18n.t('users.request.desc')}
                    </p>
                    <p>
                      <button className="btn" onClick={this.handleRequest}>Стать экспертом!</button>
                    </p>
                  </React.Fragment>
                )}
              </div>
              {request && !request.text ? (
                <div className={styles.append}>
                  <form className="form" onSubmit={this.handleSubmit}>
                    <div className="input">
                      <div className="input_input">
                        <textarea type="text" name="text" value={this.state.values.name} onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="input_submit">
                      <input type="submit" value="Сохранить" className="btn" disabled={!this.canSubmit()} />
                    </div>
                  </form>
                </div>
              ) : (
                <div className={styles.mens} />
              )}

            </div>
          }

          <div className={styles.users}>
            <List users={users} />
          </div>
        </div>
      </div>
    )
  }
}

Experts.contextType = Current

Experts.propTypes = {
  user: PropTypes.object
}

export default Experts
