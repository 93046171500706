import React, { Component } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

import I18n from '../../I18n'

import styles from './Bios.module.css'
import buttons from '../../Buttons'

class BiosList extends Component {
  state = {
    show: false
  }

  toggleShow = () => {
    this.setState(prevState => ({
      show: !prevState.show
    }));
  }

  render () {
    let bios = this.props.bios
    if(!this.state.show) bios = bios.slice(0, 1)

    return (
      <React.Fragment>
        <div className={styles.list}>
          {bios.map(bio =>
            <div key={bio.id} className={classNames(styles.item, { [styles.inactive]: !this.state.show })}>
              <h3 className={styles.years}>
                {bio.years}
              </h3>

              <div className={styles.text}>
                <ReactMarkdown source={bio.text} />
              </div>
            </div>
          )}
        </div>

        <div className={styles.more}>
          <div className={buttons.toggle} onClick={this.toggleShow}>
            Вся биография
            <i />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class Bios extends React.Component {
  render () {
    return (
      <BiosList bios={this.props.bios} />
    );
  }
}

export default Bios
