import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
// import { Helmet } from 'react-helmet'
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import classNames from 'classnames'

import I18n from '../I18n'
import Loaf from '../Loaf'
import Link, { path } from '../Routes'

import Marks from './Marks'

import styles from './Form.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

class Form extends Component {
  state = {
    rate: null,
    destroy: false
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async (id) => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('edit_rate_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })

    res.data.values.text = res.data.values.text ? EditorState.createWithContent(convertFromRaw(res.data.values.text)) : EditorState.createEmpty()
    this.setState({ ...res.data })

    this._asyncRequest = null
  }

  handleSubmit = (e) => {
    if (this._canSubmit()) {
      this.setState({ send: true })

      this._asyncRequest = axios.CancelToken.source()

      axios({
        method: 'patch',
        url: path('rate_path', { id: this.props.id, format: 'json' }),
        cancelToken: this._asyncRequest.token,
        data: {
          rate: {
            ...this.state.values,
            text: this.state.values.text.getCurrentContent().hasText() ? convertToRaw(this.state.values.text.getCurrentContent()) : null
          }
        }
      }).then(res => {
        this._asyncRequest = null

        if (res.headers.location) this.props.history.push(res.headers.location)
      })
    }

    e.preventDefault()
  }

  handleDestroy = async () => {
    await axios.delete(path('rate_path', { id: this.props.id, format: 'json' }))
    this.setState({ destroy: true })
  }

  handleRestore = async () => {
    await axios.post(path('restore_rate_path', { id: this.props.id, format: 'json' }))
    this.setState({ destroy: false })
  }

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleInputNoteChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name

    const notes = { ...this.state.values.notes, [name]: value }

    this.setState(state => ({
      values: { ...state.values, notes: notes }
    }))
  }

  _canSubmit () {
    return !this.state.send
  }

  render () {
    const { rate, values, destroy } = this.state

    if (!rate) return null

    return (
      <div className={page.root}>
        <div className={page.container}>
          <Loaf>
            <Link to="node_path" params={{ id: rate.node.id }}>
              {rate.node.title_short}
            </Link>

            <Link to="rates_node_path" params={{ id: rate.node.id }}>
              Оценки
            </Link>
          </Loaf>

          <div className={styles.root}>
            {destroy &&
              <div className={styles.destroyed}>
                <p>
                  Оценка удалена
                </p>
                <p>
                  <button className={buttons.main} onClick={this.handleRestore}>
                    Восстановить
                  </button>
                </p>
              </div>
            }

            {rate.outdated &&
              <div className={styles.new}>
                Эта оценка создана давно, поэтому при сохранении изменений будет создана новая, а все прежние версии попадут в архив.
              </div>
            }

            <div className={styles.rate}>
              <Marks
                axes={rate.axes}
                marks={values.marks}
                onMarksChange={(marks) => this.setState(state => ({ values: { ...state.values, marks } }))}
              />
            </div>

            {!destroy &&
              <div>
                <form className={form.root} onSubmit={this.handleSubmit}>
                  <div className={form.el}>
                    <label>
                      <div className={form.label}>
                        Общий комментарий к оценке
                      </div>

                      <div className={form.input}>
                        <Editor
                          editorState={values.text}
                          onChange={(editorState) => this.setState(state => ({ values: { ...state.values, text: editorState } }))}
                        />
                      </div>
                    </label>
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      Примечания к осям
                    </div>

                    <div className={form.input}>
                      {[...new Set(rate.axes.map(a => a[0]))].map(axis =>
                        <div className={styles.note} key={axis}>
                          <div>
                            {I18n.t(`rate.axes.${axis}`)}
                          </div>
                          <div className={form.input}>
                            <textarea name={axis} value={values.notes[axis]} rows="3" onChange={this.handleInputNoteChange} disabled={this.state.send} placeholder="Примечаение к оси..." />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={form.submit}>
                    <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
                    <div className={classNames(form.destroy, buttons.destroy)} onClick={this.handleDestroy}>
                      {I18n.t('destroy')}
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}

export default Form
