import React from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import Loaf from '../Loaf'
import Link, { path } from '../Routes'

import page from '../Page.module.css';
import styles from './Form.module.css';
import buttons from '../Buttons.module.css';
import form from '../Form.module.css';

class Form extends React.Component {
  state = {
    user: null,
    send: false,
  }

  file = React.createRef()

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  handleInputChange = e =>  {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }))
  }

  handleSubmit = async e => {
    e.preventDefault()

    if(this._canSubmit()) {
      this.setState({ send: true })

      this._asyncRequest = axios.CancelToken.source()

      try {
        await axios.patch(
          path('user_path', { id: this.props.id }),
          { user: this.state.values },
          { cancelToken: this._asyncRequest.token }
        )

        if(this.file.current.files[0]) {
          const data = new FormData()
          data.append('user[avatar]', this.file.current.files[0])

          await axios.patch(
            path('user_path', { id: this.props.id }),
            data,
            { cancelToken: this._asyncRequest.token }
          )
        }
        this.props.history.push(path('user_path', { id: this.props.id }))
      } catch(e) {

        this.setState({ send: false })
      }
      this._asyncRequest = null
    }
  }

  _canSubmit = () => {
    return !this.state.send
  }

  _loadAsyncData = async id => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('edit_user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })

    this.setState({ ...res.data })
    this._asyncRequest = null
  }

  render () {
    const { user } = this.state

    if(!user) return null

    return (
      <div className={page.page}>
        <div className={page.tight}>
          <Loaf>
            <Link to="user_path" params={{ id: user.id }}>
              {user.title}
            </Link>

            <Helmet>
              <title>{user.title}</title>
            </Helmet>
          </Loaf>

          <div className={styles.form}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Имя
                  </label>
                </div>

                <div className={form.input}>
                  <input type="text" name="name" value={this.state.values.name} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Фамилия
                  </label>
                </div>

                <div className={form.input}>
                  <input type="text" name="surname" value={this.state.values.surname} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Отчество
                  </label>
                </div>

                <div className={form.input}>
                  <input type="text" name="middlename" value={this.state.values.middlename} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Должность и описание
                  </label>
                </div>

                <div className={form.input}>
                  <input type="text" name="occupation" value={this.state.values.occupation} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Аватар
                  </label>
                </div>

                <div className={form.input}>
                  <input type="file" name="avatar" ref={this.file} />
                </div>
              </div>

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form
