import React, { PureComponent, Component } from 'react'
import classNames from 'classnames'

import Link from '../../Routes'

import styles from './List.module.css';

class List extends PureComponent {
  state = {
    width: null,
    nodes: null,
    node: {
      width: null,
      height: null
    }
  }

  mount = React.createRef()
  mountNode = React.createRef()

  componentDidMount() {
    this.structures_size = Object.keys(this.props.structures).length;

    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.width != this.state.width) {
      let nodes = []

      this.props.structures.map((structure, _) => {
        const count = Math.trunc(this.state.width / this.state.node.width)

        nodes.push({
          id: structure.id,
          row: Math.floor(_ / count) + 1,
          column: _ % count + 1,
          width: this.state.node.width,
          height: this.state.node.height,
          child_ids: structure.child_ids,
          parent_id: structure.parent_id
        })
      })

      nodes.map((node, _) => {
        node.lines = []
        node.sides = {
          left: [],
          right: [],
          top: [],
          bottom: [],
        }


        nodes.filter(n => node.child_ids.includes(n.id)).map(child => {
          if(Math.abs(child.column - node.column) <= Math.abs(child.row - node.row)) {
            if(child.row > node.row) {
              node.sides.bottom.push(child.id)
            } else {
              node.sides.top.push(child.id)
            }
          } else {
            if(child.column > node.column) {
              node.sides.right.push(child.id)
            } else {
              node.sides.left.push(child.id)
            }
          }
        })

        if(node.parent_id) {
          const parent = nodes.find(s => s.id == node.parent_id)

          if(Math.abs(parent.column - node.column) < Math.abs(parent.row - node.row)) {
            if(parent.row > node.row) {
              node.sides.bottom.push(parent.id)
            } else {
              node.sides.top.push(parent.id)
            }
          } else {
            if(parent.column > node.column) {
              node.sides.right.push(parent.id)
            } else {
              node.sides.left.push(parent.id)
            }
          }
        }
      })


      nodes.map((node, _) => {
        Object.entries(node.sides).map(ob => {
          let side = ob[0]

          ob[1].map((id, _) => {
            let f = { x: 0, y: 0 }

            if(side == 'top' || side == 'bottom') f.x -= node.width / 2 + ob[1].findIndex(i => i == id) * 10
            if(side == 'left') f.x -= node.width - 10
            if(side == 'right') f.x -= 10

            if(side == 'left' || side == 'right') f.y -= node.height / 2 + ob[1].findIndex(i => i == id) * 10
            if(side == 'top') f.y -= node.height - 10
            if(side == 'bottom') f.y -= 10

            f.x += node.column * node.width
            f.y += node.row * node.height

            if(id == node.parent_id) {
              const _to = nodes.find(s => s.id == id)
              Object.entries(_to.sides).map(ob => {
                if(ob[1].includes(node.id)) {
                  let side = ob[0]
                  let t = { x: 0, y: 0}

                  if(side == 'top' || side == 'bottom') t.x -= node.width / 2 + ob[1].findIndex(i => i == node.id) * 10
                  if(side == 'left') t.x -= node.width - 10
                  if(side == 'right') t.x -= 10

                  if(side == 'left' || side == 'right') t.y -= node.height / 2 + ob[1].findIndex(i => i == node.id) * 10
                  if(side == 'top') t.y -= node.height - 10
                  if(side == 'bottom') t.y -= 10

                  t.x += _to.column * node.width
                  t.y += _to.row * node.height

                  node.lines.push({ from: f, to: t })
                }
              })
            }
          })
        })
      })

      this.setState({ nodes })
    }
  }

  updateDimensions = () =>  {
    const width = this.mount.current.getBoundingClientRect().width
    if(width != this.state.width) {
      const node = {
        height: this.mountNode.current.getBoundingClientRect().height,
        width: this.mountNode.current.getBoundingClientRect().width
      }

      this.setState({ width, node })
    }
  }

  buildLine = (points) => {
    const line = (pointA, pointB) => {
      const lengthX = pointB[0] - pointA[0]
      const lengthY = pointB[1] - pointA[1]
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
      }
    }

    const controlPoint = (current, previous, next, reverse) => {
      // When 'current' is the first or last point of the array
      // 'previous' or 'next' don't exist.
      // Replace with 'current'
      const p = previous || current
      const n = next || current
      // The smoothing ratio
      const smoothing = 0
      // Properties of the opposed-line
      const o = line(p, n)
      // If is end-control-point, add PI to the angle to go backward
      const angle = o.angle + (reverse ? Math.PI : 0)
      const length = o.length * smoothing
      // The control point position is relative to the current point
      const x = current[0] + Math.cos(angle) * length
      const y = current[1] + Math.sin(angle) * length
      return [x, y]
    }

    const bezierCommand = (point, i, a) => {
      // start control point
      const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)
      // end control point
      const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true)
      return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
    }

    // console.log(points);

    // points = [points[0], [points[0][0] + (points[1][0] - points[0][0]) * 0.6, points[0][1] + (points[1][1] - points[0][1]) * 0.3], points[1]]

    const d = points.reduce((acc, point, i, a) => i === 0
    ? `M ${point[0]},${point[1]}`
    : `${acc} ${bezierCommand(point, i, a)}`
    , '')
    return d;
  }

  render () {
    const { nodes } = this.state
    const { gist, active, linked, highlighted } = this.props
    const structures = this.props.structures.sort((a, b) => a.era - b.era)

    return (
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.zzz} ref={this.mount}>
              {nodes &&
                <>
                <div className={styles.upper}>
                  <svg>
                    {nodes.filter(node => active && node.id == active.id).map(node =>
                      <>
                        {node.lines.map((line, _) =>
                          <path
                            key={`${node.id}_${_}`}
                            d={this.buildLine([
                              [line.from.x, line.from.y],
                              [line.to.x, line.to.y]
                            ])}
                          />
                        )}
                      </>
                    )}

                    {nodes.filter(node => highlighted.includes(node.id)).map(node =>
                      <>
                        {node.lines.map((line, _) =>
                          <path
                            key={`${node.id}_${_}`}
                            d={this.buildLine([
                              [line.from.x, line.from.y],
                              [line.to.x, line.to.y]
                            ])}
                          />
                        )}
                      </>
                    )}

                    {nodes.filter(node => linked.includes(node.id)).map(node =>
                      <>
                        {node.lines.map((line, _) =>
                          <path
                            key={`${node.id}_${_}`}
                            className={styles.linked}
                            d={this.buildLine([
                              [line.from.x, line.from.y],
                              [line.to.x, line.to.y]
                            ])}
                          />
                        )}
                      </>
                    )}
                  </svg>
                </div>

                <div className={styles.lower}>
                  <svg>
                    {nodes.map(node =>
                      <>
                        {node.lines.map((line, _) =>
                          <path
                            key={`${node.id}_${_}`}
                            d={this.buildLine([
                              [line.from.x, line.from.y],
                              [line.to.x, line.to.y]
                            ])}
                          />
                        )}
                      </>
                    )}
                  </svg>
                </div>
                </>
              }

              {structures &&
                <>
                  <div className={classNames(styles.list, styles.nodes)}>
                    {structures.map((structure, _) =>
                      <Node
                        key={structure.id}
                        mountNode={ _ == 0 ? this.mountNode : null }
                        structure={structure}
                        active={active && active.id == structure.id}
                        linked={linked.includes(structure.id)}
                        highlighted={highlighted.includes(structure.id)}
                        inactive={active && !linked.includes(structure.id) && !highlighted.includes(structure.id) && structure.id != active.id} />
                    )}
                  </div>
                  <div className={classNames(styles.handlers, styles.list)}>
                    {structures.map(structure =>
                      <Handler key={structure.id} structure={structure} gist={gist} active={active} />
                    )}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class Handler extends PureComponent {
  render() {
    const { gist, structure, active } = this.props

    return (
      <Link className={styles.item} to="gist_path" params={{ id: gist.id, hash: (active && active.id == structure.id ? null : structure.id)  }}>
        <div className={styles.sq}>
          <div className={styles.image} />
          <div className={styles.name} />
        </div>
      </Link>
    );
  }
}

class Node extends PureComponent {
  render() {
    const { structure, mountNode, active, linked, highlighted, inactive } = this.props

    return (
      <div ref={mountNode} className={classNames(styles.item, styles.node, { [styles.active]: active, [styles.inactive]: inactive, 'selected': this.props.selected, [styles.faded]: structure.faded})}>
        <div className={styles.sq}>
          <div className={styles.image}>
            <>
              {structure.cover &&
                <img src={structure.cover} />
              }

              {!structure.cover &&
                <div className={styles.ascover}>
                  {structure.title_short}
                </div>
              }
            </>
          </div>
          <div className={styles.name}>
            {structure.title_short}
          </div>
        </div>
      </div>
    );
  }
}

export default List
