import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import querystring from 'querystring'

import I18n from '../I18n'

import Types from './Guide/Types'

import page from '../Page.module.css'

import styles from './Guide.module.css'

import guideImg from '../../images/pages/tasks/guide.png'

Guide.propTypes = {
  location: PropTypes.object
}

export default function Guide (props) {
  const eventId = querystring.parse(props.location.search.slice(1)).event_id

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <div className={page.title}>
          <h1>
            {I18n.t('task.new.title')}
          </h1>

          <Helmet>
            <title>{I18n.t('task.new.title')}</title>
          </Helmet>
        </div>

        <div>
          <div className={styles.image}>
            <img src={guideImg} />
          </div>

          <Types event_id={eventId} />
        </div>
      </div>
    </div>
  )
}
