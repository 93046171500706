import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Routes } from '../../Routes'

import Index from './Index'
import Form from './Form'
import Ideas from './Ideas'
import Bios from './Bios'

class Nodes extends React.Component {
  render () {
    return (
      <>
        <Switch>
          <Route path={Routes.admin_nodes_path} component={Index} exact strict />
          <Route path={Routes.new_admin_node_path} component={Form} />
          <Route path={Routes.edit_admin_node_path} render={props => <Form id={props.match.params.id} {...props} /> } />
          <Route path={Routes.ideas_admin_node_path} render={props => <Ideas id={props.match.params.id} {...props} /> } />
          <Route path={Routes.bios_admin_node_path} render={props => <Bios id={props.match.params.id} {...props} /> } />
        </Switch>
      </>
    )
  }
}


export default Nodes
