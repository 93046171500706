import React from 'react'
import PropTypes from 'prop-types'

// import { path } from '../../Routes'
// import I18n from '../../I18n'

import Form from './Decision/Form'
import Node from './Decision/Node'
import Rate from './Decision/Rate'

// import form from '../../Form.module.css'
// import buttons from '../../Buttons.module.css'
import styles from './Decision.module.css'

Decision.propTypes = {
  decision: PropTypes.object,
  decisions: PropTypes.array,
  task: PropTypes.object,
  nodes: PropTypes.array,
  onDecisionCreate: PropTypes.func
}

export default function Decision (props) {
  const { task, decision, decisions, nodes, onDecisionCreate } = props

  const handleDecisionCreate = () => {
    onDecisionCreate && onDecisionCreate()
  }

  if (!decision) return null

  return (
    <div className={styles.root}>
      {!task.has_nodes && !task.has_rates &&
        <Form task={task} onDecisionCreate={handleDecisionCreate} />
      }

      {task.has_nodes && !task.has_rates && nodes &&
        <Node decisions={decisions} task={task} nodes={nodes} onDecisionCreate={handleDecisionCreate} />
      }

      {task.has_rates && nodes &&
        <Rate decisions={decisions} task={task} nodes={nodes} onDecisionCreate={handleDecisionCreate} />
      }
    </div>
  )
}
