import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
// import { Helmet } from 'react-helmet'

import Loaf from '../Loaf'
import Link, { path } from '../Routes'
// import I18n from '../I18n'

import Decisions from '../Decisions/List'

// import styles from './Show.module.css'
import page from '../Page.module.css'

Show.propTypes = {
  id: PropTypes.string
}

export default function Show (props) {
  const [execution, setExecution] = useState()
  const [decisions, setDecisions] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { execution, decisions } } = await axios.get(path('execution_path', { id: props.id, format: 'json' }))
      setExecution(execution)
      setDecisions(decisions)
    }

    _loadAsyncData()
  }, [])

  if (!execution) return null

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <Loaf>
          <Link to="event_path" params={{ id: execution.event.id }}>
            {execution.event.title}
          </Link>

          <Link to="executions_event_path" params={{ id: execution.event.id }}>
            Результаты
          </Link>
        </Loaf>

        <div className={page.title}>
          {/* <h1>
            {I18n.t(`decision.${decision.task.type}.title`)}
          </h1>

          <Helmet>
            <title>{I18n.t(`decision.${decision.task.type}.title`)}</title>
          </Helmet> */}
        </div>

        {decisions &&
          <div>
            <Decisions decisions={decisions} />
          </div>
        }
      </div>
    </div>
  )
}
