import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js'

import I18n from '../../I18n'
import Avatar from '../../Users/Avatar'
import { Current } from '../../Current'
import Link, { path } from '../../Routes'

import JoinBlock from '../../Auth/JoinBlock'
import Marks from '../../Rates/Marks'

import styles from './Rate.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

Rate.defaultProps = {
  rates: []
}

Rate.propTypes = {
  node: PropTypes.object,
  rates: PropTypes.object,
  axes: PropTypes.array,
  history: PropTypes.object
}

export default function Rate (props) {
  const [values, setValues] = useState({ text: EditorState.createEmpty(), marks: null })

  const { axes, rates, node, history } = props

  const startValues = () => {
    if (rates.user) {
      setValues({ ...values, text: rates.user.text ? EditorState.createWithContent(convertFromRaw(rates.user.text)) : EditorState.createEmpty(), marks: Object.assign(...rates.user.marks.map(i => ({ [i.axis]: i.mark }))) })
    } else {
      setValues({ ...values, marks: Object.assign(...axes.map(axis => ({ [axis]: 60 }))) })
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    const params = {
      rate: {
        ...values,
        node_id: node.id,
        text: values.text.getCurrentContent().hasText() ? convertToRaw(values.text.getCurrentContent()) : null
      }
    }

    if (rates.user) {
      _handleUpdate(rates.user.id, params)
    } else {
      _handleCreate(params)
    }
  }

  const _handleUpdate = async (id, params) => {
    await axios.patch(
      path('rate_path', { id }),
      params
    ).then(res => {
      if (res.headers.location) history.push(res.headers.location)
    }).catch((error) => {
      this.setState({ errors: error.response.data, send: false })
    })
  }

  const _handleCreate = async params => {
    await axios.post(
      path('rates_path'),
      params
    ).then(res => {
      if (res.headers.location) history.push(res.headers.location)
    }).catch((error) => {
      this.setState({ errors: error.response.data, send: false })
    })
  }

  const { user } = useContext(Current)

  if (!axes || !rates) return null

  return (
    <>
      <div className={styles.values}>
        <Marks
          onMarksChange={(marks) => setValues({ ...values, marks })}
          axes={axes}
          rates={Object.entries(rates).map(ob => ({ ...ob[1], id: ob[0] }))}
          marks={values.marks}
        />
      </div>

      {!values.marks &&
        <div className={styles.actions}>
          {rates.user &&
            <Link to="rate_path" params={{ id: rates.user.id }} className={buttons.huge}>
              Моя оценка
            </Link>
          }

          {!rates.user &&
            <div>
              <button
                className={buttons.huge}
                onClick={startValues}
              >Оценить</button>
            </div>
          }

          {node.rates > 0 &&
            <div className={styles.rates}>
              <Link to="rates_node_path" params={{ id: node.id }} className={buttons.void}>
                {I18n.t('node.rates', { count: node.rates })}
              </Link>
            </div>
          }
        </div>
      }

      {values.marks &&
        <>
        {user &&
          <div className={styles.rate}>
            <div className={styles.user}>
              <div className={styles.avatar}>
                <Avatar user={user} />
              </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={form.el}>
                <label>
                  <div className={form.input}>
                    <Editor
                      editorState={values.text}
                      placeholder="Можете прокомментировать оценку..."
                      onChange={(editorState) => setValues({ ...values, text: editorState })}
                    />
                  </div>
                </label>
              </div>

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} />
              </div>
            </form>
          </div>
        }

        {!user &&
          <div className={styles.join}>
            <JoinBlock origin={path('node_path', { id: node.id })}>
              <p>
                {I18n.t('event.not_auth')}
              </p>
            </JoinBlock>
          </div>
        }
        </>

      }
    </>
  )
}
