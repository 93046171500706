import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'

import { Current } from '../Current'
import Link, { path } from '../Routes'
import Avatar from '../Users/Avatar'

import Tasks from './Show/Tasks'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Show.module.css'

class Show extends Component {
  state = {
    event: null,
    rates: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  // static getDerivedStateFromProps (props, state) {
  //   if (props.id !== state.prevId) {
  //     return {
  //       event: null,
  //       prevId: props.id
  //     }
  //   }
  //
  //   return null
  // }
  //
  // componentDidUpdate (prevProps, prevState) {
  //   if (this.state.event === null) this._loadAsyncData(this.props.id)
  // }

  handleStopClick = async e => {
    e.preventDefault()

    await axios.post(
      path('stop_event_path', { id: this.state.event.id })
    ).then(res => {
      // console.log(res)
      // if (res.headers.location) this.props.history.push(res.headers.location)
    })
  }

  _loadAsyncData = async id => {
    this._asyncRequest = axios.CancelToken.source()
    const res = await axios.get(path('event_path', { id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { event } = this.state

    if (!event) return null

    return (
      <div className={page.root}>
        <div className={page.container12}>
          <div className={page.title}>
            <h1>
              {event.title}
            </h1>

            <Helmet>
              <title>{event.title}</title>
            </Helmet>

            {event.can_edit &&
              <div className={page.actions}>
                <Link to="edit_event_path" params={{ id: event.id }} className={page.edit}>Редактировать</Link>
              </div>
            }

            {event.text &&
              <div className={styles.text}>
                <ReactMarkdown source={stateToMarkdown(convertFromRaw(event.text))} />
              </div>
            }

            <div className={styles.executions}>
              <Link to="executions_event_path" params={{ id: event.id }}>
                Результаты выполнения
              </Link>
            </div>
          </div>

          <div className={styles.user}>
            <div className={styles.avatar}>
              <Avatar user={event.user} />
            </div>

            <div>
              <div className={styles.name}>
                {event.user.title}
              </div>

              <div className={styles.label}>
                Автор заданий
              </div>
            </div>
          </div>

          <div className={styles.tasks}>
            <Tasks tasks={event.tasks} />

            {event.can_edit &&
              <div>
                <Link className={buttons.main} to="guide_tasks_path" params={{ query: { event_id: event.id } }}>
                  Добавить задание
                </Link>
              </div>
            }
          </div>

          <div className={styles.stop}>
            {event.can_stop &&
              <button className={buttons.huge} onClick={this.handleStopClick}>
                Закончить выполнение
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

Show.contextType = Current

Show.propTypes = {
  id: PropTypes.string
}

export default Show
