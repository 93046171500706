import React, { Component } from 'react'
import className from 'classnames'
import Konva from 'konva'

import { path } from '../../Routes'

import styles from './Graph.module.css'
import page from '../../Page.module.css'

class GistMapCell {
  constructor(node, a) {
    this.a = a;
    this.x = Math.cos(a) * (1 / 20 * node.era),
    this.y = Math.sin(a) * (1 / 20 * node.era),
    this.vx = 0;
    this.vy = 0;
    this.m = 10;
    this.q = 300;
    this.dt = 1e-6;
    this.node = node;
  }
}

class GistMapSpring {
  constructor(from, to, l) {
    this.from = from
    this.to = to
    this.h = 1e+9
    this.l = l
  }
}

class Graph extends Component {
  state = {
    center: {
      x: 0, y: 0
    },
    era: 40,
    size: 22,
    width: 0,
    height: 0
  }

  mount = React.createRef()

  componentDidMount() {
    this.nodes = new Map()
    this.springs = new Set()

    this._placeNode(null, 1, 0)

    this.nodes.forEach(node => {
      node.node.child_ids.map(id => {
        const to = this.nodes.get(id)
        const from = node
        let distance_between = Math.sqrt(Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2))

        this.springs.add(new GistMapSpring(from.node.id, id, distance_between))
      })
    })

    this.stage = new Konva.Stage({
      draggable: true,
      container: this.mount.current,
      width: this.mount.current.clientWidth,
      height: this.mount.current.clientHeight
    })

    this.layer = new Konva.Layer()

    this._updateDimensions()
    this._calcNodes()

    this.nodes.forEach(n =>  {
      let points = []

      const addParent = n => {
        if(n == undefined) return null
        points.push(n.x * this.state.era * this.state.size + (this.mount.current.clientWidth / 2))
        points.push(n.y * this.state.era * this.state.size + (this.mount.current.clientHeight / 2))

        if(n.node.parent_id) addParent(this.nodes.get(n.node.parent_id))
      }

      addParent(n)

      n.node._line = new Konva.Line({
        points: points,
        stroke: '#e9e9e9',
        strokeWidth: 1.4 + (points.length * points.length * 0.02),
        // strokeWidth: 1 + Math.pow((this.state.size / 2 / ((this.state.size / 2 - n.node.era) > 0 ? (this.state.size - n.node.era) : n.node.era)), 2) * 10,
        lineCap: 'round',
        lineJoin: 'round',
        tension : 0.4,
      });

      this.layer.add(n.node._line)
    })

    this.nodes.forEach(node => {
      node._ = new Konva.Group({
        x: node.x * this.state.era * this.state.size + (this.mount.current.clientWidth / 2),
        y: node.y * this.state.era * this.state.size + (this.mount.current.clientHeight / 2),
      })

      node._rect =  new Konva.Rect({
        x: -10,
        y: -10,
        width: 100,
        height: 50,
        // fill: 'yellow',
      });

      node._circle =  new Konva.Circle({
        x: 0,
        y: 0,
        radius: 5,
        fill: '#df4fab',
        strokeWidth: 0
      });



      node._.add(node._rect)
      node._.add(node._circle)

      node._.on('click', (e) => this._handleClick(e, node))
      node._.on('mouseenter', (e) => this._handleMouseOver(e, node))
      node._.on('mouseleave', (e) => this._handleMouseOut(e, node))

      this.layer.add(node._)

      node._label = new Konva.Text({
        x: 0,
        y: 10,
        text: node.node.structurable_type == 'Conception' ? node.node.title_short.replace(/ /g, '\n') : node.node.title_short,
        fontSize: 12,
        fontFamily: '"Source Sans Pro", sans-serif',
        fontStyle: 'bold',
        fill: '#141414'
      })

      // node._label.on('mouseenter', (e) => this.handleMouseOver(e, node));
      // node._label.on('mouseleave', (e) => this.handleMouseOut(e, node));

      // node._label.hide();

      node._.add(node._label)
    })

    this.stage.add(this.layer)

    this._highlight()

    this.lastDist = 0
    // window.addEventListener('resize', this.updateDimensions);
    //
    // this.stage.getContent().addEventListener('wheel', this._wheel, false)
    // this.stage.getContent().addEventListener('touchmove', this._touchmove, false)
    // this.stage.getContent().addEventListener('touchend', this._touchend, false)

    this.stage.on('wheel', e => {
      e.evt.preventDefault()

      const scaleBy = 1.04
      const oldScale = this.stage.scaleX()

      const mousePointTo = {
        x: this.stage.getPointerPosition().x / oldScale - this.stage.x() / oldScale,
        y: this.stage.getPointerPosition().y / oldScale - this.stage.y() / oldScale
      }

      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy

      if (newScale >= 2) {
        newScale = 2
      } else if (newScale <= 0.6) {
        newScale = 0.6
      }

      this.nodes.forEach(node => {
        let newWidth = 100 * newScale
        let newHeight = 60 / newScale
        if(newWidth > 60) newWidth = 60
        if(newHeight > 40) newHeight = 40
        node._label.fontSize(11 / newScale)
        node._rect.width(newWidth)
        node._rect.height(newHeight)

        if(newScale >= 1.1) {
          node._label.show()
        } else {
          node._label.hide()
        }
      })

      this.stage.scale({ x: newScale, y: newScale })

      var newPos = {
        x: -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) * newScale
      }

      this.stage.position(newPos)
      this.stage.batchDraw()
    })

  }

  static getDerivedStateFromProps(props, state) {
    if(props.active !== state.active) {
      return {
        active: props.active,
      }
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.active != prevState.active) {
      this._highlight()
    }


  //   if(this.state.active != prevState.active) {
  //     Object.entries(this.nodes).filter(ob => ob[1].node._active).map(ob => ob[1].node._active.destroy());
  //
  //     if(this.props.activated) {
  //       const node = this.nodes[this.props.activated.id].node;
  //       node._active = node._line.clone();
  //       node._active.stroke('#df4fab');
  //       node._active.strokeWidth(2);
  //       this.layer.add(node._active);
  //     }
  //
  //     this.layer.batchDraw();
  //     this.setState({ reActivate: null })
  //   }
  }

  render() {
    const { structures } = this.props

    return (
      <div className={styles.root}>
        <div className={styles.map} ref={this.mount} />
      </div>
    )
  }

  _touchend = (e) => {
    e.preventDefault();
    this.lastDist = 0;
  }

  _touchmove = (e) => {
    e.preventDefault();

    let touch1 = e.touches[0];
    let touch2 = e.touches[1];

    if(touch1 && touch2) {
      let dist = Math.sqrt(Math.pow((touch2.clientX - touch1.clientX), 2) + Math.pow((touch2.clientY - touch1.clientY), 2)) / 2;

      if(!this.lastDist) {
        this.lastDist = dist;
      }

      var scale = this.stage.getScaleX() * dist / this.lastDist;

      this.stage.scaleX(scale);
      this.stage.scaleY(scale);
      this.stage.draw();
      this.lastDist = dist;
    }
  }

  _wheel = e => {
    e.preventDefault()

    var scaleBy = 1.02

    var oldScale = this.stage.scaleX()
    var mousePointTo = {
      x: this.stage.getPointerPosition().x / oldScale - this.stage.x() / oldScale,
      y: this.stage.getPointerPosition().y / oldScale - this.stage.y() / oldScale,
    };

    var newScale = e.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale >= 2) {
        newScale = 2;
    } else if (newScale <= 0.5) {
        newScale = 0.5;
    }

    Object.entries(this.nodes).map(i => {
      let node = i[1];
      let newWidth = 100 * newScale;
      let newHeight = 60 / newScale;
      if(newWidth > 60) newWidth = 60;
      if(newHeight > 40) newHeight = 40;
      node._label.fontSize(12 / newScale);
      node._rect.width(newWidth);
      node._rect.height(newHeight);

      if(newScale >= 1) {
        node._label.show();
      } else {
        node._label.hide();
      }

    });

    this.stage.scale({ x: newScale, y: newScale });

    var newPos = {
      x: -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) * newScale
    };
    this.stage.position(newPos);
    this.stage.batchDraw();
    // console.log('QQ');
  }


  _placeNode = (parent_id, sector, offset) => {
    const structures = this.props.structures.filter(s => (s.parent_id == parent_id && parent_id != null) || (s.structurable_type == 'Conception' && parent_id == null))
    let current_offset = offset
    if(structures.length < 1) return null
    const total = structures.map(s => s.descendant_count + 1).reduce(function(acc, val) { return acc + val })

    structures.map(s => {
      const node_sector = (s.descendant_count + 1) / total * sector
      this.nodes.set(s.id, new GistMapCell(s, 2 * Math.PI * (current_offset + node_sector / 2)))

      this._placeNode(s.id, node_sector, current_offset)
      current_offset += node_sector
    })
  }

  _calcNodes = () => {
    Array(150).fill().map((_, i) => {
      this._calcForces()
      this._moveNodes()
    });
  }

  _calcForces = () => {
    this.nodes.forEach((_from, i) => {
      _from.fx = 0
      _from.fy = 0
      this.nodes.forEach((_to, j) => {
        if(i != j) {
          let dx = _from.x - _to.x
          let dy = _from.y - _to.y
          let distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
          if(distance < 0.3) {
            _from.fx += _from.q * _to.q / Math.pow(distance, 3) * dx
            _from.fy += _from.q * _to.q / Math.pow(distance, 3) * dy
          }
        }
      })
    })
  }

  _moveNodes = () => {
    this.nodes.forEach((node, i) => {
      let current_radius = Math.sqrt(Math.pow(node.x, 2) + Math.pow(node.y, 2))
      let upper_radius = (1 / 20 * (node.node.era + 2))
      let lower_radius = (1 / 20 * (node.node.era - 1))

      if(node.node.era < 2) {
        lower_radius = 1 / 20
      }

      if (current_radius < lower_radius) {
        node.x = node.x * (1 + 0.1 * (upper_radius - lower_radius) / lower_radius)
        node.y = node.y * (1 + 0.1 * (upper_radius - lower_radius) / lower_radius)
        node.vx = 0
        node.vy = 0
      } else if (current_radius > upper_radius) {
        node.x = node.x * (1 - 0.1 * (upper_radius - lower_radius) / upper_radius)
        node.y = node.y * (1 - 0.1 * (upper_radius - lower_radius) / upper_radius)
        node.vx = 0
        node.vy = 0
      } else {
        node.x += node.vx * node.dt
        node.y += node.vy * node.dt
        node.vx += node.fx / node.m * node.dt
        node.vy += node.fy / node.m * node.dt
      }
    })
  }

  _highlight () {
    this.nodes.forEach(node => {
      if(node.node._active) node.node._active.destroy()
    })

    if(this.state.active) {
      const node = this.nodes.get(this.state.active.id)
      node.node._active = node.node._line.clone()
      node.node._active.stroke('#df4fab')
      // node._circle.setZIndex(1)
      // node._circle.radius(10)
      // node._circle.fill('#593c81')
      node.node._active.strokeWidth(2.5)
      this.layer.add(node.node._active)
    }

    this.layer.batchDraw()
  }

  _handleClick = (e, node) => {
    this.props.history.push(`${path('map_gist_path', { id: this.props.gist.id })}#${node.node.id}`)
  }

  _handleMouseOver = (e, node) => {
    this.stage.container().style.cursor = 'pointer';
    // console.log('OVER');
    node._circle.setZIndex(1)
    node._circle.radius(7)
    // node._circle.fill('#ad3983')
    node._label.show()
    this.layer.draw()
  }

  _handleMouseOut = (e, node) => {
    this.stage.container().style.cursor = 'default';
    node._circle.radius(5)
    // node._circle.fill('#df4fab')
    if(this.stage.scaleX() < 1) {
      node._label.hide();
    }

    this.layer.draw();
  }



  _updateDimensions = () =>  {
    this.setState({
      center: {
        x: this.mount.current.clientWidth / 2,
        y: this.mount.current.clientHeight / 2
      },
      width: this.mount.current.clientWidth,
      height: this.mount.current.clientHeight
    })
  }
}

export default Graph
