import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'

import { Current } from '../Current'
import Loaf from '../Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import Marks from '../Rates/Marks'

import styles from './Show.module.css'
import page from '../Page.module.css'

Show.propTypes = {
  id: PropTypes.string
}

export default function Show (props) {
  const { id } = props
  const { user } = useContext(Current)

  const [decision, setDecision] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { decision } } = await axios.get(path('decision_path', { id, format: 'json' }))
      setDecision(decision)
    }

    _loadAsyncData()
  }, [])

  const [destroy, setDestroy] = useState(false)

  const handleDestroy = async e => {
    e.preventDefault()

    await axios.delete(path('decision_path', { id, format: 'json' }))
    setDecision({ ...decision, can_restore: true })
    setDestroy(true)
  }

  const handleRestore = async e => {
    e.preventDefault()

    await axios.post(path('restore_decision_path', { id, format: 'json' }))
    setDestroy(false)
  }

  if (!decision) return null

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <Loaf>
          <Link to="event_path" params={{ id: decision.event.id }}>
            {decision.event.title}
          </Link>

          {user && user.id === decision.user.id &&
            <Link to="task_path" params={{ id: decision.task.id }}>
              {I18n.t(`task.type.${decision.task.type}.title`)}
            </Link>
          }

          {(!user || user.id !== decision.user.id) &&
            <>
              <Link to="executions_event_path" params={{ id: decision.event.id }}>
                Результаты
              </Link>

              <Link to="execution_path" params={{ id: decision.execution.id }}>
                {decision.execution.user.title}
              </Link>
            </>
          }
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t(`decision.${decision.task.type}.title`)}
          </h1>

          <Helmet>
            <title>{I18n.t(`decision.${decision.task.type}.title`)}</title>
          </Helmet>

          {(decision.can_destroy || decision.can_edit) && !destroy &&
            <div className={page.actions}>
              {decision.can_edit &&
                <Link to="edit_decision_path" params={{ id: decision.id }} className={page.edit}>Редактировать</Link>
              }

              {decision.can_destroy &&
                <a href={path('decision_path', { id: decision.id })} className={page.destroy} onClick={handleDestroy}>Удалить</a>
              }
            </div>
          }

          {destroy && decision.can_restore &&
            <div className={page.restore}>
              Результат выполнения задания был удалён. <a href={path('restore_decision_path', { id: decision.id })} onClick={handleRestore}>Отменить удаление</a>
            </div>
          }
        </div>

        <div className={styles.root}>
          {decision.rate &&
            <div className={styles.rate}>
              <Marks axes={decision.rate.axes} rates={[{ id: 'user', marks: decision.rate.marks }]} />
            </div>
          }

          {decision.text &&
            <div className={styles.text}>
              <ReactMarkdown source={stateToMarkdown(convertFromRaw(decision.text))} />
            </div>
          }

          {decision.attachments.length > 0 &&
            <>
              <div className={styles.files}>
                {decision.attachments.filter(a => !a.is_image).map(attachment =>
                  <div key={attachment.id} className={styles.file}>
                    <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                      {attachment.title}
                    </a>
                  </div>
                )}
              </div>

              <div className={styles.images}>
                {decision.attachments.filter(a => a.is_image).map(attachment =>
                  <div key={attachment.id} className={styles.image}>
                    <img src={attachment.url} />
                  </div>
                )}
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}
