import React, { Component } from 'react'

import styles from './Card.module.css'

class Card extends Component {
  render () {
    const { size, marks, axes } = this.props
    const count = axes.length

    return (
      <div className={styles.root}>
        <svg className={styles.root} viewBox={`0 0 ${size} ${size}`}>
          <defs>
            <radialGradient id="axes"  gradientUnits="userSpaceOnUse">
              <stop stopColor="#d6d6d6" offset="0" stopOpacity="0" />
              <stop stopColor="#d6d6d6" offset="1" stopOpacity="1" />
            </radialGradient>
          </defs>

          {axes.map((l, i) =>
            <line key={i} stroke="url(#axes)" className={styles.axis} x1={size / 2} y1={size / 2} x2={Math.cos(360 / axes.length * i * Math.PI / 180) * size / 2 + size / 2} y2={Math.sin(360 / axes.length * i * Math.PI / 180) * size / 2 + size / 2} />
          )}

          <polygon
            className={styles.rate}
            points={axes.map((axis, i) => {
              let angle =  (i * (360 / count) - 90) * Math.PI / 180
              let value = ((size / 2) / 100 * marks.find(v => v.axis === axis).mark)

              return [
                Math.round((value * Math.cos(angle) + size / 2) * 100) / 100,
                Math.round((value * Math.sin(angle) + size / 2) * 100) / 100
              ].join(',')
            }).join(' ')}
          />
        </svg>
      </div>
    )
  }
}

Card.defaultProps = {
  size: 200,
  min: 0,
  k: 0.99,
  rates: []
}

export default Card
