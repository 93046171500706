import React from 'react'
import PropTypes from 'prop-types'

import styles from './Loaf.module.css'

Loaf.propTypes = {
  children: PropTypes.node
}

export default function Loaf (props) {
  return (
    <div className={styles.root}>
      {props.children}
    </div>
  )
}
