import React, { Component } from 'react'
import classNames from 'classnames'

import styles from './Practices.module.css'
import buttons from '../../Buttons.module.css'

class PracticesList extends Component {
  state = {
    show: false
  }

  toggleShow = () => {
    this.setState(prevState => ({
      show: !prevState.show
    }));
  }

  render () {
    let practices = this.props.practices;
    if(!this.state.show) practices = practices.slice(0, 4);

    return (
      <React.Fragment>
        <div className={styles.practices}>
          {practices.map(practice =>
            <div key={practice.id} className={classNames(styles.practice)}>
              <div className={styles.cover}>
                <div className={styles.img}>
                  {practice.cover &&
                    <img src={practice.cover} />
                  }
                </div>
              </div>

              <div className={styles.title}>
                {practice.title}
              </div>

              {practice.source &&
                <a target="_blank" href={practice.source} className={styles.url} />
              }
            </div>
          )}
        </div>


        {this.props.practices.length > 4 &&
          <div className={styles.read}>
            <div className={buttons.toggle} onClick={() => this.setState(state => ({ show: !state.show }))}>
              Все практики ({this.props.practices.length})
              <i />
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

class Practices extends React.Component {
  render () {
    return (
      <PracticesList practices={this.props.practices} />
    )
  }
}

export default Practices
