import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
// import classNames from 'classnames'

import I18n from '../I18n'
import { path } from '../Routes'

import Bios from './Show/Bios'
import Ideas from './Show/Ideas'
import Books from './Show/Books'
import Practices from './Show/Practices'
import Rate from './Show/Rate'

import styles from './Show.module.css'
import page from '../Page.module.css'

Show.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}

export default function Show (props) {
  const [node, setNode] = useState()
  const [bios, setBios] = useState()
  const [ideas, setIdeas] = useState()
  const [books, setBooks] = useState()
  const [practices, setPractices] = useState()
  const [rate, setRate] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { node, bios, ideas, books, practices, rate } } = await axios.get(path('node_path', { id: props.id, format: 'json' }))
      setNode(node)
      setBios(bios)
      setIdeas(ideas)
      setBooks(books)
      setPractices(practices)
      setRate(rate)
    }

    _loadAsyncData()
  }, [props.id])

  if (!node) return null

  return (
    <div>
      <div className={styles.intro}>
        <div className={page.container12}>
          <div className={page.title}>
            <h1>
              {node.title}
            </h1>

            <Helmet>
              <title>{node.title}</title>
            </Helmet>
          </div>
        </div>
      </div>

      <div className={page.container12}>
        <div className={styles.about}>
          <div className={styles.cover}>
            {node.cover &&
              <div>
                <img src={node.cover} />
              </div>
            }
          </div>

          <div className={styles.description}>
            {node.description}
          </div>
        </div>

        {!!bios && bios.length > 0 &&
          <div className={styles.bios}>
            <div className={styles.title}>
              <h2>
                {I18n.t('node.bios')}
              </h2>
            </div>

            <Bios bios={bios} />
          </div>
        }

        {!!ideas && ideas.length > 0 &&
          <div className={styles.ideas}>
            <div className={styles.title}>
              <h2>
                {I18n.t('node.ideas')}
              </h2>
            </div>

            <Ideas ideas={ideas} />
          </div>
        }

        {!!books && books.length > 0 &&
          <div className={styles.books}>
            <div className={styles.title}>
              <h2>
                {I18n.t('node.books')}
              </h2>
            </div>

            <Books books={books} />
          </div>
        }

        {!!practices && practices.length > 0 &&
          <div className={styles.practices}>
            <div className={styles.title}>
              <h2>
                {I18n.t('node.practices')}
              </h2>
            </div>

            <Practices practices={practices} />
          </div>
        }
      </div>

      {!!rate &&
        <div className={styles.rates}>
          <div className={page.container12}>
            <h2>
              {I18n.t('person.rate.title')}
            </h2>
            <p>
              {I18n.t('person.rate.desc')}
            </p>

            <Rate {...rate} node={node} history={props.history} />
          </div>
        </div>
      }

      {/* <div className={styles.rate}>
        <Rate
          onValuesChange={(values) => this.setState({ values })}
          values={values}
          axes={rates.axes}
          sets={[
            {
              id: 'experts',
              values: rates.sets.experts
            },
            {
              id: 'all',
              values: rates.sets.all
            },
            {
              id: 'own',
              values: rates.rate ? rates.rate.values : false
            }
          ]}
        />

        <div className={styles.actions}>
          <Link to="rates_node_path" params={{ id: node.id }} className={buttons.void}>
            {I18n.t('node.rates', { count: node.rates })}
          </Link>

          {rates.rate &&
            <Link className={buttons.main} to="rate_path" params={{ id: rates.rate.id }}>
              Ваша оценка
            </Link>
          }

          {!rates.rate &&
            <>
              {!create &&
                <div className={buttons.main} onClick={() => this.setState({ create: true, values: Object.assign(...rates.axes.map(axis => ({ [axis]: 60 })))})}>
                  {I18n.t('new')}
                </div>
              }

              {create &&
                <div className={buttons.main} onClick={this.handleSaveRate}>
                  {I18n.t('save')}
                </div>
              }
            </>
          }
        </div>
      </div> */}

    </div>
  )
}

// state = {
//   node: null,
//   create: false,
//   values: null
// }
//
// componentDidMount () {
//   this._loadAsyncData(this.props.id)
// }
//
// componentWillUnmount () {
//   if (this._asyncRequest) this._asyncRequest.cancel()
// }
//
// componentDidUpdate (prevProps, prevState) {
//   if (prevProps.id !== this.props.id && this.state.node === null) this._loadAsyncData(this.props.id)
// }
//
// static getDerivedStateFromProps (props, state) {
//   if (props.id !== state.prevId) {
//     return {
//       node: null,
//       prevId: props.id
//     }
//   }
//
//   return null
// }
//
// _loadAsyncData = async (id) => {
//   this._asyncRequest = axios.CancelToken.source()
//
//   const res = await axios.get(path('node_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
//   this.setState({ ...res.data })
//
//   this._asyncRequest = null
// }
//
// handleSaveRate = e => {
//   this._asyncRequest = axios.CancelToken.source()
//
//   axios.post(
//     path('rates_path'),
//     {
//       rate: {
//         node_id: this.state.node.id,
//         values: this.state.values
//       }
//     },
//     { cancelToken: this._asyncRequest.token }
//   ).then(res => {
//     if (res.headers.location) this.props.history.push(res.headers.location)
//
//     this._asyncRequest = null;
//   })
// }
