import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import { path } from '../Routes'

import List from './List'
import Tabs from './Tabs'

import page from '../Page.module.css'
import styles from './Index.module.css'
import buttons from '../Buttons.module.css'

class Index extends Component {
  state = {
    users: [],
    loading: false,
    offset: 0,
    limit: 24,
    count: false
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.offset !== this.state.offset) {
      this.setState({ loading: true })
    }

    if (!prevState.loading && this.state.loading) {
      this._loadAsyncData()
    }
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(
      path('users_path', { format: 'json' }),
      {
        cancelToken: this._asyncRequest.token,
        params: {
          offset: this.state.offset,
          limit: this.state.limit
        }
      }
    )
    this._asyncRequest = null

    const { users, tabs, count } = res.data
    this.setState(state => ({ users: [...state.users, ...users], tabs, count, loading: false }))
  }

  render () {
    const { users, tabs, count, offset, limit } = this.state

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {I18n.t('users.title')}
            </h1>

            <Helmet>
              <title>{I18n.t('users.title')}</title>
            </Helmet>
          </div>

          <Tabs {...tabs} />

          <div className={styles.users}>
            <List users={users} />
          </div>

          {count > offset + limit &&
            <div className={styles.more}>
              <div className={buttons.main} onClick={() => this.setState(state => ({ offset: state.offset + state.limit }))}>{I18n.t('load_more')}</div>
            </div>
          }

        </div>
      </div>
    )
  }
}

export default Index
