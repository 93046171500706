import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'

import Loaf from '../Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import Decision from './Show/Decision'
import Decisions from '../Decisions/List'

import styles from './Show.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'

Show.propTypes = {
  id: PropTypes.string
}

export default function Show (props) {
  const [task, setTask] = useState()
  const [nodes, setNodes] = useState()
  const [decisions, setDecisions] = useState([])
  const [decision, setDecision] = useState()

  useEffect(() => {
    const _loadAsyncData = async () => {
      const { data: { task, nodes, decisions } } = await axios.get(path('task_path', { id: props.id, format: 'json' }))
      task.goal = Math.trunc(task.goal)
      setTask(task)
      setNodes(nodes)
      setDecisions(decisions)
    }

    _loadAsyncData()
  }, [])

  const handleDecisionCreate = () => {
    const _loadAsyncData = async () => {
      const { data: { decisions } } = await axios.get(path('task_path', { id: props.id, format: 'json' }))
      setDecisions(decisions)
      setDecision()
    }

    _loadAsyncData()
  }

  const isComplete = () => task.goal > 0 ? task.goal : 1

  if (!task) return null

  return (
    <div className={page.root}>
      <div className={page.container12}>
        <Loaf>
          <Link to="event_path" params={{ id: task.event.id }}>
            {task.event.title}
          </Link>
        </Loaf>

        <div className={page.title}>
          <h1>
            {I18n.t(`task.type.${task.type}.title`)}
          </h1>

          <Helmet>
            <title>{I18n.t(`task.type.${task.type}.title`)}</title>
          </Helmet>

          {task.can_edit &&
            <div className={page.actions}>
              <Link to="edit_task_path" params={{ id: task.id }} className={page.edit}>Редактировать</Link>
            </div>
          }

          {task.text &&
            <div className={styles.text}>
              <ReactMarkdown source={stateToMarkdown(convertFromRaw(task.text))} />
            </div>
          }
        </div>

        <div className={styles.goal}>
          <span className={classNames({ [styles.complete]: decisions.length >= isComplete() })}>{decisions.length}</span>/<span>{task.goal === 0 ? '1+' : task.goal}</span>
        </div>

        <Decisions decisions={decisions} hideUser={true} />

        {task.can_decision_create && !decision &&
          <div onClick={() => setDecision({})} className={classNames(buttons.huge, styles.new)}>
            {I18n.t(`decision.${task.type}.add`)}
          </div>
        }

        <Decision task={task} decision={decision} decisions={decisions} nodes={nodes} onDecisionCreate={handleDecisionCreate} />
      </div>
    </div>
  )
}
