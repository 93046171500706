import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Form from './Form'
import Show from './Show'
import Experts from './Experts'
import Friends from './Friends'
import Requests from './Requests'

class Users extends Component {
  render () {
    return (
      <Switch>
        <Route
          path={Routes.requests_users_path}
          component={Requests}
        />

        <Route
          path={Routes.experts_users_path}
          component={Experts}
        />

        <Route
          path={Routes.friends_users_path}
          component={Friends}
        />

        <Route
          path={Routes.edit_user_path}
          render={props =>
            <Form
              id={props.match.params.id}
              history={props.history}
            />
          }
        />

        <Route
          path={Routes.user_path}
          render={props =>
            <Show id={props.match.params.id} history={props.history} />
          }
        />

        <Route
          path={Routes.users_path}
          component={Index}
        />
      </Switch>
    )
  }
}

export default Users
