import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'

import I18n from '../../I18n'
import { path } from '../../Routes'

import styles from './Add.module.css'
import buttons from '../../Buttons.module.css'
import form from '../../Form.module.css'

class Add extends Component {
  state = {
    create: false,
    values: {
      title: ''
    }
  }

  handleSelect = (id, type) => {
    this._asyncRequest = axios.CancelToken.source()
    axios.post(path('attach_gist_path', { id: this.props.gist.id, format: 'json' }), { structurable_type: type, structurable_id: id, structure_id: this.props.structure ? this.props.structure.id : null }, { cancelToken: this._asyncRequest.token, })
      .then(res => {
        if(this.props.onUpdate) this.props.onUpdate()
        this._asyncRequest = null
      })
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    const { structure } = this.props
    const url = structure && structure.structurable_type == 'Approach' ? path('conceptions_path', { format: 'json' }) : path('approaches_path', { format: 'json' })
    const data = structure && structure.structurable_type == 'Approach' ? { conception: this.state.values } : { approach: this.state.values }

    if(this._canSubmit()) {
      axios.post(url, data).then(
        res => {
          const ob = structure && structure.structurable_type == 'Approach' ? res.data.conception : res.data.approach
          this.handleSelect(ob.id, ob.type)
        }
      );
    }

    e.preventDefault()
  }

  _canSubmit = () => {
    return this.state.values.title
  }

  render () {
    const { nodes, structure } = this.props
    const { create } = this.state

    let items = nodes.filter(node => node.type == 'Approach')
    if(structure && structure.structurable_type == 'Approach') items = nodes.filter(node => node.type == 'Conception')
    if(structure && (structure.structurable_type == 'Conception' || structure.structurable_type == 'Node')) items = nodes.filter(node => node.type == 'Node')

    return (
      <div>
        {create &&
          <div className={styles.create}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Название
                  </label>
                </div>

                <div className={form.input}>
                  <input type="text" name="title" value={this.state.values.title} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.submit}>
                <input type="submit" value={I18n.t('save')} className={buttons.main} disabled={!this._canSubmit()} />
                <div className={classNames(form.cancel, buttons.cancel)} onClick={() => this.setState({ create: false })}>
                  {I18n.t('cancel')}
                </div>
              </div>
            </form>
          </div>
        }

        {!create &&
          <>
            {items.length > 0 &&
              <h3>
                Выбрать из существующих
              </h3>

            }

            <ul className={styles.list}>
              {items.map(node =>
                <li key={`${node.type}_${node.id}`} onClick={(e) => this.handleSelect(node.id, node.type)}>
                  {node.title_short}
                </li>
              )}
            </ul>

            {(!structure || structure.structurable_type == 'Approach') &&
              <div className={classNames(styles.new, buttons.second)} onClick={() => this.setState({ create: true })}>
                Создать {structure ? 'новую концепцию' : 'новый подход' }
              </div>
            }
          </>
        }
      </div>
    )
  }
}

class Add_bak extends React.Component {
  state = {
    creating: false,
    new: {
      title: ''
    }
  }

  handleClick = (id, type, structure_id, e) => {
    this.props.gistActions.addNode(id, type, structure_id);
  }

  handleInputChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      new: { ...prevState.new, [name]: value }
    }));
  }

  handleCreate = (e) => {
    const { structurable, structure } = this.props;
    const path = structurable == 'Approach' ? this.props.routes.approaches_path : this.props.routes.conceptions_path;
    const node = structurable == 'Approach' ? { approach: this.state.new } : { conception: this.state.new };

    if(this.canSave()) {
      axios.post(path, Object.assign({
        authenticity_token: this.props.authenticity_token
      }, node)).then(
        res => {
          const ob = structurable == 'Approach' ? res.data.approach : res.data.conception;
          this.handleClick(ob.id, ob.type, structure.id);
          console.log(res);
        }
      );
    }

    e.preventDefault();
  }

  handleToggleClick = () => {
    this.setState(prevState => ({
      creating: !prevState.creating
    }));
  }

  canSave = () =>  {
    return this.state.new.title;
  }

  render () {
    const { nodes, structure, structurable, I18n } = this.props;

    return (
      <React.Fragment>
        {structurable != 'Node' &&
          <div className="structure_add_create">
            {this.state.creating &&
              <form className="form">
                <div className="input">
                  <div className="input_input">
                    <input type="text" name="title" value={this.state.new.title} onChange={this.handleInputChange} />
                  </div>
                </div>

                <div className="actions">
                  <div className="actions_item" onClick={this.handleToggleClick}>
                    Отмена
                  </div>
                  <div className={classNames('actions_item', {'in': !this.canSave()})} onClick={this.handleCreate}>
                    Сохранить
                  </div>
                </div>
              </form>
            }

            {!this.state.creating &&
              <div className="as_at create">
                <p>
                  {I18n.t('gist.edit.create.' + structurable.toLowerCase() + '.desc')}
                </p>
                <p>
                  <span onClick={this.handleToggleClick}>
                    {I18n.t('gist.edit.create.' + structurable.toLowerCase() + '.url')}
                  </span>
                </p>
              </div>
            }
          </div>
        }

        {!this.state.creating &&
          <div className="structure_add_list as_at">
            {nodes.map(node =>
              <div key={node.id} className="structure_add_list_item" onClick={(e) => this.handleClick(node.id, node.type, structure.id, e)}>
                <div className="image">
                  {node.cover &&
                    <img src={node.cover} />
                  }
                </div>
                <div className="title">
                  {node.title_short}
                </div>
              </div>
            )}
          </div>
        }

      </React.Fragment>
    );
  }
}

export default Add
