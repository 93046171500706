import React from 'react'
import PropTypes from 'prop-types'
import { convertFromRaw } from 'draft-js'
// import className from 'classnames'

import Link from '../Routes'
import DT from '../DT'
import I18n from '../I18n'
import Avatar from '../Users/Avatar'
import Card from '../Rates/Card'

import grid from '../Grid.module.css'
import styles from './List.module.css'

List.propTypes = {
  decisions: PropTypes.array,
  hideUser: PropTypes.bool
}

List.defaultProps = {
  hideUser: false
}

export default function List (props) {
  const { decisions, hideUser } = props

  const anno = text => {
    const anno = ''

    if (text) {
      const q = convertFromRaw(text).getPlainText()

      return q.length > 150 ? q.slice(0, 140) + '...' : q
    } else {
      return anno
    }
  }

  if (!decisions || decisions.length < 1) return null

  return (
    <div className={grid.root}>
      {decisions.map(decision =>
        <Link key={decision.id} className={grid.a} to="decision_path" params={{ id: decision.id }}>
          {!hideUser &&
            <div className={grid.header}>
              <div className={grid.avatar}>
                <Avatar user={decision.user} />
              </div>

              <div>
                <div className={grid.user}>
                  {decision.user.title}
                </div>

                <div className={grid.created_at}>
                  <DT value={decision.created_at} />
                </div>
              </div>
            </div>
          }

          {!decision.rate &&
            <div className={grid.main}>
              <div className={styles.text}>
                <strong>
                  {I18n.t(`decision.${decision.task.type}.title`)}
                </strong>
                <br />
                {anno(decision.text)}
              </div>
            </div>
          }

          {decision.node &&
            <div className={grid.node}>
              {decision.node.title_short}
            </div>
          }

          {decision.rate &&
            <>
              <div className={grid.rate}>
                <Card axes={decision.rate.axes} marks={decision.rate.marks} />
              </div>

              <div className={grid.node}>
                {decision.rate.node.title_short}
              </div>
            </>
          }
        </Link>
      )}
    </div>
  )
}
