import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { convertFromRaw } from 'draft-js'
import { stateToMarkdown } from 'draft-js-export-markdown'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
// import Plain from 'slate-plain-serializer'
// import { Value } from 'slate'

import Link, { path } from '../Routes'
// import { Render } from '../Slate'
import I18n from '../I18n'
import DT from '../DT'

import Avatar from '../Users/Avatar'

import styles from './List.module.css'

class List extends Component {
  render () {
    const { comments } = this.props

    if (!comments) return null

    return (
      <div className={styles.list}>
        {comments.map((comment) =>
          <Item key={comment.id} comment={comment} />
        )}
      </div>
    )
  }
}

List.propTypes = {
  comments: PropTypes.array
}

export default List

class Item extends Component {
  state = {
    destroy: false
  }

  handleDestroy = async e => {
    await axios.delete(path('comment_path', { id: this.props.comment.id, format: 'json' }))
    this.setState({ destroy: true })
  }

  render () {
    const { comment } = this.props
    const { destroy } = this.state

    let value = comment.body

    if (comment.value) {
      value = stateToMarkdown(convertFromRaw(comment.value))
    }

    if (destroy) return <Destroyed />

    return (
      <div className={styles.item}>
        <div className={styles.header}>
          {comment.can_destroy &&
            <div className={styles.destroy} onClick={this.handleDestroy} />
          }

          <div className={styles.avatar}>
            <Avatar user={comment.user} />
          </div>

          <div>
            <Link className={styles.name} to="user_path" params={{ id: comment.user.id }}>
              {comment.user.title}
            </Link>

            <div className={styles.created_at}>
              <DT value={comment.created_at} />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          <ReactMarkdown source={value} />
        </div>
      </div>
    )
  }
}

Item.propTypes = {
  comment: PropTypes.object
}

function Destroyed () {
  return (
    <div className={classNames(styles.item, styles.destroyed)}>
      {I18n.t('comment.destroyed')}
    </div>
  )
}
