import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import I18n from '../I18n'
import Link, { path } from '../Routes'
import Loaf from '../Loaf'

import List from '../Rates/List';

// import styles from './Rates.module.css'
import page from '../Page.module.css'

class Rates extends Component {
  state = {
    node: null,
    rates: null
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rates === null) this._loadAsyncData(this.props.id);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id !== state.prevId) {
      return {
        rates: null,
        prevId: props.id,
      };
    }

    return null;
  }

  _loadAsyncData = async (id) => {
    this._asyncRequest = axios.CancelToken.source();

    const res = await axios.get(path('rates_node_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })

    this._asyncRequest = null
  }

  render() {
    const { rates, node } = this.state

    if(!rates) return null

    return (
      <div className={page.root}>
        <div className={page.container12}>
          <Loaf>
            <Link to="node_path" params={{ id: node.id }}>
              {node.title_short}
            </Link>
          </Loaf>

          <div className={page.title}>
            <h1>
              {I18n.t('node.title.rates', { node: node.title_short })}
            </h1>

            <Helmet>
              <title>Оценки</title>
            </Helmet>
          </div>

          <div>
            <List rates={rates} node={false} />
          </div>
        </div>
      </div>
    )
  }
}

export default Rates
