import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import update from 'immutability-helper'
import classNames from 'classnames'

import I18n from '../../I18n'
import { path } from '../../Routes'

import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'
import styles from './Ideas.module.css'

class Ideas extends React.Component {
  state = {
    send: false,
    node: null,
    ideas: [],
    redirect_to: null
  }

  values = {
    id: null,
    title: '',
    desc: '',
    text: ''
  }

  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('ideas_admin_node_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const ideas = res.data.ideas.map(idea => {
          I18n.available_locales.map(locale => {
            if(!idea[locale]) {
              idea[locale] = { values: {...this.values, locale: locale} }
            }
            idea[locale].show = false
          });
          return idea;
        });

        this.setState({
          node: res.data.node,
          ideas: ideas,
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  handleLocaleInputChange = (e, locale, i) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const ideas = update(this.state.ideas, {
      [i]: {
        [locale]: {
          values: {
            [name]: {
              $set: value
            }
          }
        }
      }
    });

    this.setState({
      ideas
    });
  }

  handleClick = (e, locale, i) => {
    const ideas = update(this.state.ideas, {
      [i]: {
        [locale]: {
          $toggle: ['show']
        }
      }
    });

    this.setState({
      ideas
    });
  }

  handleNewClick = () => {
    let idea = { id: null };
    I18n.available_locales.map(locale => {
      idea[locale] = { values: {...this.values, locale: locale} };
    });

    const ideas = update(this.state.ideas, { $push: [idea] });

    this.setState({ ideas });
  }

  _canSubmit = () => {
    return !this.state.send
  }


  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: 'patch',
        url: path('admin_node_path', { id: this.props.id, format: 'json' }),
        cancelToken: this._asyncRequest.token,
        data: { node: {
          ideas_attributes: this.state.ideas.map(idea => {
            return {
              id: idea.id,
              translations_attributes: I18n.available_locales.map(locale => {
                return idea[locale].values;
              })
            };
          })
        }}
      }).then(
        res => {
          this._asyncRequest = null;
          if(res.headers.location) this.props.history.replace(res.headers.location)

        }
      ).catch((error) => {
        console.log(error);
        this.setState({ send: false });
      }).then(() => {

      });
    }

    e.preventDefault();
  }

  render() {
    const { ideas, node } = this.state

    if(!node) return null;

    const title = `Идеи: ${node.title_short}`

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.compact}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              {ideas.map((idea, _) =>
                <div className={styles.idea} key={_}>
                  <div className={styles.locales}>
                    {I18n.available_locales.map(locale =>
                      <React.Fragment key={locale}>
                        <div className={styles.title} onClick={(e) => this.handleClick(e, locale, _)}>
                          {idea[locale].values.title || `${locale.toUpperCase()}: ${I18n.t('admin.no_translation')}`}
                        </div>

                        <div className={classNames(styles.form, { [styles.collapsed]: !idea[locale].show })}>
                          <div className={form.elem}>
                            <div className={form.label}>
                              Название идеи
                            </div>

                            <div className={form.input}>
                              <input type="text" name="title" value={idea[locale].values.title} onChange={(e) => this.handleLocaleInputChange(e, locale, _)} />
                            </div>
                          </div>

                          <div className={form.elem}>
                            <div className={form.label}>
                              Краткое описание идеи
                            </div>

                            <div className={form.input}>
                              <textarea name="desc" value={idea[locale].values.desc} rows="5" onChange={(e) => this.handleLocaleInputChange(e, locale, _)} />
                            </div>
                          </div>

                          <div className={form.elem}>
                            <div className={form.label}>
                              Подробное описание идеи
                            </div>

                            <div className={form.input}>
                              <textarea name="text" value={idea[locale].values.text} rows="13" onChange={(e) => this.handleLocaleInputChange(e, locale, _)} />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              <div className={styles.new} onClick={this.handleNewClick}>
                Добавить новую идею
              </div>

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Ideas
