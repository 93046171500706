import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'
import Form from './Form'
import Fork from './Fork'
import Structure from './Structure'

class Gists extends Component {
  render () {
    return (
      <Switch>
        <Route path={Routes.structure_gist_path} render={props => <Structure id={props.match.params.id} {...props} />} />
        <Route path={Routes.fork_gist_path} render={props => <Fork id={props.match.params.id} {...props} />} />
        <Route path={Routes.edit_gist_path} render={props => <Form id={props.match.params.id} {...props} />} />
        <Route path={Routes.new_gist_path} component={Form} />
        <Route path={Routes.map_gist_path} render={props => <Show id={props.match.params.id} {...props} type="map" />} />
        <Route path={Routes.d3_gist_path} render={props => <Show id={props.match.params.id} {...props} type="map" />} />
        <Route exact path={Routes.gist_path} render={props => <Show id={props.match.params.id} {...props} />} />
      </Switch>
    )
  }
}

export default Gists
