import React, { useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Editor, EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import classNames from 'classnames'

import { path } from '../../../Routes'
import Errors from '../../../Errors'
import I18n from '../../../I18n'

import Files from './Files'

import buttons from '../../../Buttons.module.css'
import form from '../../../Form.module.css'
import styles from './Node.module.css'

Form.propTypes = {
  task: PropTypes.object,
  history: PropTypes.object,
  onDecisionCreate: PropTypes.func
}

export default function Form (props) {
  const { task, onDecisionCreate } = props

  const drafts = JSON.parse(localStorage.getItem('decisions')) || {}

  const [values, setValues] = useState({ text: drafts[task.id] ? EditorState.createWithContent(convertFromRaw(drafts[task.id])) : EditorState.createEmpty(), attachment_ids: [] })
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  const handleEditorChange = editorState => {
    localStorage.setItem('decisions', JSON.stringify({ ...drafts, [task.id]: convertToRaw(editorState.getCurrentContent()) }))

    setValues({ ...values, text: editorState })
  }

  const handleCreate = async (e) => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('decisions_path'),
      {
        decision: {
          ...values,
          task_id: task.id,
          text: values.text.getCurrentContent().hasText() ? convertToRaw(values.text.getCurrentContent()) : null
        }
      }
    ).then(res => {
      localStorage.setItem('decisions', JSON.stringify({ ...drafts, [task.id]: null }))
      onDecisionCreate && onDecisionCreate()
    }).catch((error) => {
      setErrors(error.response.data)
      setSend(false)
    })
  }

  if (!values) return null

  return (
    <form onSubmit={handleCreate}>
      <h2>{I18n.t(`decision.${task.type}.new`)}</h2>

      <div className={styles.form}>
        <div className={form.el}>
          <label>
            <div className={form.input}>
              <Editor
                editorState={values.text}
                onChange={handleEditorChange}
                placeholder={I18n.t(`decision.${task.type}.placeholder`)}
              />
            </div>
          </label>

          <Errors errors={errors.text} />
        </div>

        {task.attachment_formats &&
          <div>
            <Files formats={task.attachment_formats} onFileUpload={id => setValues({ ...values, attachment_ids: [...values.attachment_ids, id] })} />
          </div>
        }

        <div className={form.submit}>
          <input type="submit" value="Сохранить" className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} />
        </div>
      </div>
    </form>
  )
}
