import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'

import I18n from '../../I18n'
import Link, { path } from '../../Routes'

import styles from './Index.module.css'
import page from '../../Page.module.css'

class Index extends React.Component {
  state = {
    nodes: []
  }

  componentDidMount() {
    this._loadAsyncData();
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('admin_nodes_path', { format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          nodes: res.data.nodes,
        });
        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  render() {
    const { nodes } = this.state

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {I18n.t('nodes.nav')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('nodes.nav')}
              </title>
            </Helmet>
          </div>

          <div className={page.compact}>
            <div className={styles.nodes}>
              {nodes.map(node =>
                <div className={styles.node} key={node.id}>
                  <div className={styles.title}>
                    {node.title}
                  </div>
                  <div className={styles.actions}>
                    <Link to="edit_admin_node_path" params={{ id: node.id }}>Редактировать педагога</Link>
                    <Link to="ideas_admin_node_path" params={{ id: node.id }}>Редактировать идеи</Link>
                    <Link to="bios_admin_node_path" params={{ id: node.id }}>Редактировать биографию</Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index
