import React, { Component } from 'react'
// import classNames from 'classnames'

import I18n from './I18n'
import Link from './Routes'

import styles from './Footer.module.css'

class Footer extends Component {
  render () {
    return (
      <footer className={styles.root}>
        <div className={styles.menu}>
          <ul>
            <li>
              <Link activeClassName={styles.active} exact to="root_path" exact strict>{I18n.t('pages.index.title')}</Link>
            </li>
            <li>
              <Link activeClassName={styles.active} to="gist_path" params={{ id: 1 }}>{I18n.t('gist.main')}</Link>
            </li>
            <li>
              <Link activeClassName={styles.active} to="experts_users_path">{I18n.t('users.experts')}</Link>
            </li>
            <li>
              <Link activeClassName={styles.active} to="about_path">{I18n.t('pages.about.title')}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.authors}>
          <p>
            {I18n.t('pages.index.authors.desc')}
          </p>

          <p>
            Сервис работает в ограниченном режиме
            <br />
            Обо всех ошибках просим сообщать на почту <a href="mailto:support@wemap.ru">support@wemap.ru</a>
          </p>
        </div>
      </footer>
    )
  }
}

export default Footer
