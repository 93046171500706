import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { Editor, EditorState, convertToRaw } from 'draft-js'

import { path } from '../Routes'
import I18n from '../I18n'
import { Current } from '../Current'
import Errors from '../Errors'
import Avatar from '../Users/Avatar'
import JoinBlock from '../Auth/JoinBlock'

import styles from './Form.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'

Form.propTypes = {
  comment: PropTypes.object,
  onUpdateComments: PropTypes.func
}

Form.defaultProps = {
  comment: {}
}

export default function Form (props) {
  const { comment, onUpdateComments } = props
  const { user } = useContext(Current)

  const [values, setValues] = useState({ value: EditorState.createEmpty() })
  const [send, setSend] = useState(false)
  const [errors, setErrors] = useState({})

  const handleCreate = async e => {
    e.preventDefault()

    if (send) {
      return null
    } else {
      setErrors({})
      setSend(true)
    }

    await axios.post(
      path('comments_path', { format: 'json' }),
      {
        comment: {
          ...values,
          value: values.value.getCurrentContent().hasText() ? convertToRaw(values.value.getCurrentContent()) : null,
          ...(!comment.id && { commentable_id: comment.commentable_id, commentable_type: comment.commentable_type })
        }
      }
    ).then(res => {
      onUpdateComments && onUpdateComments(res.data.comments)
      setValues({ value: EditorState.createEmpty() })
    }).catch((error) => {
      setErrors(error.response.data)
    })

    setSend(false)
  }

  const _canSubmit = () => {
    return values.value.getCurrentContent().hasText() && !send
  }

  if (!user) {
    return (
      <div className={styles.join}>
        <JoinBlock origin={path(`${comment.commentable_type.toLowerCase()}_path`, { id: comment.commentable_id })}>
          <p>
            {I18n.t('comments.auth')}
          </p>
        </JoinBlock>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <div className={styles.user}>
        <div className={styles.avatar}>
          <Avatar user={user} />
        </div>
      </div>

      <div className={styles.form}>
        <form className={form.root} onSubmit={handleCreate}>
          <div className={form.el}>
            <div className={form.input}>
              <Editor
                placeholder="Напишите комментарий.."
                editorState={values.value}
                onChange={(editorState) => setValues({ ...values, value: editorState })}
              />
            </div>

            <Errors errors={errors.value} />
          </div>

          <div className={form.submit}>
            {/* <input type="submit" value="Сохранить" className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} /> */}
            <input type="submit" value="Комментировать" className={classNames(buttons.main, { [buttons.progress]: send })} disabled={!_canSubmit()} />
          </div>
        </form>
      </div>
    </div>
  )
}
