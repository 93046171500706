import React, { Component } from 'react'

import I18n from './I18n'

import page from './Page.module.css'
import styles from './Loader.module.css'

class Loader extends Component {
  render () {
    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={styles.text}>
              {I18n.t('loading')}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Loader
