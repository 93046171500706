import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Show from './Show'
import Form from './Form'
import Executions from './Executions'

class Events extends Component {
  render () {
    return (
      <Switch>
        <Route
          path={Routes.executions_event_path}
          render={props =>
            <Executions id={props.match.params.id} />
          }
        />

        <Route
          path={Routes.edit_event_path}
          render={props =>
            <Form id={props.match.params.id} history={props.history} />
          }
        />

        <Route path={Routes.new_event_path} component={Form} />
        <Route path={Routes.event_path} render={props => <Show id={props.match.params.id} />} />
      </Switch>
    )
  }
}

export default Events
