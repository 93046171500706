import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import Link, { path } from '../../Routes'

import styles from './Events.module.css'
import buttons from '../../Buttons.module.css'

class Events extends Component {
  state = {
    events: []
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async id => {
    this._asyncRequest = axios.CancelToken.source()
    const res = await axios.get(path('events_user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    this.setState({ ...res.data })
  }

  render () {
    const { events } = this.state
    const { title } = this.props

    if (!events) return null

    return (
      <>
        <Helmet><title>{title}</title></Helmet>

        {events.length > 0 &&
          <div className={styles.events}>
            {events.map(event =>
              <Link key={event.id} to="event_path" params={{ id: event.id }} className={styles.event}>
                {event.title}
              </Link>
            )}
          </div>
        }

        {events.length === 0 &&
          <p>
            Мы добавили возможность создавать задания для других участников проекта. Вы сможете подготовить различные наборы обязательных действий и отслеживать процесс их выполнения.
          </p>
        }

        <div>
          <Link to="new_event_path" className={buttons.huge}>
            Создать комплекс заданий
          </Link>
        </div>
      </>
    )
  }
}

Events.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string
}

export default Events
