import React from 'react'
import PropTypes from 'prop-types'

import form from './Form.module.css'

function Errors (props) {
  return (
    <>
      {props.errors &&
        <div className={form.error}>
          <ul>
            {props.errors.map((error, _) => <li key={_}>{error}</li>)}
          </ul>
        </div>
      }
    </>
  )
}

Errors.propTypes = {
  errors: PropTypes.array
}

export default Errors

export function Summary (props) {
  if (Object.entries(props.errors).length < 1) return null

  return (
    <div className={form.error}>
      Невозможно сохранить, поскольку присутствуют ошибки, вероятно заполнены не все обязательные поля.
    </div>
  )
}

Summary.propTypes = {
  errors: PropTypes.object
}
