import React from 'react'
import classNames from 'classnames'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import { Current } from '../Current'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import JoinBlock from '../Auth/JoinBlock'

import styles from './Form.module.css'
import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'

class Form extends React.Component {
  state = {
    values: {
      title: '',
      text: ''
    },
    submit: {
      url: path('gists_path', { format: 'json' }),
      method: 'post'
    },
    redirect_to: null
  }

  componentDidMount() {
    if(this.props.id) {
      this._loadAsyncData(this.props.id);
    }
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('edit_gist_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          values: {
            title: res.data.gist.title,
            text: res.data.gist.text,
          },
          submit: {
            url: path('gist_path', { id: id, format: 'json' }),
            method: 'patch'
          }
        });

        this._asyncRequest = null
      });
  }

  handleSubmit = (e) => {
    if(this.canSubmit()) {
      this._asyncRequest = axios.CancelToken.source()

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: Object.assign({
          authenticity_token: this.props.authenticity_token
        }, { gist: this.state.values })
      }).then(
        res => {
          if(res.headers.location) this.props.history.replace(res.headers.location)

          this._asyncRequest = null;
        }
      );
    }

    e.preventDefault();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  canSubmit = () => {
    return this.state.values.title;
  }

  render () {
    const { user } = this.context

    return (
      <div className={page.root}>
        <div className={page.tight}>
          <div className={page.title}>
            <Helmet>
              <title>{I18n.t('gist.new.title')}</title>
            </Helmet>
          </div>

          {user &&
            <form className={form.root} onSubmit={this.handleSubmit}>
              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Название
                  </label>
                </div>

                <div className={form.input}>
                  <input
                    className={form.h1}
                    type="text"
                    name="title"
                    placeholder="Добавьте название карты"
                    value={this.state.values.title}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  <label>
                    Описание
                  </label>
                </div>

                <div className={form.input}>
                  <textarea
                    name="text"
                    value={this.state.values.text}
                    rows="2"
                    placeholder="Расскажите несколько слов о вашей интерпретации"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className={form.submit}>
                <input type="submit" value="Создать карту" className={buttons.main} disabled={!this.canSubmit()} />
              </div>
            </form>
          }

          {!user &&
            <div className={styles.join}>
              <JoinBlock redirect_to={this.props.location.pathname}>
                <p>
                  {I18n.t('gist.not_auth')}
                </p>
              </JoinBlock>
            </div>
          }
        </div>
      </div>
    );
  }
}

Form.contextType = Current

export default Form
