import React, { Component } from 'react'
import classNames from 'classnames'

import Link from '../../Routes'
import I18n from '../../I18n'

import styles from './Detail.module.css'

class Detail extends Component {
  render () {
    const { active } = this.props

    if(!this.props.active) return null;

    return (
      <div className={classNames(styles.root, { [styles.active]: active })}>
        {active &&
          <>
            <h2 className={styles.title}>
              {active.title}
            </h2>

            <div className={styles.meta}>
              <ul>
                {!!active.gists &&
                  <li>
                    <div className={classNames(styles.label, styles.gists)}>
                      {I18n.t('gists.title')}
                    </div>

                    <div className={styles.value}>
                      {active.gists}
                    </div>
                  </li>
                }

                {!!active.rates &&
                  <li>
                    <div className={classNames(styles.label, styles.rates)}>
                      {I18n.t('rates.title')}
                    </div>

                    <div className={styles.value}>
                      {active.rates}
                    </div>
                  </li>
                }
              </ul>
            </div>

            {active.structurable_type == 'Node' &&
              <div className={styles.more}>
                <Link to="node_path" params={{ id: active.structurable_id }}>
                  {I18n.t('node.read')}
                </Link>
              </div>
            }
          </>
        }
      </div>
    );
  }
}

export default Detail
