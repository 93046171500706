import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18n from '../I18n'
import { path } from '../Routes'

import styles from './JoinBlock.module.css'
import buttons from '../Buttons.module.css'

class JoinBlock extends React.Component {
  render () {
    const { origin } = this.props

    return (
      <>
        {this.props.children &&
          <div className={styles.desc}>
            {this.props.children}
          </div>
        }

        <div className={styles.networks}>
          <a className={classNames(styles.network, styles.facebook, buttons.main)} href={`${path('user_facebook_omniauth_authorize_path')}${origin ? `?origin=${origin}` : ''}`}>
            {I18n.t('auth.join.facebook')}
          </a>
          <a className={classNames(styles.network, styles.vkontakte, buttons.main)} href={`${path('user_vkontakte_omniauth_authorize_path')}${origin ? `?origin=${origin}` : ''}`}>
            {I18n.t('auth.join.vkontakte')}
          </a>
        </div>
      </>
    )
  }
}

JoinBlock.propTypes = {
  origin: PropTypes.string,
  children: PropTypes.node
}

export default JoinBlock
