import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import Link, { path, Routes } from '../Routes'
import { Current } from '../Current'

import Avatar from './Avatar'
import Rates from './Show/Rates'
import Gists from './Show/Gists'
import Events from './Show/Events'
import Following from './Show/Following'

import page from '../Page.module.css'
import styles from './Show.module.css'

class Show extends Component {
  state = {
    user: null
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  static getDerivedStateFromProps (props, state) {
    if (props.id !== state.prevId) {
      return {
        user: null,
        prevId: props.id
      }
    }

    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.user === null) this._loadAsyncData(this.props.id)
  }

  _loadAsyncData (id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { user } = res.data

        this.setState({ user })

        this._asyncRequest = null
      })
  }

  logoutHandle = async e => {
    e.preventDefault()
    await axios.delete(e.target.getAttribute('href'))
    // if (typeof window !== 'undefined') window.location = path('root_path')
  }

  render () {
    const { user } = this.state
    const current = this.context.user

    return (
      <div className={page.root}>
        <div className={page.container}>
          {user &&
            <div className={classNames(page.title, styles.title)}>
              <div className={styles.avatar}>
                <Avatar user={user} size="m" />
              </div>

              <div className={styles.text}>
                <h1>
                  {user.title}
                </h1>

                {user.occupation &&
                  <p className={styles.occupation}>
                    {user.occupation}
                  </p>
                }

                {current && current.id === user.id && user.can_edit &&
                  <p className={styles.me}>
                    <Link to="edit_user_path" params={{ id: user.id }} className={styles.edit}>
                      {I18n.t('user.edit')}
                    </Link>

                    <a href={path('login_path')} onClick={this.logoutHandle} data-method="delete">
                      {I18n.t('auth.logout')}
                    </a>
                  </p>
                }

                {user.expert &&
                  <div className="expert as_at">
                    {I18n.t('user.expert')}
                  </div>
                }

                <Helmet>
                  <title>{user.title}</title>
                </Helmet>
              </div>
            </div>
          }
        </div>

        {user &&
          <div className={styles.rest}>
            <div className={page.container}>
              <div className={styles.tabs}>
                <ul>
                  <li>
                    <Link exact to="user_path" params={{ id: user.id }} activeClassName={styles.active}>
                      {I18n.t('user.rates')}

                      <span className={styles.counter}>
                        {user.rates}
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="gists_user_path" params={{ id: user.id }} activeClassName={styles.active}>
                      {I18n.t('user.gists')}

                      <span className={styles.counter}>
                        {user.gists}
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link to="events_user_path" params={{ id: user.id }} activeClassName={styles.active}>
                      {I18n.t('user.events')}

                      <span className={styles.counter}>
                        {user.events}
                      </span>
                    </Link>
                  </li>

                  {!!user.following &&
                    <li>
                      <Link to="following_user_path" params={{ id: user.id }} activeClassName={styles.active}>
                        {I18n.t('user.following')}

                        <span className={styles.counter}>
                          {user.following}
                        </span>
                      </Link>
                    </li>
                  }
                </ul>
              </div>

              <Switch>
                <Route exact strict path={Routes.user_path} render={props => <Rates id={props.match.params.id} />} />
                <Route path={Routes.gists_user_path} render={props => <Gists id={props.match.params.id} />} />

                <Route
                  path={Routes.events_user_path}
                  render={props =>
                    <Events
                      id={props.match.params.id}
                      title={`${I18n.t('user.events')} (${user.title})`}
                    />
                  }
                />

                <Route path={Routes.following_user_path} render={props => <Following id={props.match.params.id} />} />
              </Switch>
            </div>
          </div>
        }
      </div>
    )
  }
}

Show.contextType = Current

Show.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}

export default Show
