import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import Link, { path } from '../Routes'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'
import styles from './Login.module.css'

class Login extends Component {
  state = {
    send: false,
    error: false,
    toggle: false,
    values: {
      email: '',
      password: ''
    }
  }

  likeExpert () {
    return this.props.location.hash === '#expert'
  }

  componentDidMount () {
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    e.preventDefault()

    if (this._canSubmit()) {
      this.setState({ send: true })
      this._asyncRequest = axios.CancelToken.source()

      axios.post(path('login_path', { format: 'json' }), { user: { ...this.state.values } }, { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._asyncRequest = null
          this.setState({ error: false })
          // if (res.headers.location && typeof window !== 'undefined') window.location = res.headers.location
          if (res.headers.location) this.props.history.push(res.headers.location)
        }).catch(() => {
          this.setState({ error: true })
        }).then(() => {
          this.setState({ send: false })
        })
    }
  }

  _canSubmit () {
    return true
  }

  render () {
    const { values, error, toggle } = this.state

    return (
      <div className={page.page}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {I18n.t('auth.login.title')}
              </h1>
              <Helmet>
                <title>
                  {I18n.t('auth.login.title')}
                </title>
              </Helmet>
            </div>

            {this.likeExpert() ? (
              <p className={styles.desc}>
                {I18n.t('auth.join.expert')}
              </p>
            ) : (
              <p className={styles.desc}>
                {I18n.t('auth.join.desc')}
              </p>
            )}

            <div className={styles.networks}>
              <a className={classNames(styles.network, styles.facebook, buttons.main)} href={path('user_facebook_omniauth_authorize_path')}>
                {I18n.t('auth.join.facebook')}
              </a>
              <a className={classNames(styles.network, styles.vkontakte, buttons.main)} href={path('user_vkontakte_omniauth_authorize_path')}>
                {I18n.t('auth.join.vkontakte')}
              </a>
            </div>

            <div className={classNames(styles.os, { [styles.toggle]: toggle })}>
              <div className={styles.handler} onClick={() => this.setState(state => ({ toggle: !state.toggle }))}>
                {I18n.t('auth.login.email')}
              </div>

              <div className={styles.form}>
                <form className={form.root} onSubmit={this.handleSubmit}>
                  <div className={form.item}>
                    <div className={form.label}>
                      {I18n.t('auth.values.email')}
                    </div>

                    <div className={form.input}>
                      <input type="text" name="email" value={values.email} onChange={this.handleInputChange} />
                    </div>
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      {I18n.t('auth.values.password')}
                    </div>

                    <div className={form.input}>
                      <input type="password" name="password" value={values.password} onChange={this.handleInputChange} />
                    </div>
                  </div>

                  {error &&
                    <div className={styles.error}>
                      {I18n.t('auth.login.error')}
                    </div>
                  }

                  <div className={form.submit}>
                    <input type="submit" value={I18n.t('auth.login.action')} className={buttons.main} disabled={!this._canSubmit()} />
                  </div>

                  <div className={styles.join}>
                    <Link to="join_path">
                      {I18n.t('auth.join.title')}
                    </Link>
                    <br />
                    <Link to="new_recovery_path">
                      {I18n.t('auth.recovery.title')}
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

export default Login
