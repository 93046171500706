import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import interact from 'interactjs'

import I18n from '../I18n'

import styles from './Marks.module.css'

if (typeof window !== 'undefined') {
  interact.addDocument(window.document, {
    events: { passive: false }
  })
}

class Marks extends Component {
  state = {}

  mount = React.createRef()
  svg = React.createRef()

  componentDidMount () {
    window.addEventListener('resize', this._onResize)
    this._onResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._onResize)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.marks !== this.state.marks && prevState.marks) {
      if (this.props.onMarksChange) this.props.onMarksChange(this.state.marks)
    }
  }

  _onResize = () => {
    const { width, height } = this.mount.current.getBoundingClientRect()
    const size = height - 80
    const radius = size / 2

    this.setState({ width, height, size, radius })
  }

  handleMarkChange = (axis, mark) => {
    this.setState(state => ({
      marks: { ...state.marks, [axis]: mark }
    }))
  }

  handleAxisActive = active => {
    this.setState({ active })
  }

  static getDerivedStateFromProps (props, state) {
    if (!state.marks) {
      return {
        marks: props.marks
      }
    }

    return null
  }

  render () {
    const { axes, rates } = this.props
    const { width, height, size, radius, marks, active } = this.state

    const r = radius

    return (
      <>
        <div ref={this.mount} className={styles.root}>
          <div className={styles.placeholder}>
            {radius &&
              <svg viewBox={`0 0 ${size} ${size}`} className={styles.svg} ref={this.svg}>
                {axes.map((axis, i) =>
                  <line
                    className={classNames(styles.axis, { [styles.active]: active && active === axis })}
                    key={i} x1={radius} y1={radius}
                    x2={Math.cos((360 / axes.length * i - 90) * Math.PI / 180) * radius * 0.94 + radius}
                    y2={Math.sin((360 / axes.length * i - 90) * Math.PI / 180) * radius * 0.94 + radius} />
                )}

                {rates.map(rate =>
                  <polygon
                    key={rate.id}
                    className={classNames(styles.polygon, styles[rate.id], { [styles.faded]: marks })}
                    points={axes.map((axis, i) => {
                      const angle = (i * (360 / axes.length) - 90) * Math.PI / 180
                      const value = radius * 0.94 * rate.marks.find(v => v.axis === axis).mark / 100

                      return [
                        Math.round((value * Math.cos(angle) + radius) * 100) / 100,
                        Math.round((value * Math.sin(angle) + radius) * 100) / 100
                      ].join(',')
                    }).join(' ')}
                  />

                )}

                {marks &&
                  <g>
                    <polygon
                      className={classNames(styles.polygon, styles.editable)}
                      points={axes.map((axis, i) => {
                        let angle = (i * (360 / axes.length) - 90) * Math.PI / 180
                        let value = r * 0.94 * marks[axis] / 100

                        return [
                          Math.round((value * Math.cos(angle) + r) * 100) / 100,
                          Math.round((value * Math.sin(angle) + r) * 100) / 100
                        ].join(',')
                      }).join(' ')}
                    />

                    {axes.map((axis, i) =>
                      <Dot
                        key={axis}
                        i={i}
                        axis={axis}
                        axes={axes}
                        mark={marks[axis]}
                        radius={radius}
                        onMarkChange={this.handleMarkChange}
                        onAxisActive={this.handleAxisActive}
                      />
                    )}
                  </g>
                }
              </svg>
            }
          </div>

          {axes.map((axis, i) =>
            <Label key={i} i={i} axis={axis} axes={axes} width={width} height={height} value={marks && marks[axis]} />
          )}
        </div>
      </>
    )
  }
}

Marks.propTypes = {
  axes: PropTypes.array,
  rates: PropTypes.array,
  marks: PropTypes.object,
  onMarksChange: PropTypes.func
}

Marks.defaultProps = {
  rates: []
}

export default Marks

class Dot extends Component {
  state = {
    active: false
  }

  mount = React.createRef()

  componentDidMount () {
    const { onAxisActive } = this.props

    interact(this.mount.current)
      .draggable({
        onstart: e => {
          this.setState({ active: true })
          if (onAxisActive) onAxisActive(this.props.axis)
        },
        onend: e => {
          this.setState({ active: false })
          if (onAxisActive) onAxisActive(null)
        },
        onmove: e => {
          const { axes, radius, i } = this.props
          const { dx, dy } = e

          const moveR = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))

          const moveAngle = Math.atan2(dy, dx)
          const newR = moveR * Math.cos(Math.abs((360 / axes.length * i - 90) * Math.PI / 180 - moveAngle))

          let rs = newR / radius * 100
          const v = this.state.mark

          if (v + rs > 100) rs = 100 - v
          if (v + rs < 0) rs = 0

          this.setState({ mark: Math.round(v + rs) })
        }
      })
  }

  componentWillUnmount () {
    interact(this.mount.current).unset()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { axes, axis, radius, i, onMarkChange } = this.props
    const { mark } = this.state
    if (prevState.mark !== mark) {
      this.setState({
        x: Math.cos((360 / axes.length * i - 90) * Math.PI / 180) * (radius * 0.94) * mark / 100 + radius,
        y: Math.sin((360 / axes.length * i - 90) * Math.PI / 180) * (radius * 0.94) * mark / 100 + radius
      })

      if (onMarkChange) onMarkChange(axis, mark)
    }
  }

  static getDerivedStateFromProps (props, state) {
    const { mark, axes, radius, i } = props

    if (!state.mark || radius !== state.prevRadius) {
      return {
        mark: mark,
        x: Math.cos((360 / axes.length * i - 90) * Math.PI / 180) * (radius * 0.94) * mark / 100 + radius,
        y: Math.sin((360 / axes.length * i - 90) * Math.PI / 180) * (radius * 0.94) * mark / 100 + radius,
        prevRadius: radius
      }
    }

    return null
  }

  render () {
    const { x, y, active, mark } = this.state

    if (!mark) return null

    return (
      <>
        <svg ref={this.mount} className={classNames(styles.dot, { [styles.active]: active })} x={x} y={y} width="36" height="36">
          <circle cx="0" cy="0" className={styles.t} r="0" />
          <circle cx="0" cy="0" className={styles.v} r="4" />
          <circle cx="0" cy="0" className={styles.h} r="18" />
        </svg>
      </>
    )
  }
}

Dot.propTypes = {
  axis: PropTypes.string,
  axes: PropTypes.array,
  i: PropTypes.number,
  mark: PropTypes.number,
  radius: PropTypes.number,
  onMarkChange: PropTypes.func,
  onAxisActive: PropTypes.func
}

class Label extends Component {
  state = {
    style: {
      left: 0,
      top: 0
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (props.width !== state.prevWidth) {
      return {
        style: {
          left: Math.cos((360 / props.axes.length * props.i - 90) * Math.PI / 180) * (props.height / 2 - 20) + props.width / 2,
          top: Math.sin((360 / props.axes.length * props.i - 90) * Math.PI / 180) * (props.height / 2 - 20) + props.height / 2
        },
        prevWidth: props.width
      }
    }

    return null
  }

  render () {
    const { i, axes, axis, value } = this.props
    const { style } = this.state

    const count = axes.length

    return (
      <div
        className={classNames(
          styles.label,
          {
            [styles.right]: i > 0 && i < count / 2,
            [styles.left]: i > count / 2,
            [styles.bottom]: i === count / 2,
            [styles.top]: i === 0
          }
        )}

        style={style}
      >
        <span className={styles.long}>
          {I18n.t(`rate.axes.${axis}.desc`)}
          {value !== null ? ` (${value})` : ''}
        </span>

        <span className={styles.short}>
          {I18n.t(`rate.axes.${axis}.label`)}
          {value !== null ? ` (${value})` : ''}
        </span>
      </div>
    )
  }
}

Label.propTypes = {
  i: PropTypes.number,
  axes: PropTypes.array,
  axis: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.number
}

Label.defaultProps = {
  value: null
}
