import React from 'react'
import { Helmet } from 'react-helmet'

import Link, { Routes } from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css';

class Dashboard extends React.Component {
  render() {
    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {I18n.t('admin.nav')}
            </h1>

            <Helmet>
              <title>
                {I18n.t('admin.nav')}
              </title>
            </Helmet>
          </div>

          <div className={page.compact}>
            <ul>
              <li>
                <Link to="admin_nodes_path">
                  Педагоги
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard
