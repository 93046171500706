import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import update from 'immutability-helper'
import classNames from 'classnames'

import Link, { Routes, path } from '../Routes'
import Loader from '../Loader'

import List from './Show/List'
import Graph2D from './Show/Graph2D'
import Graph from './Show/Graph'
import Detail from './Show/Detail'

import ListIcon from '!svg-react-loader!../../images/icons/gist_list.svg'
import MapIcon from '!svg-react-loader!../../images/icons/gist_map.svg'

import page from '../Page.module.css'
import styles from './Show.module.css'

class Show extends PureComponent {
  state = {
    gist: null,
    structures: null,
    active: null,
    linked: [],
    highlighted: [],
    filters: {
      name: ''
    }
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.gist === null) this._loadAsyncData(this.props.id)
  // }
  //
  // static getDerivedStateFromProps(props, state) {
  //   if (props.id !== state.prevId) {
  //     return {
  //       gist: null,
  //       prevId: props.id,
  //     };
  //   }
  //
  //   return null;
  // }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      const { structures, active } = this.state

      const linked = active ? active.child_ids : []
      let highlighted = []

      function checkParent (structure) {
        if(structure.parent_id) {
          let parent = structures.find(s => s.id == structure.parent_id)
          highlighted.push(parent.id)
          // if(!linked.includes(parent.id))
          checkParent(parent)
        }
      }

      if (active) checkParent(active)

      this.setState({ linked, highlighted })
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.gist) {
      const active = state.structures.find(s => s.id == props.location.hash.slice(1))
      if (state.active !== active) {
        return {
          active: active
        }
      }
    }

    return null
  }

  _loadAsyncData = async (id) => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get(path('gist_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
    this.setState({ ...res.data })

    this._asyncRequest = null
  }

  filterNodes = () => {
    let structures = this.state.structures

    if (this.state.filters.name) {
      Object.entries(structures).map(ob => {
        structures = update(structures, {
          [ob[1].id]: {
            faded: { $set: !ob[1].title.toLowerCase().includes(this.state.filters.name.toLowerCase()) }
          }
        })
      })
    } else {
      Object.entries(structures).map(ob => {
        structures = update(structures, {
          [ob[1].id]: {
            faded: { $set: false }
          }
        })
      })
    }

    this.setState({ structures: structures })
  }

  isGraph = () => this.props.type === 'map'

  handleNameChange = (e) => {
    // const value = e.target.value

    // this.setState({
    //   filters: { ...this.state.filters, name: value }
    // }, () => { this.filterNodes(); });

  }

  render () {
    const { gist, structures, active, linked, highlighted } = this.state

    if (!gist) return <Loader />

    return (
      <div className={classNames(page.root, { [styles.graph]: this.isGraph() })}>
        <div className={page.container}>
          <Helmet>
            <title>{gist.title}</title>
          </Helmet>

          {!this.isGraph() &&
            <>
              <div className={page.title}>
                <h1>
                  {gist.title}
                </h1>
              </div>

              <div className={styles.actions}>
                {gist.can_edit &&
                  <Link to="structure_gist_path" params={{ id: gist.id }} className={styles.edit}>Изменить карту</Link>
                }

                {!gist.can_edit &&
                  <Link to="fork_gist_path" params={{ id: gist.id }} className={styles.edit}>Изменить карту</Link>
                }

                <Link to="new_gist_path" className={styles.new}>Создать свою карту</Link>
              </div>
            </>
          }

          <div className={styles.filters}>
            <div className={styles.byname}>
              <input type="text" placeholder="Поиск по имени" className={styles.input} onChange={this.handleNameChange} />
            </div>

            <div className={styles.switcher}>
              <Link exact to="gist_path" params={{ id: gist.id }} activeClassName={styles.active}>
                <ListIcon />
              </Link>

              <Link to="map_gist_path" params={{ id: gist.id }} activeClassName={styles.active}>
                <MapIcon />
              </Link>
            </div>
          </div>
        </div>

        {structures && structures.length > 0 &&
          <>
            <Switch>
              <Route
                path={Routes.map_gist_path}
                render={props =>
                  <Graph2D
                    structures={structures}
                    gist={gist}
                    active={active}
                    linked={linked}
                    highlighted={highlighted}
                    history={this.props.history}
                  />
                }
              />

              <Route
                path={Routes.d3_gist_path}
                render={props =>
                  <Graph
                    structures={structures}
                    gist={gist}
                    active={active}
                    linked={linked}
                    highlighted={highlighted}
                    history={this.props.history}
                  />
                }
              />

              <Route
                exact
                path={path('gist_path', { id: gist.id })}
                render={props =>
                  <List
                    structures={structures}
                    gist={gist}
                    active={active}
                    linked={linked}
                    highlighted={highlighted}
                  />
                }
              />
            </Switch>

            <Detail active={active} />
          </>
        }

      </div>
    )
  }
}

Show.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object
}

export default Show
