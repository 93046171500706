import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Guide from './Guide'
import Form from './Form'
import Show from './Show'

Tasks.propTypes = {
  match: PropTypes.object
}

export default function Tasks (props) {
  return (
    <Switch>
      <Route
        path={Routes.edit_task_path}
        render={props =>
          <Form id={props.match.params.id} {...props} />
        }
      />

      <Route path={Routes.guide_tasks_path} component={Guide} />
      <Route path={Routes.new_task_path} component={Form} />

      <Route
        path={Routes.task_path}
        render={props =>
          <Show id={props.match.params.id} {...props} />
        }
      />
    </Switch>
  )
}
