import React, { Component } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import { path } from '../Routes'
import Errors from '../Errors'

import page from '../Page.module.css'
import form from '../Form.module.css'
import buttons from '../Buttons.module.css'
import styles from './Join.module.css'

class Login extends Component {
  state = {
    send: false,
    errors: {},
    values: {
      email: '',
      name: '',
      surname: '',
      middlename: '',
      password: '',
      password_confirmation: ''
    }
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  handleInputChange = e => {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState(state => ({
      values: { ...state.values, [name]: value }
    }))
  }

  handleSubmit = e => {
    if (this._canSubmit()) {
      this.setState({ send: true })
      this._asyncRequest = axios.CancelToken.source()

      axios.post(path('join_path', { format: 'json' }), { user: { ...this.state.values } }, { cancelToken: this._asyncRequest.token })
        .then(res => {
          this._asyncRequest = null
          this.setState({ errors: {} })
          if (res.headers.location) this.props.history.push(res.headers.location)
        })
        .catch(error => {
          this.setState({ errors: error.response.data })
        })
        .then(() => {
          this.setState({ send: false })
        })
    }

    e.preventDefault()
  }

  _canSubmit () {
    return true
  }

  render () {
    const { values, errors } = this.state

    return (
      <div className={page.page}>
        <div className={page.container}>
          <div className={styles.root}>
            <div className={page.title}>
              <h1>
                {I18n.t('auth.join.title')}
              </h1>
              <Helmet>
                <title>
                  {I18n.t('auth.join.title')}
                </title>
              </Helmet>
            </div>

            <div className={styles.form}>
              <form className={form.root} onSubmit={this.handleSubmit}>
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Электронная почта
                    </div>

                    <div className={form.input}>
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={this.handleInputChange}
                        placeholder="Введите электронную почту..."
                      />
                    </div>
                  </label>

                  <Errors errors={errors.email} />

                  <div className={form.hint}>
                    Почта не для публичного отображения, необходима только для входа и получения системных уведомлений
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Имя
                  </div>

                  <div className={form.input}>
                    <input
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={this.handleInputChange}
                      placeholder="Введите ваше имя..."
                    />
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Фамилия
                  </div>

                  <div className={form.input}>
                    <input
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={this.handleInputChange}
                      placeholder="Введите вашу фамилию..."
                    />
                  </div>
                </div>

                <div className={form.item}>
                  <div className={form.label}>
                    Отчество
                  </div>

                  <div className={form.input}>
                    <input
                      type="text"
                      name="middle_name"
                      value={values.middle_name}
                      onChange={this.handleInputChange}
                      placeholder="Введите ваше отчество..."
                    />
                  </div>

                  <div className={form.hint}>
                    Необязательно к заполнению
                  </div>
                </div>

                {Object.entries(errors).length > 0 &&
                  <div className={styles.error}>
                    Необходимо исправить ошибки и попробовать снова.
                  </div>
                }

                <div className={form.submit}>
                  <input type="submit" value="Зарегистрироваться" className={buttons.main} disabled={!this._canSubmit()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login
