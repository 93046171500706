import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, StaticRouter, Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { YMInitializer } from 'react-yandex-metrika'

import { Routes } from './Routes'
import I18n from './I18n'
import { Current } from './Current'

import Header from './Header'
import Footer from './Footer'
import Index from './Index'
import About from './About'
import Join from './Auth/Join'
import Login from './Auth/Login'
import Recovery from './Auth/Recovery'
import Rates from './Rates/Rates'
import Events from './Events/Events'
import Nodes from './Nodes/Nodes'

const Admin = loadable(() => import('./Admin/Admin'))
const Gists = loadable(() => import('./Gists/Gists'))
const Users = loadable(() => import('./Users/Users'))
const Tasks = loadable(() => import('./Tasks/Tasks'))
const Help = loadable(() => import('./Help/Help'))
const Decisions = loadable(() => import('./Decisions/Decisions'))
const Executions = loadable(() => import('./Executions/Executions'))

class App extends Component {
  state = {}

  componentDidMount () {
    this.ws = new WebSocket(`${this.props.ssl ? 'wss' : 'ws'}://${this.props.host}/cable`)

    this.ws.onopen = () => {
      this.ws.send(JSON.stringify({ command: 'subscribe', identifier: JSON.stringify({ channel: 'AppChannel' }) }))
    }

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data)

      if (data.identifier) {
        const { channel } = JSON.parse(data.identifier)

        if (channel === 'AppChannel' && data.message && !data.type) {
          const { user, jwt_token } = data.message

          this.setState({ user, jwt_token })
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.jwt_token !== prevState.jwt_token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.state.jwt_token}`
    }
  }

  static getDerivedStateFromProps (props, state) {
    I18n.available_locales = props.available_locales
    I18n.host = props.host
    I18n.locale = props.locale
    I18n.default_locale = props.default_locale

    if (typeof state.user === 'undefined') {
      axios.defaults.headers.common['Authorization'] = `Bearer ${props.jwt_token}`

      return {
        user: props.user,
        jwt_token: props.jwt_token
      }
    }

    return null
  }

  render () {
    const { path } = this.props
    const { user, token } = this.state

    return (
      <Router path={path}>
        <>
          <Helmet
            defaultTitle={I18n.t('site_name')}
            titleTemplate={'%s — ' + I18n.t('site_name')}
          />

          <Current.Provider value={{ user, token }}>
            <Header />

            <Switch>
              <Route path={Routes.nodes_path} component={Nodes} />
              <Route path={Routes.users_path} component={Users} />
              <Route path={Routes.about_path} component={About} />
              <Route path={Routes.rates_path} component={Rates} />
              <Route path={Routes.login_path} component={Login} />
              <Route path={Routes.join_path} component={Join} />
              <Route path={Routes.recovery_path} component={Recovery} />
              <Route path={Routes.events_path} component={Events} />
              <Route path={Routes.tasks_path} component={Tasks} />
              <Route path={Routes.gists_path} component={Gists} />
              <Route path={Routes.decisions_path} component={Decisions} />
              <Route path={Routes.execution_path} component={Executions} />

              <Route path={Routes.help_path} component={Help} />

              <Route path={Routes.admin_dashboard_path} component={Admin} />
              <Route path={Routes.root_path} component={Index} exact />
            </Switch>

            <Switch>
              <Route path={[Routes.map_gist_path, Routes.d3_gist_path]} component={() => <></>} />
              <Route path={Routes.root_path} component={Footer} />
            </Switch>
          </Current.Provider>

          {this.props.env === 'production' &&
            <YMInitializer
              accounts={[43766444]}
              version="2"
              options={{
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true
              }}
            />
          }
        </>
      </Router>
    )
  }
}

class Router extends Component {
  renderRouter = () => {
    if (typeof window !== 'undefined') {
      return (
        <BrowserRouter>
          {this.props.children}
        </BrowserRouter>
      )
    } else {
      return (
        <StaticRouter location={this.props.path} context={{}}>
          {this.props.children}
        </StaticRouter>
      )
    }
  }

  render () {
    return (this.renderRouter())
  }
}

Router.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node
}

App.propTypes = {
  user: PropTypes.object,
  jwt_token: PropTypes.string,
  ssl: PropTypes.bool,
  host: PropTypes.string,
  path: PropTypes.string,
  env: PropTypes.string,
  locale: PropTypes.string,
  default_locale: PropTypes.string,
  available_locales: PropTypes.array
}

// App.defaultProps = {
//   user: null
// }

export default App
