import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'

import { Routes } from '../Routes'

import Index from './Index'
import Tasks from './Tasks'

Help.propTypes = {
  match: PropTypes.object
}

export default function Help (props) {
  return (
    <Switch>
      <Route path={Routes.help_tasks_path} component={Tasks} />
      <Route path={Routes.help_path} component={Index} />
    </Switch>
  )
}
