import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Link from '../Routes'
import I18n from '../I18n'

import Avatar from '../Users/Avatar'

import styles from './List.module.css'

class List extends Component {
  render () {
    const { users } = this.props

    if (!users || users.length < 1) return null

    return (
      <div className={styles.list}>
        {users.map((user) =>
          <Item key={user.id} user={user} />
        )}
      </div>
    )
  }
}

List.propTypes = {
  users: PropTypes.array
}

List.defaultProps = {
  users: []
}

export default List

class Item extends React.Component {
  render () {
    const { user } = this.props

    return (
      <div className={styles.item}>
        {user.social_provider && false &&
          <div className="social">
            <a className={user.social_provider} href={user.social_url} target="_blank" rel="noopener noreferrer" />
          </div>
        }

        <Link to="user_path" params={{ id: user.id }}>
          {(user.expert || user.friend) &&
            <div className={styles.whois}>
              {user.expert &&
                <div className={styles.expert} />
              }

              {user.friend &&
                <div className={styles.friend} />
              }
            </div>
          }

          <div className={styles.avatar}>
            <Avatar user={user} />
          </div>

          <div>
            <div className={styles.name}>
              {user.title}
            </div>

            {user.occupation && I18n.locale === 'ru' &&
              <div className={styles.occupation}>
                {user.occupation}
              </div>
            }

            <div className={styles.meta}>
              {user.rates > 0 &&
                <div className={styles.rates}>
                  {user.rates}
                </div>
              }

              {user.gists > 0 &&
                <div className={styles.gists}>
                  {user.gists}
                </div>
              }

              {user.following > 0 &&
                <div className={styles.following}>
                  {user.following}
                </div>
              }

              {user.comments > 0 &&
                <div className={styles.comments}>
                  {user.comments}
                </div>
              }
            </div>
          </div>

        </Link>
      </div>
    )
  }
}

Item.propTypes = {
  user: PropTypes.object
}
