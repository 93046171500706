import React from 'react'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import update from 'immutability-helper'

import I18n from '../../I18n'
import { path } from '../../Routes'

import styles from './Form.module.css'
import page from '../../Page.module.css'
import form from '../../Form.module.css'
import buttons from '../../Buttons.module.css'

class Form extends React.Component {
  state = {
    send: false,
    node: null,
    values: {
      years: '',
      era: '',
    },
    submit: {
      url: path('admin_nodes_path', { format: 'json' }),
      method: 'post',
    },
    redirect_to: null
  }

  constructor(props) {
    super(props);

    I18n.available_locales.map(locale => {
      this.state[locale] = { values: {
        id: null,
        locale: locale,
        title: '',
        title_short: '',
        surname: '',
        description: '',
        school: '',
        region: '',
      }};
    });

    // console.log(this.state);
  }

  componentDidMount() {
    if(this.props.id) this._loadAsyncData(this.props.id);
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source();

    axios.get(path('edit_admin_node_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          node: res.data.node,
          values: res.data.values,
          submit: {
            url: path('admin_node_path', { id: id, format: 'json' }),
            method: 'patch'
          }
        });

        I18n.available_locales.map(locale => {
          if(res.data[locale]) {
            this.setState({
              [locale]: res.data[locale]
            });
          }
        });

        this._asyncRequest = null;
      });
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel();
  }

  handleInputChange = (e) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value }
    }));
  }

  handleLocaleInputChange = (e, locale) =>  {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    const values = update(this.state[locale], {
      values: {
        [name]: {
          $set: value
        }
      }
    });

    this.setState({
      [locale]: values
    });
  }

  _canSubmit = () => {
    return !this.state.send;
  }

  handleSubmit = (e) => {
    if(this._canSubmit()) {
      this.setState({ send: true });
      this._asyncRequest = axios.CancelToken.source();

      axios({
        method: this.state.submit.method,
        url: this.state.submit.url,
        cancelToken: this._asyncRequest.token,
        data: {
          node: {
            ...this.state.values,
            translations_attributes: I18n.available_locales.map(locale => {
              return this.state[locale].values;
            })
          }
        }
      }).then(
        res => {
          this._asyncRequest = null
          if(res.headers.location) this.props.history.replace(res.headers.location)
        }
      ).catch((error) => {
        console.log(error);
        this.setState({ send: false });
      }).then(() => {

      });
    }

    e.preventDefault();
  }

  render() {
    const { node } = this.state

    const title = node ? node.title : 'Новый педагог'

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {title}
            </h1>

            <Helmet>
              <title>
                {title}
              </title>
            </Helmet>
          </div>

          <div className={page.compact}>
            <form className={form.root} onSubmit={this.handleSubmit}>
              <div className={form.item}>
                <div className={form.label}>
                  Эра
                </div>

                <div className={form.input}>
                  <input type="text" name="era" value={this.state.values.era} onChange={this.handleInputChange} />
                </div>
              </div>

              <div className={form.item}>
                <div className={form.label}>
                  Годы жизни
                </div>

                <div className={form.input}>
                  <input type="text" name="years" value={this.state.values.years} onChange={this.handleInputChange} />
                </div>
              </div>

              {I18n.available_locales.map(locale =>
                <div key={locale} className={styles.locale}>
                  <div className={styles.title}>
                    {locale.toUpperCase()}
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      Полное имя
                    </div>

                    <div className={form.input}>
                      <input type="text" name="title" value={this.state[locale].values.title} onChange={(e) => this.handleLocaleInputChange(e, locale)} />
                    </div>
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      Короткое имя
                    </div>

                    <div className={form.input}>
                      <input type="text" name="title_short" value={this.state[locale].values.title_short} onChange={(e) => this.handleLocaleInputChange(e, locale)} />
                    </div>
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      Фамилия (одно слово!)
                    </div>

                    <div className={form.input}>
                      <input type="text" name="surname" value={this.state[locale].values.surname} onChange={(e) => this.handleLocaleInputChange(e, locale)} />
                    </div>

                    <div className={form.hint}>
                      Одно слово без сокращений, имен и прочего. Нигде не показывается, необходимо только для правильной сортировки по фамилии.
                    </div>
                  </div>

                  <div className={form.item}>
                    <div className={form.label}>
                      Описание
                    </div>

                    <div className={form.input}>
                      <textarea name="description" value={this.state[locale].values.description} onChange={(e) => this.handleLocaleInputChange(e, locale)} />
                    </div>
                  </div>
                </div>
              )}

              <div className={form.submit}>
                <input type="submit" value="Сохранить" className={buttons.main} disabled={!this._canSubmit()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Form
