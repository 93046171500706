import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Helmet } from 'react-helmet'
import { Editor, EditorState, convertToRaw, convertFromRaw, ContentState } from 'draft-js'
import querystring from 'querystring'
import classNames from 'classnames'

import { path } from '../Routes'
import I18n from '../I18n'
import Errors, { Summary } from '../Errors'

import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import form from '../Form.module.css'

class Form extends React.Component {
  state = {
    task: null,
    errors: {},
    values: null,
    send: false
  }

  componentDidMount () {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async id => {
    this._asyncRequest = axios.CancelToken.source()

    const eventId = querystring.parse(this.props.location.search.slice(1)).event_id
    const type = querystring.parse(this.props.location.search.slice(1)).type
    const uri = id ? path('edit_task_path', { id: id, format: 'json' }) : path('new_task_path', { format: 'json', query: { event_id: eventId, type } })

    const res = await axios.get(uri, { cancelToken: this._asyncRequest.token })
    this._asyncRequest = null

    res.data.values.text = EditorState.createWithContent(res.data.values.text ? convertFromRaw(res.data.values.text) : ContentState.createFromText(I18n.t(`task.type.${type}.default`)))
    this.setState({ ...res.data })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    if (this.state.send) return null

    this.setState({ send: true })

    const params = {
      task: {
        ...this.state.values,
        text: this.state.values.text.getCurrentContent().hasText() ? convertToRaw(this.state.values.text.getCurrentContent()) : null
      }
    }

    if (this.state.task) {
      this._handleUpdate(this.state.task.id, params)
    } else {
      this._handleCreate(params)
    }
  }

  _handleUpdate = async (id, params) => {
    await axios.patch(
      path('task_path', { id }),
      params
    ).then(res => {
      if (res.headers.location) this.props.history.push(res.headers.location)
    }).catch((error) => {
      this.setState({ errors: error.response.data, send: false })
    })

    this.setState({ send: false })
  }

  _handleCreate = async (params) => {
    await axios.post(
      path('tasks_path'),
      params
    ).then(res => {
      if (res.headers.location) this.props.history.push(res.headers.location)
    }).catch((error) => {
      this.setState({ errors: error.response.data, send: false })
    })

    this.setState({ send: false })
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState(state => ({ values: { ...state.values, [name]: value } }))
  }

  handleCheckboxChange = ({ target: { value, checked } }) => {
    const nodeId = Math.trunc(value)

    if (checked) {
      if (!this.state.values.node_ids.includes(nodeId)) {
        this.setState(state => ({ values: { ...state.values, node_ids: [ ...state.values.node_ids, nodeId ] } }))
      }
    } else {
      this.setState(state => ({ values: { ...state.values, node_ids: state.values.node_ids.filter(id => id !== nodeId) } }))
    }
  }

  handleClearType = e => {
    e.preventDefault()

    this.setState(state => ({ values: { ...state.values, type: '' } }))
  }

  render () {
    const { task, values, errors, dictionaries, send } = this.state
    const { id } = this.props

    return (
      <div className={page.root}>
        <div className={page.container12}>
          <div className={page.title}>
            <h1>
              {id ? I18n.t('task.edit.title') : I18n.t('task.new.title')}
            </h1>

            <Helmet>
              <title>{id ? I18n.t('task.edit.title') : I18n.t('task.new.title')}</title>
            </Helmet>
          </div>

          {values &&
            <form onSubmit={this.handleSubmit}>

              {false &&
                <div className={form.el}>
                  <label>
                    <div className={form.label}>
                      Тип задания
                    </div>

                    <div className={form.input}>
                      <select name="type" onChange={this.handleInputChange} value={values.type}>
                        <option value="" disabled>Выберите тип задания...</option>
                        {dictionaries.types.map(type =>
                          <option key={type} value={type}>{I18n.t(`task.type.${type}.title`)}</option>
                        )}
                      </select>
                    </div>
                  </label>

                  <Errors errors={errors.type} />
                </div>
              }

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Описание задания
                  </div>

                  <div className={form.input}>
                    <Editor
                      editorState={values.text}
                      onChange={(editorState) => this.setState(state => ({ values: { ...state.values, text: editorState } }))}
                    />
                  </div>
                </label>

                <Errors errors={errors.text} />

                <div className={form.hint}>
                  Обязательное поле для заполнения. Можете изменить по своему усмотрению.
                </div>
              </div>

              <div className={form.el}>
                <label>
                  <div className={form.label}>
                    Минимальное количество для выполнения
                  </div>

                  <div className={form.input}>
                    <input
                      type="text"
                      name="goal"
                      value={values.goal}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </label>

                <div className={form.hint}>
                  Можно указать сколько минимально заданий нужно создать. Если ничего не указано, то могут создавать любое количество, но минимально нужно будет хотя бы одно.
                </div>
              </div>

              {dictionaries && dictionaries.nodes &&
                <div className={form.el}>
                  <div className={form.label}>
                    Педагоги (если нужно ограничить выбор)
                  </div>

                  <p className={form.hint}>
                    Выберите педагогов чтобы ограничить выполнение задания только ими, если ничего не выбрано, то можно будет выполнять задание для любого педагога.
                  </p>

                  <div className={form.input}>
                    {dictionaries.nodes.map(node =>
                      <label className={form.checkbox} key={node.id}>
                        <input type="checkbox" value={node.id} onChange={this.handleCheckboxChange} checked={values.node_ids.includes(node.id)} />
                        {node.title_short}
                      </label>
                    )}
                  </div>
                </div>
              }

              <div className={form.submit}>
                <input type="submit" value={task ? 'Сохранить' : 'Создать' } className={classNames(buttons.main, { [buttons.progress]: send })} disabled={send} />
              </div>

              <Summary errors={errors} />
            </form>
          }
        </div>
      </div>
    )
  }
}

Form.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object
}

export default Form
