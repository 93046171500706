import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import AnimateHeight from 'react-animate-height'

// import {
//   CSSTransition,
//   TransitionGroup
// } from 'react-transition-group'

import styles from './Books.module.css'
import buttons from '../../Buttons.module.css'

Books.propTypes = {
  books: PropTypes.array
}
export default function Books (props) {
  let { books } = props

  const [height, setHeight] = useState(250)

  const isOpened = () => {
    return height === 'auto'
  }

  return (
    <>
      <AnimateHeight
        className={classNames(styles.wrapper, { [styles.opened]: isOpened() })}
        duration={500}
        height={height}
      >
        <div className={styles.books}>
          {books.map(book =>
            <div key={book.id} className={classNames(styles.book, { [styles.noSource]: !book.source })}>
              <div className={styles.cover}>
                <div className={styles.img}>
                  {book.cover &&
                    <img src={book.cover} />
                  }
                </div>
              </div>

              <div className={styles.data}>
                {!book.source &&
                  <div className={styles.no}>
                    Временно отсутсвует
                  </div>
                }

                {book.author &&
                  <div className={styles.author}>
                    {book.author}
                  </div>
                }

                <div className={styles.title}>
                  {book.title}
                </div>

                {book.description &&
                  <div className={styles.description}>
                    {book.description}
                  </div>
                }
              </div>

              {book.source &&
                <a target="_blank" rel="noopener noreferrer" href={book.source} className={styles.url} />
              }
            </div>
          )}
        </div>
      </AnimateHeight>

      {books.length > 6 &&
        <div className={styles.read}>
          <div className={buttons.toggle} onClick={() => setHeight(isOpened() ? 250 : 'auto')}>
            Все книги ({books.length})
            <i />
          </div>
        </div>
      }
    </>
  )
}
