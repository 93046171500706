import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'

import I18n from '../I18n'
import { path } from '../Routes'

import List from './List'
import Tabs from './Tabs'

import page from '../Page.module.css'
import styles from './Index.module.css'

class Friends extends Component {
  state = {
    users: null,
    tabs: null,
  }

  componentDidMount() {
    this._loadAsyncData()
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData() {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('friends_users_path', { format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        const { users, tabs } = res.data

        this.setState({ users, tabs })

        this._asyncRequest = null
      });
  }

  render() {
    const { users, tabs } = this.state

    return (
      <div className={page.root}>
        <div className={page.container}>
          <div className={page.title}>
            <h1>
              {I18n.t('users.friends')}
            </h1>

            <Helmet>
              <title>{I18n.t('users.friends')}</title>
            </Helmet>
          </div>

          <Tabs {...tabs} />

          <div className={styles.users}>
            <List users={users} />
          </div>
        </div>
      </div>
    )
  }
}

export default Friends
