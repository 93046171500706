import React, { Component } from 'react'
import axios from 'axios'

import { path } from '../../Routes'

import List from '../../Gists/List';

class Gists extends React.Component {
  state = {
    gists: null,
  }
  
  componentDidMount() {
    this._loadAsyncData(this.props.id)
  }

  componentWillUnmount() {
    if(this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData(id) {
    this._asyncRequest = axios.CancelToken.source()

    axios.get(path('gists_user_path', { id: id, format: 'json' }), { cancelToken: this._asyncRequest.token })
      .then(res => {
        this.setState({
          gists: res.data.gists
        })

        this._asyncRequest = null
      })
  }

  render () {
    return (
      <List gists={this.state.gists} />
    )
  }
}

export default Gists
