import React, { Component } from 'react'
import classNames from 'classnames'

import I18n from './I18n'
import { Current } from './Current'
import Link from './Routes'

import Burger from '!svg-react-loader!../images/icons/burger.svg'
import Logo from '!svg-react-loader!../images/logo.svg'

import Avatar from './Users/Avatar'

import styles from './Header.module.css'
import buttons from './Buttons.module.css'

class Header extends Component {
  state = {
    toggle: false
  }

  isAuth = () => {
    return this.context.user !== null
  }

  handleToggle = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle
    }))
  }

  handleClose = (e) => {
    if (e.target.tagName === 'A') {
      this.setState({
        toggle: false
      })
    }
  }

  render () {
    const { toggle } = this.state
    const { user } = this.context

    return (
      <header className={classNames(styles.root, { [styles.toggled]: toggle })}>
        <div className={styles.menu} onClick={this.handleClose} >
          <div className={styles.item}>
            <Link activeClassName={styles.active} className={styles.main} exact to="root_path" strict>{I18n.t('pages.index.title')}</Link>
          </div>

          <div className={styles.item}>
            <Link activeClassName={styles.active} className={styles.main} to="gist_path" params={{ id: 1 }}>{I18n.t('gist.main')}</Link>
          </div>

          <div className={styles.item}>
            <Link activeClassName={styles.active} className={styles.main} to="experts_users_path">{I18n.t('users.experts')}</Link>
          </div>

          <div className={styles.item}>
            <Link activeClassName={styles.active} className={styles.main} to="about_path">{I18n.t('pages.about.title')}</Link>
          </div>

          {user && user.editor &&
            <div className={styles.item}>
              <Link activeClassName={styles.active} className={styles.main} to="admin_dashboard_path">{I18n.t('admin.nav')}</Link>
            </div>
          }

          {user &&
            <div className={styles.user}>
              <Link to="user_path" params={{ id: user.id }}>
                <div className={styles.avatar}>
                  <Avatar user={user} />
                </div>

                <div className={styles.name}>
                  {user.title}
                </div>
              </Link>
            </div>

          }

          {!user &&
            <div className={styles.join}>
              <Link className={buttons.second} to="login_path">{I18n.t('auth.login.title')}</Link>
            </div>
          }
        </div>

        <div className={styles.authors}>
          {I18n.t('header.desc')}
        </div>

        <div className={styles.burger}>
          <Burger onClick={this.handleToggle} />
        </div>

        <div className={styles.logo}>
          <Link to="root_path">
            <Logo />
          </Link>
        </div>

        <div className={styles.locales}>
          {I18n.available_locales.filter(locale => locale !== I18n.locale).map(locale =>
            <a key={locale} href={'//' + (locale === I18n.default_locale ? '' : `${locale}.`) + I18n.host} className={classNames(styles.locale, styles[locale])}>
              {locale}
            </a>
          )}
        </div>
      </header>
    )
  }
}

Header.contextType = Current

export default Header
